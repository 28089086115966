import Card from 'ui/src/card/card';
import CardTableContent from 'ui/src/card/card-content-table';
import CardHeader from 'ui/src/card/card-header';
import { ContentLayout, PrimaryContent } from 'ui/src/content-layout';
import { useCompanyId } from 'core/src/company/focused-company-context';
import { useEffect } from 'react';
import moment from 'moment';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import SecondaryButton from 'ui/src/button/secondary-button';
import UserNotificationList from '@client/page/management/user-notificartion/user-notification-list/component/user-notification-list.tsx';
import { PushNotificationToggle } from '@client/module/push-notification/push-notification-toggle.tsx';
import ApplicationErrorView from '@client/module/error/application-error-view.tsx';
import useGetUserNotification from './logic/use-get-user-notifications';
import UserNotificationTable from './component/user-notification-table';

export default function UserNotificationListPage() {
  const companyID = useCompanyId();
  const { data, refetch, error } = useGetUserNotification(companyID);

  useEffect(() => {
    localStorage.setItem('latestAccessTime', moment().toISOString());
  }, []);

  return (
    <ContentLayout>
      <PrimaryContent>
        <ApplicationErrorView error={error} />
        <Card>
          <CardHeader title="User notification" withBackButton={true} backTarget={'/'}>
            <PushNotificationToggle />
            <SecondaryButton
              onClick={() => {
                refetch().catch((e) => void e);
              }}
            >
              <ArrowPathIcon className="w-4" />
            </SecondaryButton>
          </CardHeader>

          <CardTableContent>
            <div className="max-sm:hidden">
              <UserNotificationTable items={data?.userNotifications} />
            </div>
            <div className="sm:hidden">
              <UserNotificationList items={data?.userNotifications} />
            </div>
          </CardTableContent>
        </Card>
      </PrimaryContent>
    </ContentLayout>
  );
}
