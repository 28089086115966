import { useQuery } from '@apollo/client';
import { graphql } from '@client/graphql/types';
import { InvoicesFilter } from '@client/graphql/types/graphql.ts';

const QUERY = graphql(`
  query InvoicesByBranch($branchId: ID!, $filter: InvoicesFilter!) {
    invoicesByBranch(branchId: $branchId, filter: $filter) {
      ...InvoiceItem
    }
  }
`);

export default function useGetInvoicesByBranch(branchId: string, filter: InvoicesFilter){
  return useQuery(QUERY, {
    variables: {
      branchId: branchId,
      filter: filter,
    }
  })
}