import { ReactNode } from 'react';

export default function DescriptionItem(props: { title: string; children?: ReactNode }) {
  return (
    <div className="py-2 sm:col-span-1 sm:px-0">
      <dt className="text-sm  font-medium leading-6 text-gray-400">{props.title}</dt>
      <dd className="text-sm leading-6 text-gray-700">{props.children}</dd>
    </div>
  );
}
