import { useEffect, useState } from 'react';
import { Button } from 'antd';
import { XMarkIcon } from '@heroicons/react/24/outline';
import SecondaryButton from 'ui/src/button/secondary-button';
import { useDialog } from 'ui/src/modal/use-dialog';
import { useTranslate } from '@tolgee/react';

export function FlexDurationInput(props: { value: number[]; onChange: (value: number[]) => void }) {
  const { confirmAlert } = useDialog();
  const [value, setValue] = useState<number[]>([]);
  const [newDuration, setNewDuration] = useState<number | null>(null);
  const {t}=useTranslate();
  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const validate = () => {
    if (!newDuration) {
      return false;
    }

    // not negative
    if (newDuration < 0) {
      setNewDuration(null);
      confirmAlert({
        title: t('reservation.setting.form-input.flex-time.add-alert.negative-error.title'),
        message: t('reservation.setting.form-input.flex-time.add-alert.negative-error.message'),
        textButton: t('reservation.setting.form-input.flex-time.add-alert.negative-error.done-button'),
        onConfirm: () => {
          return Promise.resolve();
        },
      });
      return false;
    }

    // not loop
    if (value.includes(newDuration)) {
      setNewDuration(null);
      confirmAlert({
        title: t('reservation.setting.form-input.flex-time.add-alert.duplicate-error.title'),
        message: t('reservation.setting.form-input.flex-time.add-alert.duplicate-error.message'),
        textButton: t('reservation.setting.form-input.flex-time.add-alert.duplicate-error.done-button'),
        onConfirm: () => {
          return Promise.resolve();
        },
      });
      return false;
    }

    return true;
  };

  const handleRemove = (index: number) => {
    const updatedValue = value.filter((_, i) => i !== index);
    setValue(updatedValue);
    props.onChange(updatedValue);
  };

  const handleAdd = () => {
    if (!validate()) {
      return;
    }

    if (newDuration !== null && !isNaN(newDuration)) {
      const updatedValue = [...value, newDuration];
      setValue(updatedValue);
      props.onChange(updatedValue);
      setNewDuration(null); // Reset input field after adding
    }
  };

  return (
    <div className="mt-2 flex flex-col gap-2">
      <div className="flex flex-wrap gap-2 p-2 border border-gray-300 rounded-md">
        {value
          .sort((a, b) => a - b)
          .map((item, index) => (
            <div className=" flex bg-gray-50" key={index}>
              <div className="flex items-center object-cover rounded-lg border border-gray-300 min-w-16 h-10 px-2">
                <span className="grow text-center">
                  {item} <span className="text-gray-500">{t('reservation.setting.form-input.flex-time.minutes')}</span>
                </span>
                <Button
                  onClick={() => {
                    handleRemove(index);
                  }}
                  className="md:right-0 w-5 h-5 px-1 mx-1 text-white font-bold bg-gray-200 rounded-full cursor-pointer"
                >
                  <XMarkIcon className="text-gray-600" />
                </Button>
              </div>
            </div>
          ))}

        <div className="flex items-center px-2 rounded-md border border-gray-300 text-sm">
          <input
            type={'number'}
            name={'duration'}
            placeholder={t('reservation.setting.form-input.flex-time.placeholder')}
            value={newDuration ?? ''}
            className="border-none text-sm w-32 focus:outline-none focus:ring-0"
            onChange={(e) => {
              setNewDuration(parseInt(e.target.value, 10) || null);
            }}
          />

          <span>{t('reservation.setting.form-input.flex-time.minutes')}</span>
        </div>
      </div>
      <SecondaryButton onClick={handleAdd} className="self-end">
        {t('reservation.setting.form-input.flex-time.add-button')}
      </SecondaryButton>
    </div>
  );
}
