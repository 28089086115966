import { graphql } from '@client/graphql/types';
import { useQuery } from '@apollo/client';

const QUERY = graphql(`
  query TableReservationDetail($id: ID!) {
    tableReservation(id: $id) {
      ...TableReservationItem
    }
  }
`);

export default function useTableReservationDetailQuery(
  id: string | null | undefined
) {
  return useQuery(QUERY, {
    variables: {
      id: id ?? '',
    },
    skip: !id,
  });
}
