import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import CardContent from '@packages/ui/card/card-content.tsx';
import DestroyButton from '@packages/ui/button/destroy-button.tsx';
import { useDialog } from '@packages/ui/modal/use-dialog.tsx';
import useDeleteAllReservations
  from '@client/page/management/table-reservation/setting/logic/use-delete-all-reservations.ts';
import {
  useFocusedBranchContext,
} from '@client/page/management/table-group/table-group-list/component/branch-selection/focused-branch-context.ts';
import CardSection from '@packages/ui/card/card-section.tsx';
import { useTranslate } from "@tolgee/react";

const TableReservationAdvancedSetting = () => {
  const { branch } = useFocusedBranchContext();
  const dialog = useDialog();

  const [deleteAllReservations] = useDeleteAllReservations();

  const deleteAllHandler = () => {
    dialog.confirmAlert({
      title: t('reservation.setting.advanced-setting.delete-all.alert.title'),
      message: t('reservation.setting.advanced-setting.delete-all.alert.message'),
      textButton: t('common.button.delete'),
      onConfirm: async () => {
        await deleteAllReservations({
          variables: { branchID: branch?.id ?? '' },
        });
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onCancel: async () => {
      },
    });
  };
  const { t } = useTranslate();

  return (
    <Card>
      <CardHeader title={t('reservation.setting.advanced-setting.title')} />
      <CardContent>
        <CardSection
          title={t('reservation.setting.advanced-setting.delete-all.title')}
          descriptions={t('reservation.setting.advanced-setting.delete-all.description')}>
          <DestroyButton
            onClick={deleteAllHandler}>
            {t('reservation.setting.advanced-setting.delete-all.button')}
          </DestroyButton>
        </CardSection>
      </CardContent>
    </Card>
  );
};

export default TableReservationAdvancedSetting;
