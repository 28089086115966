import classNames from 'classnames';
import { ReactNode } from 'react';

export default function TableRow(props: {
  className?: string;
  compact?: boolean;
  selected?: boolean;
  children?: ReactNode;
  onClick?: () => void;
}) {
  return (
    <tr
      onClick={props.onClick}
      className={classNames(
        'hover:bg-gray-50',
        props.compact ? 'h-8' : 'h-14',
        props.className,
        props.selected ? 'bg-indigo-50' : undefined
      )}
    >
      {props.children}
    </tr>
  );
}
