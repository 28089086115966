import CardSection from '@packages/ui/card/card-section.tsx';
import DestroyButton from '@packages/ui/button/destroy-button.tsx';
import { useCallback } from 'react';
import {
  useDeleteMenuProductConfigurationValue,
} from '@client/page/management/menu/menu-product-configuration-value-edit/logic/use-delete-menu-product-configuration-value.ts';
import { useNavigate } from 'react-router-dom';
import AlertError from '@packages/ui/alert/alert-error.tsx';
import { useTranslate } from "@tolgee/react";

export default function DeleteProductConfigurationValueMenu(props: {
  valueId: string;
}) {
  const navigate = useNavigate();
  const [update, { loading, error }] = useDeleteMenuProductConfigurationValue();

  const submit = useCallback(() => {
    update({ variables: { id: props.valueId } })
      .then(() => {
        navigate('..');
      })
      .catch((e) => void e);
  }, [navigate, props.valueId, update]);
  const { t } = useTranslate();
  return (
    <CardSection
      title={t('menu.menu-detail.product.edit.configurations.edit.value.edit.extra-action.delete.title')}
      descriptions={t('menu.menu-detail.product.edit.configurations.edit.value.edit.extra-action.delete.description')}
    >
      {error && (
        <AlertError
          className="mb-4"
          title={error.name}
          message={error.message}
        />
      )}
      <DestroyButton
        data-testid="delete-button"
        onClick={submit}
        loading={loading}
      >
        {t('common.button.delete')}
      </DestroyButton>
    </CardSection>
  );
}
