import { graphql } from '@client/graphql/types';
import { useMutation } from '@apollo/client';

const MUTATION = graphql(`
  mutation OnlineOrderCancel($id: ID!) {
    onlineOrderOwnerCancel(id: $id)
  }
`);

export default function useCancelOnlineOrder() {
  return useMutation(MUTATION, {
    refetchQueries: ['OnlineOrderDetail','OnlineOrderDashboard'],
  });
}
