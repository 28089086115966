import { useEffect, useState } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';

interface PaginationData {
  size: number;
  perPage: number;
  current: number;
  buttonNumberDisplay: number;
}

export default function Pagination(props: { info: PaginationData; setCurrent: (newCurrent: number) => void }) {
  const { info } = props;
  const { size, perPage, current, buttonNumberDisplay } = info;
  const [buttons, setButtons] = useState<number[]>([]);
  const [move, setMove] = useState<number>(0);

  useEffect(() => {
    const newButtonCount = size % perPage == 0 ? size / perPage : size / perPage + 1;

    const newButtons = Array.from({ length: newButtonCount }, (_, index) => index);
    setButtons(newButtons);
  }, [size, perPage]);

  const moveRight = () => {
    if (move < buttons.length - buttonNumberDisplay) setMove(move + 1);
  };

  const moveLeft = () => {
    if (move > 0) setMove(move - 1);
  };

  // const onEnterPage = () => {
  //   const numberCheck = Number(enterPage) - 1;
  //   if (numberCheck >= 0 && numberCheck < buttons.length) {
  //     if (numberCheck >= 5) setMove(numberCheck - buttonNumberDisplay + 1);
  //     else setMove(0);
  //     setCurrent(numberCheck);
  //     return;
  //   }
  //   alert('Have an error when you enter page number, please check');
  // };

  return (
    <>
      <div className="flex flex-1 justify-between sm:hidden">
        <a
          href="#"
          className={
            classNames(
              'relative inline-flex items-center',
              'rounded-md border border-gray-300 bg-white px-4 py-2',
              'text-sm font-medium text-gray-700 hover:bg-gray-50')
          }
        >
          Previous
        </a>
        <a
          href="#"
          className={classNames('relative ml-3 inline-flex items-center hover:bg-gray-50',
            'rounded-md border border-gray-300 bg-white px-4 py-2',
            'text-sm font-medium text-gray-700 ')}
        >
          Next
        </a>
      </div>

      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            {size > 0 ? (
              <>
                Showing <span className="font-medium">{current * perPage + 1}</span> to{' '}
                <span className="font-medium">{Math.min((current + 1) * perPage, size)}</span> of{' '}
                <span className="font-medium">{size}</span> results
              </>
            ) : (
              <></>
            )}
          </p>
        </div>
        <div>
          <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
            <div
              className="relative inline-flex items-center rounded-l-md px-2 py-2
              text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
              onClick={moveLeft}
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </div>

            {buttons.slice(move, move + buttonNumberDisplay).map((item) => (
              <div
                key={item}
                aria-current="page"
                className={classNames(
                  'relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ',
                  'ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0',
                  {
                    'focus-visible:outline-indigo-600':
                      item == current,
                  },
                )}
                onClick={() => {
                  setMove(item);
                }}
              >
                {item + 1}
              </div>
            ))}
            <div
              className="
              relative inline-flex items-center rounded-r-md
              px-2 py-2 text-gray-400 ring-1 ring-inset
              ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0
              "
              onClick={moveRight}
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </div>
          </nav>
        </div>
      </div>
    </>
  );
}
