import { graphql } from '@client/graphql/types';
import { useMutation } from '@apollo/client';

const MUTATION = graphql(`
  mutation TableReservationUpdate($id: ID!, $input: TableReservationUpdate!) {
    updateTableReservation(id: $id, input: $input) {
      id
      platformType
      tableId
      guestName
      status
      message
      startTime
      seats
    }
  }
`);

export default function useTableReservationUpdate() {
  return useMutation(MUTATION, {});
}
