import { DaysOfWeek } from '@client/graphql/types/graphql';
import classNames from 'classnames';
import { useEffect, useState } from 'react';

interface SelectDay {
  name: string;
  value: DaysOfWeek;
  selected: boolean;
}

export default function SelectDaysOfWeek(props: { value: DaysOfWeek[]; onChange: (value: DaysOfWeek[]) => void }) {
  const [list, setList] = useState<SelectDay[]>([
    { name: 'Monday', value: DaysOfWeek.Monday, selected: false },
    { name: 'Tuesday', value: DaysOfWeek.Tuesday, selected: false },
    { name: 'Wednesday', value: DaysOfWeek.Wednesday, selected: false },
    { name: 'Thursday', value: DaysOfWeek.Thursday, selected: false },
    { name: 'Friday', value: DaysOfWeek.Friday, selected: false },
    { name: 'Saturday', value: DaysOfWeek.Saturday, selected: false },
    { name: 'Sunday', value: DaysOfWeek.Sunday, selected: false },
  ]);

  useEffect(() => {
    setList(
      list.map((item) => {
        if (props.value.includes(item.value)) {
          item.selected = true;
        }
        return item;
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const handleClick = (name: string) => {
    const newList = list.map((item) => {
      if (item.name == name) {
        item.selected = !item.selected;
      }
      return item;
    });

    props.onChange(newList.filter((item) => item.selected).map((item) => item.value));

    setList(newList);
  };
  return (
    <div>
      <ul className="grid grid-cols-2 sm:grid-cols-3 gap-3 max-w-sm">
        {list.map((item) => (
          <li
            key={item.value}
            className={classNames(
              'border px-3 py-1 rounded-md border-gray-300 text-gray-900 font-medium text-sm text-center cursor-pointer',
              item.selected ? 'bg-indigo-600 text-white' : ''
            )}
            onClick={() => {
              handleClick(item.name);
            }}
          >
            {item.name}
          </li>
        ))}
      </ul>
    </div>
  );
}
