import { graphql } from '@client/graphql/types';
import { useMutation } from '@apollo/client';

const MUTATION = graphql(`
  mutation OnlineOrderChangeDeliveryPrice($id: ID!, $deliveryPrice: Int!) {
    onlineOrderChangeDeliveryPrice(id: $id, deliveryPrice: $deliveryPrice)
  }
`);

export default function useChangeDeliveryPrice() {
  return useMutation(MUTATION, {
    refetchQueries: ['OnlineOrderDetail'],
  });
}
