import { useNavigate, useParams } from 'react-router-dom';
import Card from '@packages/ui/card/card.tsx';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import CardHeader from '@packages/ui/card/card-header.tsx';
import SecondaryButton from '@packages/ui/button/secondary-button.tsx';
import PrimaryButton from '@packages/ui/button/primary-button.tsx';
import AlertError from '@packages/ui/alert/alert-error.tsx';
import MenuProductTable from '@client/page/management/menu/menu-detail/component/menu-product-table.tsx';
import Loading from '@packages/ui/loading.tsx';
import MenuDetailDeleteButton from '@client/page/management/menu/menu-detail/component/menu-detail-delete-button.tsx';
import CardContent from '@packages/ui/card/card-content.tsx';
import CardSection from '@packages/ui/card/card-section.tsx';
import { ContentLayout, PrimaryContent } from 'ui/src/content-layout';
import { useEffect } from 'react';
import CardTableContent from '@packages/ui/card/card-content-table.tsx';
import { useTranslate } from "@tolgee/react";
import { Helmet } from 'react-helmet-async';
import { useGetMenu } from './logic/use-get-menu';

function MenuDetail() {
  const menuId = useParams().menuId ?? '';
  const navigate = useNavigate();
  const { t } = useTranslate();

  const { data, loading, refetch, error } = useGetMenu(menuId);

  useEffect(() => {
    navigate(location.pathname, {
      replace: true,
      state: { breadcrumbName: data?.menu.title },
    });
  }, [navigate, data?.menu.title]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <AlertError title={error.name} message={error.message} />;
  }

  if (!data) {
    return <Card>No data</Card>;
  }
  return (
    <ContentLayout>
      <Helmet>
        <title>{t('menu.menu-detail.helmet.title')}</title>
        <meta
          name="description"
          content="This page displays the details of a menu"
        ></meta>
      </Helmet>
      <PrimaryContent>
        <div className="flex-col space-y-4">
          <Card>
            <CardHeader title={data.menu.title} withBackButton={true}>
              <SecondaryButton
                onClick={() => {
                  navigate(`sections/create`);
                }}
              >
                {t('menu.menu-detail.table-product.button.add-category')}
              </SecondaryButton>

              <PrimaryButton
                onClick={() => {
                  navigate('products/create');
                }}
              >
                {t('menu.menu-detail.table-product.button.add-product')}
              </PrimaryButton>

              <SecondaryButton
                onClick={() => {
                  refetch().catch((e) => void e);
                }}
              >
                <ArrowPathIcon className="w-4" />
              </SecondaryButton>
            </CardHeader>

            <CardTableContent>
              <MenuProductTable
                products={data.menu.menuProducts}
                sections={data.menu.sections}
              />
            </CardTableContent>
          </Card>

          <Card>
            <CardHeader title={t('menu-detail.extra-action')} />
            <CardContent>
              <div className="flex flex-col space-y-8">
                <CardSection
                  title={t('menu.menu-detail.extra-action.edit.title')}
                  descriptions={t('menu.menu-detail.extra-action.edit.description')}
                >
                  <SecondaryButton
                    onClick={() => {
                      navigate(`edit`);
                    }}
                  >
                    {t('menu.menu-detail.extra-action.edit.button')}
                  </SecondaryButton>
                </CardSection>

                <MenuDetailDeleteButton menuId={menuId} />
              </div>
            </CardContent>
          </Card>
        </div>
      </PrimaryContent>
    </ContentLayout>
  );
}

export default MenuDetail;
