import { ContentLayout, PrimaryContent } from 'ui/src/content-layout';
import CardHeader from 'ui/src/card/card-header';
import Card from 'ui/src/card/card';
import CardContent from 'ui/src/card/card-content';
import FormLayout from 'ui/src/form/form-layout';
import FormItem from 'ui/src/form/form-item';
import TextInput from 'ui/src/form/input/text-input';
import PrimaryButton from 'ui/src/button/primary-button';
import CardSection from 'ui/src/card/card-section';
import { useEffect } from 'react';
import useFormData from '@client/module/utils/use-form-data';
import ApplicationErrorView from '@client/module/error/application-error-view';
import Loading from '@packages/ui/loading.tsx';
import { useNavigate } from 'react-router-dom';
import useCurrentUser from './logic/use-current-user';

interface UserProfile {
  userId: string;
  username: string;
  firstname: string;
  lastname: string;
  email: string;
}

export default function UserProfile() {
  const navigate = useNavigate();
  const { data, loading, error } = useCurrentUser();
  const {
    data: form,
    handle,
    setData,
  } = useFormData<UserProfile>({
    userId: '',
    username: '',
    firstname: '',
    lastname: '',
    email: '',
  });

  useEffect(() => {
    if (!data) return;
    setData({
      userId: data.currentUser?.id ?? '',
      username: data.currentUser?.username ?? '',
      firstname: data.currentUser?.firstName ?? '',
      lastname: data.currentUser?.lastName ?? '',
      email: data.currentUser?.email ?? '',
    });
  }, [data, setData]);

  return (
    <ContentLayout>
      <PrimaryContent>
        <Card>
          {loading && <Loading />}

          <CardHeader title="User profile" />

          <CardContent>
            <FormLayout>
              <FormItem title={'Username'} className="max-w-md">
                <TextInput
                  data-testid="Username-input"
                  type="text"
                  label="title"
                  value={form.username}
                  name="username"
                  placeholder="Username"
                  // error={}
                  onChange={handle}
                />
              </FormItem>

              <FormItem title={'Firstname'} className="max-w-md">
                <TextInput
                  data-testid="fullname-input"
                  type="text"
                  label="Firstname"
                  value={form.firstname}
                  name="firstName"
                  placeholder="fullname"
                  // error={}
                  onChange={handle}
                />
              </FormItem>

              <FormItem title={'Lastname'} className="max-w-md">
                <TextInput
                  data-testid="fullname-input"
                  type="text"
                  label="Lastname"
                  value={form.lastname}
                  name="lastName"
                  placeholder="fullname"
                  // error={}
                  onChange={handle}
                />
              </FormItem>

              <FormItem title={'Email'} className="max-w-md">
                <TextInput
                  data-testid="description-input"
                  type="text"
                  label="description"
                  value={form.email}
                  name="email"
                  placeholder="description"
                  // error={}
                  onChange={handle}
                />
              </FormItem>

              {/* <FormItem title={'User Role'} className="max-w-md">
                {data?.currentUser?.role.length !== 0 ? (
                  <div className="flex">
                    {data?.currentUser?.role.map((item, index) => (
                      <div
                        key={index}
                        className="border border-1 border-gray-300 shadow-md bg-white py-2 px-4 rounded-md"
                      >
                        {item}
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="italic text-gray-400">
                    User role is undefined
                  </div>
                )}
              </FormItem> */}
              <ApplicationErrorView error={error} />
            </FormLayout>
          </CardContent>
        </Card>

        <Card>
          <CardHeader title="Action" />
          <CardContent>
            <CardSection
              title="Reset password"
              descriptions="This action will be reset your password."
            >
              <PrimaryButton
                onClick={() => {
                  // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                  navigate('/reset-password/'+ data?.currentUser?.id)
                }}
              >
                {' '}
                Change
              </PrimaryButton>
            </CardSection>
          </CardContent>
        </Card>
      </PrimaryContent>
    </ContentLayout>
  );
}
