import { UserCompanyPermission } from '@client/graphql/types/graphql';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

export default function UserRoleSelected({
  permissions,
  setPermissions,
  manualHandle,
}: {
  permissions: Set<UserCompanyPermission>;
  setPermissions: Dispatch<SetStateAction<Set<UserCompanyPermission>>>;
  manualHandle: (name: string, value: unknown) => void;
}) {
  const [showList, setShowList] = useState<boolean>(false);

  const handleCheckbox = (permission: string) => {
    const newPermissionList = new Set<UserCompanyPermission>(permissions);
    newPermissionList.has(
      UserCompanyPermission[permission as keyof typeof UserCompanyPermission]
    )
      ? newPermissionList.delete(
          UserCompanyPermission[
            permission as keyof typeof UserCompanyPermission
          ]
        )
      : newPermissionList.add(
          UserCompanyPermission[
            permission as keyof typeof UserCompanyPermission
          ]
        );
    setPermissions(newPermissionList);
    manualHandle('permission', Array.from(newPermissionList));
  };

  let userRoleListVisible = 'invisible absolute';
  if (showList) {
    userRoleListVisible = 'visible';
  }
  const [selectedCount, setSelectedCount] = useState(0);

  useEffect(() => {
    let count = 0;
    permissionArray.forEach((item) => {
      if (permissions.has(item.value as UserCompanyPermission)) {
        count++;
      }
    });
    setSelectedCount(count);
  }, [permissions]);
  return (
    <>
      <div
        onClick={() => {
          setShowList(!showList);
        }}
        className="cursor-pointer ring-1 ring-inset ring-gray-300 rounded-md shadow-sm p-2 flex justify-between relative"
      >
        <p>{selectedCount} managements selected</p>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m19.5 8.25-7.5 7.5-7.5-7.5"
          />
        </svg>
      </div>

      <div
        className={
          'mt-0.5 h-[150px] overflow-scroll	no-scrollbar ring-1 ring-inset ring-gray-300 rounded-md ' +
          userRoleListVisible
        }
      >
        {permissionArray.map((item) => (
          <div
            key={item.key}
            className="flex gap-2 items-center py-1 px-2 hover:bg-gray-200"
          >
            <input
              type="checkbox"
              checked={permissions.has(item.value as UserCompanyPermission)}
              onChange={() => {
                handleCheckbox(item.key);
                if (permissions.has(item.value as UserCompanyPermission)) {
                  setSelectedCount((prevCount) => Math.max(0, prevCount - 1)); // Prevent count from going negative
                } else {
                  setSelectedCount((prevCount) => prevCount + 1);
                }
              }}
              className="rounded-sm"
            />
            <p className="cursor-pointer">
              {item.value.replace('_', ' ').toLowerCase()}
            </p>
          </div>
        ))}
      </div>
    </>
  );
}

interface PermissionArray {
  key: string;
  value: string;
}

function ConvertPermissionEnum() {
  const permissionKey = Object.keys(UserCompanyPermission);
  const permissionValue = Object.values(UserCompanyPermission);
  const permissionArray: PermissionArray[] = [];
  for (let i = 0; i < permissionKey.length; i++) {
    permissionArray.push({
      key: permissionKey[i],
      value: permissionValue[i],
    });
  }

  return permissionArray;
}

const permissionArray = ConvertPermissionEnum();
