import { ReactNode } from 'react';

interface OverlayProps {
  isHidden: boolean;
  toggleVisibility: () => void;
  children: ReactNode;
}

function Overlay({ isHidden, toggleVisibility, children }: OverlayProps) {
  return (
    <div
      className={`absolute ${
        isHidden ? 'hidden' : ''
      }  top-0 left-0 bg-black bg-opacity-50 z-10 cursor-pointer fixed flex items-center justify-center `}
      onClick={toggleVisibility}
    >
      {children}
    </div>
  );
}

export default Overlay;
