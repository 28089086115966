import Card from 'ui/src/card/card';
import CardContent from 'ui/src/card/card-content';
import FormItem from 'ui/src/form/form-item';
import FormLayout from 'ui/src/form/form-layout';
import TextInput from 'ui/src/form/input/text-input';
import ToggleInput from 'ui/src/form/input/toggle-input';
import CardFooter from 'ui/src/card/card-footer';
import SecondaryButton from 'ui/src/button/secondary-button';
import PrimaryButton from 'ui/src/button/primary-button';
import CardHeader from 'ui/src/card/card-header';
import { useNavigate, useParams } from 'react-router-dom';
import {
  TimeRuleType,
  ContentType,
  TopContent,
} from '@client/graphql/types/graphql';
import useFormData from '@client/module/utils/use-form-data';
import { useEffect } from 'react';
import {
  formatGraphQlError,
  validationErrors,
} from '@client/module/error/error';

import { TopBannerPreview } from '@client/page/management/banner/banner-preview/top-banner-preview.tsx';
import FullDateView from '@packages/ui/date/full-date-view.tsx';
import { useTimeRuleForm } from '@client/page/management/banner/logic/use-time-rule-form.ts';
import moment from 'moment-timezone';
import { useFocusedCompanyContext } from '@packages/core/company/focused-company-context.ts';
import MenuSelectionInput from '@packages/ui/form/input/menu-selection-input.tsx';
import { Helmet } from 'react-helmet-async';
import BannerDeleteButton from './component/banner-delete-button';
import useBanner from './logic/use-banner';
import useBannerUpdate from './logic/use-banner-update';

interface TopBannerForm {
  title: string;
  enabled: boolean;
  contentType: ContentType;
  text: string;
  action: string;
  backgroundColor: string;
  foregroundColor: string;
}

export default function TopBannerUpdate() {
  const { settings } = useFocusedCompanyContext();
  const navigate = useNavigate();
  const bannerId = useParams().bannerId ?? '';
  const [update, { loading, error: updateError }] = useBannerUpdate();
  const { data, error: loadingError } = useBanner(bannerId);

  const {
    data: timeRuleForm,
    manualHandle: manualUpdateTimeRuleForm,
    setTimeRuleType,
  } = useTimeRuleForm(data?.banner.timeRule);

  const {
    data: form,
    handle,
    manualHandle,
    setData,
  } = useFormData<TopBannerForm>({
    title: '',
    enabled: false,
    contentType: ContentType.Top,
    text: '',
    action: '',
    backgroundColor: '#FFFFFF',
    foregroundColor: '#000000',
  });

  const error = loadingError ?? updateError;
  const applicationErrors = formatGraphQlError(error?.graphQLErrors);
  const validationError = validationErrors(applicationErrors);

  useEffect(() => {
    if (data && data.banner.content.__typename === 'TopContent') {
      const banner = data.banner;
      const content = banner.content as TopContent;

      setData({
        title: banner.title,
        enabled: banner.enabled,
        contentType: ContentType.Top,
        text: content.text ?? '',
        action: content.action ?? '',
        backgroundColor: content.backgroundColor ?? '#FFFFFF',
        foregroundColor: content.foregroundColor ?? '#000000',
      });
    }
  }, [data, setData]);

  const onSubmit = async () => {
    await update({
      variables: {
        bannerId: bannerId,
        input: {
          title: form.title,
          enabled: form.enabled,
        },
        timeRuleInput: {
          type: timeRuleForm.timeRuleType,
          start: timeRuleForm.start,
          end: timeRuleForm.end,
        },
        contentInput: {
          type: form.contentType,
          text: form.text,
          action: form.action,
          backgroundColor: form.backgroundColor,
          foregroundColor: form.foregroundColor,
        },
      },
    })
      .then(() => {
        navigate('../..');
      })
      .catch((e) => {
        /* eslint-disable no-console */
        console.log(e);
      });
  };

  return (
    <div className="flex flex-col sm:flex-row">
      <Helmet>
        <title>Update top banner</title>
        <meta
          name="description"
          content="This page allows you to update a top banner"
        />
      </Helmet>

      <div className="flex flex-col  space-y-4 sm:w-2/3  pb-4 sm:pb-0">
        <Card>
          <CardHeader
            withBackButton={true}
            backTarget="../.."
            title={'Update top banner'}
          />

          <CardContent>
            <FormLayout>
              <FormItem title={'Title'} className="max-w-md">
                <TextInput
                  data-testid="title-input"
                  type="text"
                  label="title"
                  name="title"
                  placeholder="Title"
                  value={form.title}
                  error={validationError.title}
                  autoComplete={'off'}
                  onChange={handle}
                />
              </FormItem>

              <FormItem title={'Text'} className="max-w-md">
                <TextInput
                  data-testid="text-input"
                  type="text"
                  label="text"
                  name="text"
                  placeholder="Text"
                  value={form.text}
                  error={validationError.text}
                  autoComplete={'off'}
                  onChange={handle}
                />
              </FormItem>

              <FormItem title={'Action'} className="max-w-md">
                <TextInput
                  data-testid="action-input"
                  type="text"
                  label="action"
                  name="action"
                  placeholder="Action"
                  value={form.action}
                  error={validationError.action}
                  autoComplete={'off'}
                  onChange={handle}
                />
              </FormItem>

              <FormItem title={'Time rule type'} className="max-w-md">
                <div className="flex flex-col gap-4">
                  <div className="">
                    <MenuSelectionInput
                      title=""
                      data={Object.values(TimeRuleType)}
                      value={timeRuleForm.timeRuleType}
                      onChange={(newValue) => {
                        setTimeRuleType(newValue);
                      }}
                      build={(item) => {
                        return {
                          id: item,
                          name: item,
                        };
                      }}
                      className="-my-2 w-full"
                    />
                  </div>
                  {timeRuleForm.timeRuleType == TimeRuleType.RangeTime && (
                    <div>
                      <FormItem title="Timezone" className="pt-4">
                        <TextInput
                          type={'text'}
                          label={'timezone'}
                          value={settings.timezone}
                          name={'timezone'}
                          placeholder={'Timezone'}
                          // eslint-disable-next-line @typescript-eslint/no-empty-function
                          onChange={() => {}}
                        />
                      </FormItem>

                      <FormItem title="Range" className="pt-4">
                        <div className="max-w-full flex gap-2">
                          <input
                            className="rounded-md max-w-full border-gray-300"
                            aria-label="Start Date"
                            id="start"
                            name="start"
                            value={moment(timeRuleForm.start).format(
                              'YYYY-MM-DD HH:mm'
                            )}
                            type="datetime-local"
                            onChange={(event) => {
                              const value = event.target.value;
                              manualUpdateTimeRuleForm(
                                'start',
                                moment(value)
                                  .tz(settings.timezone)
                                  .toISOString()
                              );
                            }}
                          />

                          <input
                            className="rounded-md max-w-full border-gray-300"
                            aria-label="End Date"
                            id="end"
                            name="end"
                            value={moment(timeRuleForm.end).format(
                              'YYYY-MM-DD HH:mm'
                            )}
                            type="datetime-local"
                            onChange={(event) => {
                              const value = event.target.value;
                              manualUpdateTimeRuleForm(
                                'end',
                                moment(value)
                                  .tz(settings.timezone)
                                  .toISOString()
                              );
                            }}
                          />
                        </div>

                        <div>
                          {timeRuleForm.start &&
                            timeRuleForm.end &&
                            timeRuleForm.start >= timeRuleForm.end && (
                              <p
                                className="mt-2 text-xs italic text-red-600"
                                id="email-error"
                              >
                                {
                                  'Choose startDate and endDate, startDate must be smaller than endDate'
                                }
                              </p>
                            )}
                        </div>
                      </FormItem>
                    </div>
                  )}

                  {timeRuleForm.timeRuleType == TimeRuleType.Always && (
                    <div></div>
                  )}
                </div>
              </FormItem>

              <FormItem title={'Background color'} className="max-w-md">
                <div className="flex flex-col gap-4">
                  <input
                    type="color"
                    name="backgroundColor"
                    value={form.backgroundColor}
                    onChange={handle}
                  />
                </div>
              </FormItem>

              <FormItem title={'Foreground color'} className="max-w-md ">
                <div className="flex flex-col gap-4">
                  <input
                    type="color"
                    name="foregroundColor"
                    value={form.foregroundColor}
                    onChange={handle}
                  />
                </div>
              </FormItem>

              <FormItem title="Create at" className="w-64">
                <FullDateView date={data?.banner.createAt} />
              </FormItem>

              <FormItem title="Last update at" className="w-64">
                <FullDateView date={data?.banner.updateAt} />
              </FormItem>

              <FormItem title="Active" className="w-32">
                <ToggleInput
                  value={form.enabled}
                  onChange={(newValue) => {
                    manualHandle('enabled', newValue);
                  }}
                />
              </FormItem>
            </FormLayout>
          </CardContent>

          <CardFooter>
            <div className="flex justify-center space-x-4 w-96">
              <SecondaryButton
                className="flex-1"
                onClick={() => {
                  navigate('../..');
                }}
              >
                Back
              </SecondaryButton>
              <PrimaryButton
                id="create-button"
                data-testid="create-button"
                className="w-32 flex-1"
                onClick={() => {
                  onSubmit().catch((e) => void e);
                }}
                loading={loading}
              >
                Update
              </PrimaryButton>
            </div>
          </CardFooter>
        </Card>
        <Card>
          <CardContent>
            <BannerDeleteButton bannerId={bannerId} />
          </CardContent>
        </Card>
      </div>

      <div className="sm:w-1/3  sm:mt-0 mt-2">
        <TopBannerPreview
          content={form.text}
          backgroundColour={form.backgroundColor}
          foregroundColour={form.foregroundColor}
        />
      </div>
    </div>
  );
}
