import { useQuery } from '@apollo/client';
import { graphql } from '@client/graphql/types';

const GET_OPENING_TIME_QUERY = graphql(`
  query OpeningTime($id: ID!) {
    openingTime(id: $id) {
      id
      branchId
      dayOfWeek
      openTime
      closeTime
      note
      isActive
    }
  }
`);

export function useGetOpeningTime(openingTimeId: string) {
  return useQuery(GET_OPENING_TIME_QUERY, {
    variables: { id: openingTimeId },
  });
}
