import Card from '@packages/ui/card/card.tsx';
import SecondaryButton from '@packages/ui/button/secondary-button.tsx';
import { useNavigate, useParams } from 'react-router-dom';
import { useCallback, useRef } from 'react';
import useCreateMenuProduct from '@client/page/management/menu/menu-product-create/logic/use-create-menu-product.ts';
import { formatGraphQlError, validationErrors } from '@client/module/error/error.ts';
import FormLayout from '@packages/ui/form/form-layout.tsx';
import FormItem from '@packages/ui/form/form-item.tsx';
import TextInput from '@packages/ui/form/input/text-input.tsx';
import PrimaryButton from '@packages/ui/button/primary-button.tsx';
import MenuSectionSelection
  from '@client/page/management/menu/menu-section/menu-section-selection-input/menu-section-selection.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import CardContent from '@packages/ui/card/card-content.tsx';
import CardFooter from '@packages/ui/card/card-footer.tsx';
import ToggleInput from '@packages/ui/form/input/toggle-input.tsx';
import useFormData from '@client/module/utils/use-form-data.ts';
import { ContentLayout, PrimaryContent } from 'ui/src/content-layout';
import { client } from '@client/graphql/client.ts';
import ImageInput from '@packages/core/uploading/image-input.tsx';
import { Helmet } from 'react-helmet-async';
import {
  AllergicType,
  MenuProductConfiguration,
  MenuProductLabel,
  ProductConfigurationType,
} from '@client/graphql/types/graphql';
import ApplicationErrorView from '@client/module/error/application-error-view.tsx';
import MenuProductLabelSelect
  from '@client/page/management/menu/menu-product-create/component/menu-product-label-select.tsx';
import { useTranslate } from "@tolgee/react";
import { v4 as uuidv4 } from 'uuid';
import AllergicSelection from '../menu-product-edit/components/allergic-selection';
import MenuProductConfigurationCreateTable from './component/menu-product-configuration-create-table';


interface Form {
  title: string;
  description: string;
  images: string[];
  ingredients: string[];
  sectionId: string | null;
  enabled: boolean;
  configurations: MenuProductConfiguration[];
  code: string;
  labels: MenuProductLabel[];
  allergics: AllergicType[];
}

const DESCRIPTION_MAX_LENGTH = 200;

export default function MenuProductCreate() {
  const menuId = useParams().menuId ?? '';
  const navigate = useNavigate();
  const rawBaseConfigurationId = useRef(uuidv4());

  const { t } = useTranslate();
  const [create, { loading, error }] = useCreateMenuProduct();

  const applicationErrors = formatGraphQlError(error?.graphQLErrors);
  const validationError = validationErrors(applicationErrors);

  const {
    data: form,
    handle,
    manualHandle,
  } = useFormData<Form>({
    title: '',
    description: '',
    images: [],
    ingredients: [],
    sectionId: null,
    enabled: true,
    configurations: [
      {
        id: rawBaseConfigurationId.current,
        title: 'Base',
        type: ProductConfigurationType.Base,
        values: [],
      },
    ],
    code: '',
    allergics: [],
    labels: [],
  });

  const configurationHandle = useCallback((value: unknown) => {
    manualHandle('configurations', value);
  }, [manualHandle]);


  const onSubmit = useCallback(
    (form: Form) => {
      // if (form.configurations.find(item => item.id == rawBaseConfigurationId.current)?.values.length == 0) {
      //   return;
      // }
      create({
        variables: {
          menuId: menuId,
          input: {
            ...form,
            configurations: removeIdFromMenuProduct(form.configurations),
          },
        },
      })
        .then((product) => {
          const createdProduct = product.data?.createMenuProduct;
          if (createdProduct) {
            navigate(`..`);
          }
        })
        .catch((e) => void e);
    },
    [create, menuId, navigate],
  );

  return (
    <ContentLayout>
      <Helmet>
        <title>{t('menu.menu-detail.product.create.helmet.title')}</title>
        <meta name="description" content="This page allows you to create a new product" />
      </Helmet>
      <PrimaryContent>
        <ApplicationErrorView error={error} />

        <Card>
          <CardHeader title={t('menu.menu-detail.product.create.header')} />

          <CardContent>
            <FormLayout>
              <FormItem className="max-w-md" title={t('menu.menu-detail.product.create.form-input.code.title')}>
                <TextInput
                  data-testid="code-input"
                  type="text"
                  label="code"
                  name="code"
                  placeholder={t('menu.menu-detail.product.create.form-input.code.placeholder')}
                  value={form.code}
                  error={validationError.code}
                  autoComplete={'off'}
                  onChange={handle}
                />
              </FormItem>

              <FormItem className="max-w-md" title={t('menu.menu-detail.product.create.form-input.name.title')}>
                <TextInput
                  data-testid="title-input"
                  type="text"
                  label="title"
                  name="title"
                  placeholder={t('menu.menu-detail.product.create.form-input.name.placeholder')}
                  value={form.title}
                  error={validationError.title}
                  autoComplete={'off'}
                  onChange={handle}
                />
              </FormItem>

              <FormItem
                subtitle={`(${String(form.description.length)}/${String(DESCRIPTION_MAX_LENGTH)})`}

                className="max-w-md" title={t('menu.menu-detail.product.create.form-input.description.title')}>

                <textarea
                  className="w-full p-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-200"
                  placeholder={t('menu.menu-detail.product.create.form-input.description.placeholder')}
                  value={form.description}
                  name="description"
                  onChange={(event) => {
                    manualHandle('description', event.target.value);
                  }}
                  maxLength={DESCRIPTION_MAX_LENGTH}
                />
              </FormItem>

              <FormItem title={t('menu.menu-detail.product.create.form-input.allergic.title')} className="w-48">
                <AllergicSelection
                  allergics={form.allergics}
                  onChange={(value) => {
                    manualHandle('allergics', value);
                  }}
                />
              </FormItem>


              <MenuSectionSelection
                menuId={menuId}
                value={form.sectionId}
                onChange={(sectionId) => {
                  manualHandle('sectionId', sectionId);
                }}
              />

              <FormItem title="Label" className="max-w-md">
                <MenuProductLabelSelect
                  label={form.labels}
                  handleLabel={(value, selected) => {
                    if (selected) {
                      manualHandle('labels', [...form.labels, value]);
                    } else {
                      manualHandle(
                        'labels',
                        form.labels.filter((label) => label !== value),
                      );
                    }
                  }}
                />
              </FormItem>

              <div>
                <ImageInput
                  client={client}
                  images={form.images}
                  onChange={(newValue) => {
                    manualHandle('images', newValue);
                  }}
                  multiple={true}
                />
                <p className="text-red-500 text-sm mt-2">{validationError.images}</p>
              </div>

              <div>
                <MenuProductConfigurationCreateTable
                  data={form.configurations}
                  configurationHandle={configurationHandle}
                />
                 {validationError.configurations && (
                  <p className="mt-2 text-sm text-red-600" id="email-error">
                    {validationError.configurations}
                  </p>
                )}
              </div>

              <FormItem title={t('menu.menu-detail.product.create.form-input.active-toggle.title')} className="w-32">
                <ToggleInput
                  value={form.enabled}
                  onChange={(newValue) => {
                    manualHandle('enabled', newValue);
                  }}
                />
              </FormItem>
            </FormLayout>
          </CardContent>

          <CardFooter>
            <div className="flex justify-center space-x-4 w-96">
              <SecondaryButton
                className="flex-1"
                onClick={() => {
                  navigate('..');
                }}
              >
                {t('common.button.cancel')}
              </SecondaryButton>
              <PrimaryButton
                id="create-button"
                data-testid="create-button"
                className="w-32 flex-1"
                onClick={() => {
                  onSubmit(form);
                }}
                loading={loading}
              >
                {t('common.button.create')}
              </PrimaryButton>
            </div>
          </CardFooter>
        </Card>
      </PrimaryContent>
    </ContentLayout>
  );
}

function removeIdFromMenuProduct(configuration: MenuProductConfiguration[]) {
  const newConfigurations = configuration.map((item) => {
    delete (item as { id?: string }).id;
    item.values = item.values.map((valueItem) => {
      delete (valueItem as { id?: string }).id;
      return valueItem;
    });
    return item;
  });
  return newConfigurations;
}