import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  formatGraphQlError,
  validationErrors,
} from '@client/module/error/error';
import CardContent from '@packages/ui/card/card-content';
import FormLayout from '@packages/ui/form/form-layout';
import TextInput from '@packages/ui/form/input/text-input';
import FormItem from '@packages/ui/form/form-item';
import Card from '@packages/ui/card/card';
import CardHeader from '@packages/ui/card/card-header';
import AlertError from '@packages/ui/alert/alert-error';
import CardFooter from '@packages/ui/card/card-footer';
import SecondaryButton from '@packages/ui/button/secondary-button';
import PrimaryButton from '@packages/ui/button/primary-button';
import useFormData from '@client/module/utils/use-form-data';
import ToggleInput from '@packages/ui/form/input/toggle-input';
import { UserCompanyPermission } from '@client/graphql/types/graphql';
import { useCompanyId } from 'core/src/company/focused-company-context';
import CardSection from '@packages/ui/card/card-section.tsx';
import { ContentLayout, PrimaryContent } from 'ui/src/content-layout';
import { Helmet } from 'react-helmet-async';
import UserRoleSelected from '../user-create/component/user-role-selected';
import useUserDetail from './logic/use-user-detail';
import UserDelete from './component/user-delete';
import useUserCompanyRoleUpdate from './logic/use-user-company-role-update';
import useResetUserPassword from './logic/use-reset-password';

interface FormData {
  id: string;
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  status: boolean;
  permission: UserCompanyPermission[];
}

export default function UserDetail() {
  const userID = useParams().userID ?? '';
  const companyID = useCompanyId();
  const navigate = useNavigate();
  const { data, error: loadingError } = useUserDetail(userID, companyID);
  const [permissions, setPermissions] = useState<Set<UserCompanyPermission>>(
    new Set()
  );
  const {
    data: form,
    setData,
    handle,
    manualHandle,
  } = useFormData<FormData>({
    id: '',
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    status: false,
    permission: Array.from(permissions),
  });

  const [update, { error: updateError }] = useUserCompanyRoleUpdate();
  const [resetPassword, { error: resetPasswordError }] = useResetUserPassword();

  const error = loadingError ?? updateError ?? resetPasswordError;
  const applicationErrors = formatGraphQlError(error?.graphQLErrors);
  const validationError = validationErrors(applicationErrors);

  useEffect(() => {
    if (data?.user) {
      setData({
        id: data.user.id,
        username: data.user.username,
        firstName: data.user.firstName,
        lastName: data.user.lastName,
        email: data.user.email,
        status: data.user.status,
        permission: data.userCompanyRole.permission,
      });

      setPermissions(
        new Set<UserCompanyPermission>(data.userCompanyRole.permission)
      );
    }
  }, [data?.user, data?.userCompanyRole, setData, setPermissions]);

  const submit = useCallback(() => {
    update({
      variables: {
        companyID: companyID,
        input: {
          userId: userID,
          username: form.username,
          firstName: form.firstName,
          lastName: form.lastName,
          email: form.email,
          status: form.status,
          permission: Array.from(permissions),
        },
      },
    })
      .then(() => {
        navigate('..');
      })
      .catch((e: unknown) => void e);
  }, [userID, form, navigate, update, companyID, permissions]);

  const handleResetPassword = () => {
    resetPassword({
      variables: {
        companyID: companyID,
        userID: userID,
      },
    })
      .then((value) => {
        if (!value.data?.resetUserPassword) {
          alert('Failed to reset password');
          return;
        }

        alert("User's reset password is: " + value.data.resetUserPassword);
        navigate('..');
      })
      .catch(() => {
        alert('Failed to reset password');
      });
  };

  return (
     
      <ContentLayout>
        <Helmet>
          <title>Edit User</title>
          <meta
            name="description"
            content="This page allows you to edit a user"
          />
        </Helmet>
        <PrimaryContent>
          <div className="space-y-4 ">
            <Card>
              <CardHeader title="Edit User" />

              <CardContent>
                <FormLayout>
                  <FormItem title={'Username'} className="max-w-md">
                    <TextInput
                      data-testid="Username-input"
                      type="text"
                      label="title"
                      value={form.username}
                      name="username"
                      placeholder="Username"
                      error={validationError.title}
                      onChange={handle}
                    />
                  </FormItem>

                  <FormItem title={'Firstname'} className="max-w-md">
                    <TextInput
                      data-testid="fullname-input"
                      type="text"
                      label="Firstname"
                      value={form.firstName}
                      name="firstName"
                      placeholder="fullname"
                      error={validationError.title}
                      onChange={handle}
                    />
                  </FormItem>

                  <FormItem title={'Lastname'} className="max-w-md">
                    <TextInput
                      data-testid="fullname-input"
                      type="text"
                      label="Lastname"
                      value={form.lastName}
                      name="lastName"
                      placeholder="fullname"
                      error={validationError.title}
                      onChange={handle}
                    />
                  </FormItem>

                  <FormItem title={'Email'} className="max-w-md">
                    <TextInput
                      data-testid="description-input"
                      type="text"
                      label="description"
                      value={form.email}
                      name="email"
                      placeholder="description"
                      error={validationError.title}
                      onChange={handle}
                    />
                  </FormItem>

                  <FormItem title="Status" className="w-32">
                    <ToggleInput
                      value={form.status}
                      onChange={(newValue) => {
                        manualHandle('status', newValue);
                      }}
                      data-testid="product-active-toggle"
                    />
                  </FormItem>

                  <FormItem title={'User Role'} className="max-w-md">
                    <UserRoleSelected
                      permissions={permissions}
                      setPermissions={setPermissions}
                      manualHandle={manualHandle}
                    />
                  </FormItem>

                  {error && (
                    <AlertError
                      className="mt-4"
                      title={error.name}
                      message={error.message}
                    />
                  )}
                </FormLayout>
              </CardContent>

              <CardFooter>
                <SecondaryButton
                  className="w-32"
                  onClick={() => {
                    navigate('..');
                  }}
                >
                  Cancel
                </SecondaryButton>
                <PrimaryButton
                  className="w-32"
                  data-testid="update-button"
                  onClick={submit}
                >
                  Update
                </PrimaryButton>
              </CardFooter>
            </Card>

            <Card>
              <CardHeader title="Extra actions" />
              <CardContent>
                <UserDelete userId={userID} />

                <CardSection
                  title="Reset password"
                  descriptions="This action will immediately create a new random password for user. The new password will be displayed in the alert."
                >
                  <SecondaryButton
                    className="w-32"
                    data-testid="update-button"
                    onClick={handleResetPassword}
                  >
                    Reset password
                  </SecondaryButton>
                </CardSection>
              </CardContent>
            </Card>
          </div>
        </PrimaryContent>
      </ContentLayout>
     
  );
}
