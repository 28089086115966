import { graphql } from '@client/graphql/types';
import { useQuery } from '@apollo/client';
import { TableReservationsFilter } from '@client/graphql/types/graphql.ts';

const QUERY = graphql(`
  query TableReservations($branchId: ID!, $filter: TableReservationsFilter!) {
    tableReservations(branchId: $branchId, filter: $filter) {
      ...TableReservationItem
    }
  }
`);

export default function useTableReservationsQuery(
  branchId: string,
  filter: TableReservationsFilter,
  skip = false
) {
  return useQuery(QUERY, {
    variables: {
      branchId: branchId,
      filter: filter,
    },
    skip: skip,
    pollInterval: 60 * 1000,
  });
}
