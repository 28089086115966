import { Outlet } from 'react-router-dom';
import ModalProvider from '@packages/ui/modal/modal-provider.tsx';
import NotificationCenterProvider from '@packages/ui/notification/notification-center-provider.tsx';
import { PushServiceContextProvider } from '@client/module/push-notification/push-service-context-provider.tsx';

export default function ProtectedRoutes() {
  return (
    <NotificationCenterProvider>
      <PushServiceContextProvider>
        <ModalProvider>
          <Outlet />
        </ModalProvider>
      </PushServiceContextProvider>
    </NotificationCenterProvider>
  );
}
