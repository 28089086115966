import { OnlineOrderDeliveryTableConfigurationInput } from '@client/graphql/types/graphql';
import Table from 'ui/src/table/table';
import TableRow from 'ui/src/table/table-row';
import TableRowColumn from 'ui/src/table/table-row-cell';
import TableHeaderColumn from 'ui/src/table/table-header-column';
import SecondaryButton from 'ui/src/button/secondary-button';
import { TableCellAlignment } from 'ui/src/table/table-type';
import { OnlineOrderDelivery } from '@client/page/management/online-order/setting/model/online-order-delivery.model.ts';
import CurrencyView from '@packages/core/utils/currency-view.tsx';
import { useTranslate } from "@tolgee/react";

export default function DeliveryTable(props: {
  deliveryDataList: OnlineOrderDelivery[],
  showUpdateModal: (deliveryId: string) => void
}) {
  const { deliveryDataList, showUpdateModal } = props;
  const { t } = useTranslate();
  return (
    <Table
      data={deliveryDataList}
      build={(deliveryData, index) => <DeliveryDataRow key={index} deliveryData={deliveryData}
                                                       showUpdateModal={showUpdateModal} />}
    >
      <TableHeaderColumn
        alignment={TableCellAlignment.Center}>{t('online-order.setting-page.edit.delivery-setting.table.delivery-distance')}</TableHeaderColumn>
      <TableHeaderColumn
        alignment={TableCellAlignment.Center}>{t('online-order.setting-page.edit.delivery-setting.table.delivery-time')}</TableHeaderColumn>
      <TableHeaderColumn
        alignment={TableCellAlignment.Center}>{t('online-order.setting-page.edit.delivery-setting.table.delivery-fee')}</TableHeaderColumn>
      <TableHeaderColumn className="w-14"></TableHeaderColumn>
    </Table>
  );
}

function DeliveryDataRow(props: {
  deliveryData: OnlineOrderDeliveryTableConfigurationInput,
  showUpdateModal: (deliveryId: string) => void
}) {
  const { deliveryData, showUpdateModal } = props;
  const { t } = useTranslate();
  return (
    <TableRow>
      <TableRowColumn alignment={TableCellAlignment.Center}>
        <p className="font-medium">
          &lt;
          {(deliveryData.deliveryDistance / 1000).toFixed(2)} km
        </p>
      </TableRowColumn>
      <TableRowColumn alignment={TableCellAlignment.Center}>
        <p className="font-medium">{deliveryData.deliveryTime} minutes</p>
      </TableRowColumn>
      <TableRowColumn alignment={TableCellAlignment.Center}>
        <CurrencyView className="font-semibold" price={deliveryData.deliveryFee} />
      </TableRowColumn>
      <TableRowColumn alignment={TableCellAlignment.Right}>
        <SecondaryButton className="mx-2" onClick={() => {
          showUpdateModal(deliveryData.id);
        }}>{t('online-order.setting-page.edit.delivery-setting.table.edit-button')}</SecondaryButton>
      </TableRowColumn>
    </TableRow>
  );
}
