import ActionPanel from '@packages/ui/action-panel.tsx';
import PrimaryButton from 'ui/src/button/primary-button';
import Center from 'ui/src/center';
import { useNotificationCenter } from 'ui/src/notification/notification-center';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslate } from "@tolgee/react";
import useSetupOnlineOrderBranchSetting from '../logic/use-set-up-online-order-branch-setting';

export default function OnlineOrderBranchSettingSetup(props: { branchID: string }) {
  // Params
  const { branchID } = props;

  // Hooks
  const navigate = useNavigate();
  const { showAlert } = useNotificationCenter();
  const [create, { error, loading }] = useSetupOnlineOrderBranchSetting(branchID);
  const { t } = useTranslate();

  // Callbacks
  const handleClick = useCallback(() => {
    create({
      variables: {
        branchID: branchID,
      },
    })
      .then(() => {
        showAlert({
          status: 'success',
          title: t('alert.title.success'),
          message: t('online-order.setting-page.setup.alert.success.message'),
        });
        navigate(0);
      })
      .catch(() => {
        showAlert({
          status: 'error',
          title: t('alert.title.error'),
          message: error?.message ?? '',
        });
      });
  }, [branchID, create, error?.message, navigate, showAlert, t]);
  return (
    <Center>
      <ActionPanel title={t('online-order.setting-page.setup.title')}
                   description={t('online-order.setting-page.setup.description')}>
        <PrimaryButton disabled={loading} onClick={handleClick}>
          {t('common.button.create')}
        </PrimaryButton>
      </ActionPanel>
    </Center>
  );
}
