import { graphql } from '@client/graphql/types';
import { useQuery } from '@apollo/client';

const QUERY = graphql(`
  query TableReservationReportByBranch($branchId: ID!) {
    tableReservationReport(branchID: $branchId) {
      daily {
        date
        count
      }
      hourly {
        hour
        count
      }
    }
  }
`);

export default function useTableReservationReportByBranch(branchId: string) {
  return useQuery(QUERY, {
    variables: {
      branchId: branchId,
    },
  });
}
