import { graphql } from '@client/graphql/types';
import { useMutation } from '@apollo/client';

const MUTATION = graphql(`
  mutation DeleteBanner(
    $bannerId: String!
  ) {
    deleteBanner(
      bannerId: $bannerId
    ) {
      bannerId
      title
    }
  }
`);

export default function useBannerDelete() {
  return useMutation(MUTATION, {});
}
