import { graphql } from '@client/graphql/types';
import { useMutation } from '@apollo/client';

const MUTATION = graphql(`
  mutation OnlineOrderConfirm($id: ID!, $input: OnlineOrderOwnerConfirmInput!) {
    onlineOrderOwnerConfirm(id: $id, input: $input)
  }
`);

export default function useConfirmOnlineOrder() {
  return useMutation(MUTATION, {
    refetchQueries: ['OnlineOrderDetail','OnlineOrderDashboard'],
  });
}
