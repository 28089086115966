import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import './index.css';
import { ApolloProvider } from '@apollo/client';
import { AuthProvider } from '@client/module/auth/auth-provider.tsx';
import { init as SentryInit } from '@sentry/react';
import { enableMapSet } from 'immer';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone'; // dependent on utc plugin
import { HelmetProvider } from 'react-helmet-async';
import { registerSW } from 'virtual:pwa-register';
import migrationV1 from '@client/migration/v1.ts';
import { DevTools, FormatSimple, Tolgee, TolgeeProvider } from '@tolgee/react';
import { router } from './app/router.tsx';
import { client } from './graphql/client.ts';

// add this to prompt for a refresh
// const updateSW = registerSW({
//   onNeedRefresh() {
//     if (confirm('New content available. Reload?')) {
//       updateSW(true);
//     }
//   },
// });

registerSW({ immediate: true });

// Setup dayjs
dayjs.extend(utc);
dayjs.extend(timezone);

// Setup Firebase
const firebaseConfig = {
  apiKey: 'AIzaSyC-h59jYQOURCanu5QCJpiy-3FUP16pSDc',
  authDomain: 'inhouse-55263.firebaseapp.com',
  projectId: 'inhouse-55263',
  storageBucket: 'inhouse-55263.appspot.com',
  messagingSenderId: '838641558620',
  appId: '1:838641558620:web:05f61cee220a699096b299',
  measurementId: 'G-JQWSF58XWT',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);

// Setup Sentry

const sentryDns: string | null = import.meta.env.VITE_SENTRY_DNS as string;
if (sentryDns) {
  import('@sentry/react')
    .then((sentryReact) => {
      SentryInit({
        dsn: sentryDns,
        integrations: [sentryReact.browserTracingIntegration(), sentryReact.replayIntegration()],
        // Performance Monitoring
        tracesSampleRate: import.meta.env.PROD ? 1.0 : 0.2,
        // Session Replay
        replaysSessionSampleRate: import.meta.env.PROD ? 0.1 : 1,
        replaysOnErrorSampleRate: 1.0,
      });
    })
    .catch((error) => {
      /* eslint-disable no-console */
      console.log(error);
    });
}

// Chart setup
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// Immer plugin
enableMapSet();

// Migration
migrationV1();

//tolgee
const tolgee = Tolgee()
  .use(DevTools())
  .use(FormatSimple())
  .init({
    language: localStorage.getItem('language') ?? 'en',

    // for development
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    apiUrl: import.meta.env.VITE_APP_TOLGEE_API_URL,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    apiKey: import.meta.env.VITE_APP_TOLGEE_API_KEY,

    // for production
    staticData: {
      en: () => import('./i18n/en.json').then((m) => m.default),
      de: () => import('./i18n/de.json').then((m) => m.default),
      vi: () => import('./i18n/vi.json').then((m) => m.default),
    },
  });

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
ReactDOM.createRoot(document.getElementById('root')!).render(
  <HelmetProvider>
    <React.StrictMode>
      <TolgeeProvider tolgee={tolgee}>
        <AuthProvider>
          <ApolloProvider client={client}>
            <RouterProvider router={router} />
          </ApolloProvider>
        </AuthProvider>
      </TolgeeProvider>
    </React.StrictMode>
  </HelmetProvider>
);
