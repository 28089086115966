import PrimaryButton from 'ui/src/button/primary-button';
import Card from 'ui/src/card/card';
import CardContent from 'ui/src/card/card-content';
import CardHeader from 'ui/src/card/card-header';
import FormItem from 'ui/src/form/form-item';
import FormLayout from 'ui/src/form/form-layout';
import TextInput from 'ui/src/form/input/text-input';
import CardFooter from 'ui/src/card/card-footer';
import SecondaryButton from 'ui/src/button/secondary-button';
import useFormData from '@client/module/utils/use-form-data';
import { useNotificationCenter } from 'ui/src/notification/notification-center';
import { OnlineOrderDelivery } from '@client/page/management/online-order/setting/model/online-order-delivery.model.ts';
import DestroyButton from 'ui/src/button/destroy-button';
import { useDialog } from 'ui/src/modal/use-dialog';
import { currencySymbol, formatRawCurrency, normalizeCurrency } from 'core/src/utils/currency-formatter';
import { useCompanyCurrency } from 'core/src/company/focused-company-context';
import { useTranslate } from "@tolgee/react";
import useUpdateOnlineOrderConfiguration from '../logic/use-update-online-order-branch-setting';

interface Form {
  deliveryDistance: string;
  deliveryFee: string;
  deliveryTime: string;
}

export default function OnlineOrderDeliveryTableDataUpdate(props: {
  onClose: () => void;
  branchID: string;
  deliveryId: string;
  deliveryData: OnlineOrderDelivery[];
}) {
  // Params
  const { onClose, branchID, deliveryData, deliveryId } = props;
  const dialog = useDialog();
  const currency = useCompanyCurrency();
  // Hooks
  const { showAlert } = useNotificationCenter();
  const [update, { loading: updateLoading }] = useUpdateOnlineOrderConfiguration();
  const { t } = useTranslate();

  // Data
  const currentDeliveryData = deliveryData.find((item) => item.id == deliveryId);
  const { data: form, manualHandle } = useFormData<Form>({
    deliveryDistance: ((currentDeliveryData?.deliveryDistance ?? 0) / 1000).toString(),
    deliveryFee: formatRawCurrency(currentDeliveryData?.deliveryFee ?? 0, currency).value,
    deliveryTime: (currentDeliveryData?.deliveryTime ?? 0).toString(),
  });


  // Handlers
  const handleSubmit = () => {
    const updatedDeliveryTableData = [
      ...deliveryData.map((item) => {
        if (item.id == deliveryId) {
          return {
            id: item.id,
            deliveryDistance: parseInt(form.deliveryDistance) * 1000,
            deliveryFee: normalizeCurrency(form.deliveryFee, currency),
            deliveryTime: parseInt(form.deliveryTime),
          };
        }
        return {
          id: item.id,
          deliveryDistance: item.deliveryDistance,
          deliveryFee: item.deliveryFee,
          deliveryTime: item.deliveryTime,
        };
      }),
    ];
    update({
      variables: {
        branchID: branchID,
        input: {
          deliveryTableData: updatedDeliveryTableData,
        },
      },
    })
      .then(() => {
        showAlert({
          status: 'success',
          title: t('alert.title.success'),
          message: t('online-order.setting-page.edit.delivery-setting.edit-dialog.alert.success.message'),
        });

        onClose();
      })
      .catch((err) => {
        /* eslint-disable no-console */
        console.log(err);
        showAlert({
          status: 'error',
          title: t('alert.title.error'),
          message: t('online-order.setting-page.edit.delivery-setting.edit-dialog.alert.error.message'),
        });
      });
  };

  const handleDelete = () => {
    const updatedDeliveryTableData = [
      ...deliveryData.map((item) => ({
        id: item.id,
        deliveryDistance: item.deliveryDistance,
        deliveryFee: item.deliveryFee,
        deliveryTime: item.deliveryTime,
      })),
    ].filter((item) => item.id !== deliveryId);

    return update({
      variables: {
        branchID: branchID,
        input: {
          deliveryTableData: updatedDeliveryTableData,
        },
      },
    })
      .then(() => {
        showAlert({
          status: 'success',
          title: t('alert.title.success'),
          message:t('online-order.setting-page.edit.delivery-setting.edit-dialog.alert.success.message'),
        });

        onClose();
      })
      .catch((err) => {
        /* eslint-disable no-console */
        console.log(err);
        showAlert({
          status: 'error',
          title: t('alert.title.error'),
          message: t('online-order.setting-page.edit.delivery-setting.edit-dialog.alert.error.message'),
        });
      });
  };

  const showAlerModal = () => {
    dialog.destructAlert({
      title: t('online-order.setting-page.edit.delivery-setting.edit-dialog.delete-alert.title'),
      message: t('online-order.setting-page.edit.delivery-setting.edit-dialog.delete-alert.message'),
      textButton: t('online-order.setting-page.edit.delivery-setting.edit-dialog.delete-alert.text-button'),
      onConfirm: () => {
        return handleDelete();
      },
    });
  };

  return (
    <>
      <Card>
        <CardHeader title={t('online-order.setting-page.edit.delivery-setting.edit-dialog.title')}></CardHeader>

        <CardContent>
          <FormLayout>
            <FormItem title={t('online-order.setting-page.edit.delivery-setting.edit-dialog.form-input.delivery-distance.title')} className="max-w-36">
              <TextInput
                data-testid="deliveryDistance"
                type={'number'}
                label="deliveryDistance"
                value={form.deliveryDistance}
                name="deliveryDistance"
                placeholder=""
                suffix={<span className="ml-2 text-gray-500">km</span>}
                onChange={(newVal) => {
                  manualHandle('deliveryDistance', newVal.target.value);
                }}
              />
            </FormItem>
            <FormItem title={t('online-order.setting-page.edit.delivery-setting.edit-dialog.form-input.delivery-time.title')}className="max-w-36">
              <TextInput
                data-testid="deliveryTime"
                type={'number'}
                label="deliveryTime"
                value={form.deliveryTime}
                name="deliveryTime"
                placeholder=""
                suffix={<span className="ml-2 text-gray-500">minutes</span>}
                onChange={(newVal) => {
                  manualHandle('deliveryTime', newVal.target.value);
                }}
              />
            </FormItem>

            <FormItem title={t('online-order.setting-page.edit.delivery-setting.edit-dialog.form-input.delivery-fee.title')} className="max-w-36">
              <TextInput
                data-testid="deliveryFee"
                type={'number'}
                label="deliveryFee"
                value={form.deliveryFee}
                name="deliveryFee"
                placeholder=""
                suffix={<span className="ml-2 text-gray-500">{currencySymbol(currency)}</span>}
                onChange={(newVal) => {
                  manualHandle('deliveryFee', newVal.target.value);
                }}
              />
            </FormItem>

            {/* <ApplicationErrorView error={updateError} /> */}
          </FormLayout>
        </CardContent>

        <CardFooter>
          <div className="w-full flex">
            <div className="grow">
              <DestroyButton onClick={showAlerModal}>{t('common.button.delete')}</DestroyButton>
            </div>

            <div className="flex gap-2">
              <PrimaryButton
                disabled={updateLoading}
                loading={updateLoading}
                onClick={() => {
                  handleSubmit();
                }}
              >
                {t('common.button.update')}
              </PrimaryButton>
              <SecondaryButton onClick={onClose}>{t('common.button.cancel')}</SecondaryButton>
            </div>
          </div>
        </CardFooter>
      </Card>
    </>
  );
}
