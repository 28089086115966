import useTableReservationReportByBranch from '@client/page/management/table-reservation/analytic/logic/use-table-reservation-report-by-branch.ts';
import TableReservationAnalyticDynamicChange from '@client/page/management/table-reservation/analytic/component/table-reservation-analytic-dynamic-change.tsx';
import ApplicationErrorView from '@client/module/error/application-error-view.tsx';
import { ContentLayout, FullPrimaryContent } from '@packages/ui/content-layout.tsx';
import TableReservationAnalyticDailyDiagram from '@client/page/management/table-reservation/analytic/component/table-reservation-analytic-daily-diagram.tsx';
import TableReservationAnalyticHourlyDiagram from '@client/page/management/table-reservation/analytic/component/table-reservation-analytic-hourly-diagram.tsx';
import { useFocusedBranchContext } from '@client/page/management/table-group/table-group-list/component/branch-selection/focused-branch-context.ts';
import { Helmet } from 'react-helmet-async';

const TableReservationAnalytic = () => {
  const branchProvider = useFocusedBranchContext();
  const branchID = branchProvider.branch?.id ?? '';

  const { data, error } = useTableReservationReportByBranch(branchID);

  const dailyCount = data?.tableReservationReport.daily ?? [];
  const hourlyCount = data?.tableReservationReport.hourly ?? [];

  return (
    <ContentLayout>
      <Helmet>
        <title>Reservations Report</title>
      </Helmet>
      <FullPrimaryContent>
        <ApplicationErrorView error={error} />
        <TableReservationAnalyticDynamicChange dailyCount={dailyCount} />
        <TableReservationAnalyticDailyDiagram dailyCount={dailyCount} />
        <TableReservationAnalyticHourlyDiagram hourlyCount={hourlyCount} />
      </FullPrimaryContent>
    </ContentLayout>
  );
};

export default TableReservationAnalytic;
