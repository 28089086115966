import { useState } from 'react';
import PrimaryButton from 'ui/src/button/primary-button';
import { AdjustmentsHorizontalIcon } from '@heroicons/react/20/solid';
import { useTranslate } from '@tolgee/react';

export default function ShowMailListIcon(props: { branchId: string; data: string[] }) {
  const { data } = props;
  const [showModal, setShowModal] = useState<boolean>(false);
  const { t } = useTranslate();

  return (
    <>
      <AdjustmentsHorizontalIcon
        className="w-4 h-4 fill-gray-500"
        onClick={() => {
          setShowModal(true);
        }}
      />
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              <div className="bg-white py-8 px-6 rounded-lg flex flex-col gap-2 w-[500px] h-[250px]">
                <div className="text-2xl font-medium">{t('common.mail-list')}</div>

                <ul className="grow	 overflow-y-scroll border rounded-md">
                  {data.map((item) => (
                    <li key={item} className="py-2 px-2 border-b text-gray-600 hover:bg-gray-100">
                      {item}
                    </li>
                  ))}
                </ul>
                <div className="flex justify-end">
                  <div className="flex gap-1">
                    <PrimaryButton
                      onClick={() => {
                        setShowModal(false);
                      }}
                    >
                      {t('common.button.done')}
                    </PrimaryButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="fixed -inset-4 bg-gray-500 opacity-75 transition-opacity z-40"></div>
        </>
      ) : null}
    </>
  );
}
