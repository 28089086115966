import { useMutation } from '@apollo/client';
import { graphql } from '@client/graphql/types';

const MUTATION = graphql(`
  mutation UserCompanyRoleDelete($companyID: ID!, $userID: ID!) {
    userCompanyRoleDelete(companyID: $companyID, userID: $userID) {
      id
    }
  }
`);

export default function useDeleteUser() {
  return useMutation(MUTATION, {

  });
}
