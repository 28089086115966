import { useCallback } from 'react';
import { useNotificationCenter } from '@packages/ui/notification/notification-center.ts';
import useDeclineOnlineOrder from '@client/page/management/online-order/detail/logic/use-decline-online-order.ts';
import DestroyButton from '@packages/ui/button/destroy-button.tsx';
import { useDialog } from 'ui/src/modal/use-dialog';
import { useTranslate } from "@tolgee/react";

const OnlineOrderActionDecline = (props: { orderID: string; onClose?: () => void }) => {
  const { orderID, onClose } = props;

  const { showAlert } = useNotificationCenter();

  const [action, { loading }] = useDeclineOnlineOrder();

  const dialog = useDialog();
  const { t } = useTranslate();
  const handleDialog = () => {
    dialog.destructAlert({
      title: t('online-order.detail.action.decline.dialog.title'),
      message: t('online-order.detail.action.decline.dialog.message'),
      textButton: t('online-order.detail.action.decline.dialog.button'),
      onConfirm: confirmHandler,
    });
  };

  const confirmHandler = useCallback(() => {
    return action({
      variables: {
        id: orderID,
      },
    })
      .then(() => {
        showAlert({
          status: 'success',
          title: t('alert.title.success'),
          message: t('online-order.detail.action.decline.alert.message'),
        });

        onClose?.();
      })
      .catch((e: Error) => {
        showAlert({
          status: 'error',
          title: t('alert.title.error'),
          message: e.message,
        });
      });
  }, [action, orderID, showAlert, onClose, t]);

  return (
    <DestroyButton onClick={handleDialog} disabled={loading}>
      {t('online-order.detail.action.decline.button')}
    </DestroyButton>
  );
};

export default OnlineOrderActionDecline;
