import {
  BanknotesIcon,
  CalendarIcon,
  // CheckBadgeIcon,
  // ClockIcon,
  Cog6ToothIcon,
  // UsersIcon,
} from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import CardTableContent from '@packages/ui/card/card-content-table.tsx';
import { useTranslate } from "@tolgee/react";
import { Helmet } from 'react-helmet-async';

export default function TableReservationBranchOverview() {
  const { t } = useTranslate();

  const actions = [
    {
      title: 'Reservation calendar',
      subtext: 'Take a look of reservations in list style',
      href: 'list',
      icon: CalendarIcon,
      iconForeground: 'text-cyan-700',
      iconBackground: 'bg-cyan-50',
    },
    // {
    //   title: 'Operative managing',
    //   subtext: 'Manage the table reservation in real-time',
    //   href: 'operative',
    //   icon: CheckBadgeIcon,
    //   iconForeground: 'text-teal-700',
    //   iconBackground: 'bg-teal-50',
    // },
    // {
    //   title: 'Incoming reservation managing',
    //   subtext: 'Manage the incoming reservation',
    //   href: 'incoming-reservations',
    //   icon: ClockIcon,
    //   iconForeground: 'text-purple-700',
    //   iconBackground: 'bg-purple-50',
    // },
    // {
    //   title: 'Reservation planing',
    //   subtext: 'Plan the reservation in the future',
    //   href: '#',
    //   icon: UsersIcon,
    //   iconForeground: 'text-sky-700',
    //   iconBackground: 'bg-sky-50',
    // },
    {
      title: 'Analytics',
      subtext: 'Analyze the reservation data',
      href: 'analytics',
      icon: BanknotesIcon,
      iconForeground: 'text-yellow-700',
      iconBackground: 'bg-yellow-50',
    },
    {
      title: t('common.preference'),
      subtext: 'Set the preference for the reservation',
      href: 'preferences',
      icon: Cog6ToothIcon,
      iconForeground: 'text-rose-700',
      iconBackground: 'bg-rose-50',
    },
  ];

  return (
     
      <Card>
        <Helmet>
          <title>Table reservation</title>
          <meta
            name="description"
            content="This page displays a list of table reservation settings"
          />
        </Helmet>
        <CardHeader title="Table reservation overview" withBackButton={true} />
        <CardTableContent>
          <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-gray-200 shadow sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0">
            {actions.map((action, actionIdx) => (
              <div
                key={action.title}
                className={classNames(
                  actionIdx === 0
                    ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none'
                    : '',
                  actionIdx === 1 ? 'sm:rounded-tr-lg' : '',
                  'group relative bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500'
                )}
              >
                <div>
                  <span
                    className={classNames(
                      action.iconBackground,
                      action.iconForeground,
                      'inline-flex rounded-lg p-3 ring-4 ring-white'
                    )}
                  >
                    <action.icon className="h-6 w-6" aria-hidden="true" />
                  </span>
                </div>
                <div className="mt-8">
                  <h3 className="text-base font-semibold leading-6 text-gray-900">
                    <Link to={action.href} className="focus:outline-none">
                      {/* Extend touch target to entire panel */}
                      <span className="absolute inset-0" aria-hidden="true" />
                      {action.title}
                    </Link>
                  </h3>
                  <p className="mt-2 text-sm text-gray-500">{action.subtext}</p>
                </div>
                <span
                  className="pointer-events-none absolute right-6 top-6 text-gray-300 group-hover:text-gray-400"
                  aria-hidden="true"
                >
                  <svg
                    className="h-6 w-6"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19
                     4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                  </svg>
                </span>
              </div>
            ))}
          </div>
        </CardTableContent>
      </Card>
     
  );
}
