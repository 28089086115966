import { createContext, useContext } from 'react';
import { CompanyService } from '@packages/core/company/company-hook.ts';

export const CompanyContext = createContext<CompanyService | undefined>(
  undefined
);

export function useCompanyService() {
  const context = useContext(CompanyContext);

  if (context === undefined) {
    throw new Error(
      'useCompanyService must be used within a CompanyContextProvider'
    );
  }

  return context;
}
