import { useQuery } from '@apollo/client';
import { graphql } from '@client/graphql/types';
import { DaysOfWeek, OpeningTimeServiceType } from '@client/graphql/types/graphql.ts';

const GET_OPENING_TIMES = graphql(`
  query OpeningTimes($branchId: ID!, $filter: OpeningTimeFilter) {
    openingTimes(branchId: $branchId, filter: $filter) {
      ...OpeningTimeItem
    }
  }
`);

export function useOpeningTimesQuery(
  branchId: string | undefined,
  service?: OpeningTimeServiceType,
  isActive?: boolean,
  dayOfWeek?: DaysOfWeek
) {
  return useQuery(GET_OPENING_TIMES, {
    variables: {
      branchId: branchId ?? '',
      filter: {
        isActive: isActive,
        dayOfWeek: dayOfWeek,
        service: service,
      },
    },
    skip: !branchId || !service,
  });
}
