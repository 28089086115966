import { graphql } from '@client/graphql/types';
import { useQuery } from '@apollo/client';

const QUERY = graphql(`
  query userNotifications($companyID: ID) {
    userNotifications(companyID: $companyID) {
      id
      title
      link
      message
      createdAt
    }
  }
`);

export default function useGetUserNotification(companyID: string) {
  return useQuery(QUERY, {
    variables: {
      companyID: companyID,
    },
    pollInterval: 30 * 1000,
  });
}
