import { graphql } from '@client/graphql/types';
import { useMutation } from '@apollo/client';

const MUTATION = graphql(`
  mutation UpdateConfirmTableReservation(
    $id: ID!
    $status: TableReservationConfirmationStatus!
  ) {
    updateTableReservationConfirmationStatus(id: $id, status: $status) {
      id
      confirmationStatus
      status
    }
  }
`);

export default function useUpdateConfirmTableReservation() {
  return useMutation(MUTATION, {});
}
