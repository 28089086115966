import { AllergicType } from '@client/graphql/types/graphql';
import CheckBoxInput from 'ui/src/form/input/check-box-input';
import { capitalize } from 'lodash';
import { useTranslate } from "@tolgee/react";

export default function AllergicSelection(props: {
  allergics: AllergicType[];
  onChange: (allergics: AllergicType[]) => void;
}) {
  const { t } = useTranslate();
  return (
    <div className={'grid grid-cols-2'}>
      {Object.values(AllergicType).map((item) => (
        <div key={item}>
          <CheckBoxInput
            value={props.allergics.find((allergic) => allergic === item) !== undefined}
            name={t('menu.menu-detail.product.create.form-input.allergic.selection.' + capitalize(item.replace(/_/g, ' ')))}
            onChange={(_, value) => {
              if (value) {
                props.onChange([...props.allergics, item]);
              } else {
                props.onChange(props.allergics.filter((allergic) => allergic !== item));
              }
            }}
          />
        </div>
      ))}
    </div>
  );
}
