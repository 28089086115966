import { graphql } from '@client/graphql/types';
import { useMutation } from '@apollo/client';

const MUTATION = graphql(`
  mutation UpdateOnlineOrderConfiguration($branchID: ID!, $input: UpdateOnlineOrderConfigurationInput!) {
    updateOnlineOrderConfiguration(branchID: $branchID, input: $input) {
      branchID
      menuID
    }
  }
`);

export default function useUpdateOnlineOrderConfiguration() {
    return useMutation(MUTATION, {
      refetchQueries: ['OnlineOrderBranchConfiguration']
    });
}
