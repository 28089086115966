import UserDetail from '@client/page/user-company-role/user-detail/user-detail.tsx';
import UserCreate from '@client/page/user-company-role/user-create/user-create.tsx';
import UserNav from './user-list/user-nav';

export const UserCompanyRouter = {
  path: 'user-company-roles',
  handle: {
    crumb: () => 'User Company Role',
  },
  children: [
    {
      path: '',
      element: <UserNav />
    },
    {
      path: ':userID',
      handle: {
        crumb: () => 'Edit User',
      },
      element: <UserDetail />
    },
    {
      path: 'create',
      handle: {
        crumb: () => 'Create User',
      },
      element: <UserCreate />
    },
  ],
};
