import dayjs, { Dayjs } from 'dayjs';
import { useFocusedBranchContext } from '@client/page/management/table-group/table-group-list/component/branch-selection/focused-branch-context.ts';
import useOnlineOrderDashboardPage from '@client/page/management/online-order/dashboard/logic/use-online-order-dashboard-page.ts';
import { getFragmentData } from '@client/graphql/types';
import Loading from '@packages/ui/loading.tsx';
import ApplicationErrorView from '@client/module/error/application-error-view.tsx';
import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import CardTableContent from '@packages/ui/card/card-content-table.tsx';
import OnlineOrderTable from '@client/page/management/online-order/dashboard/component/online-order-table.tsx';
import { ReactNode } from 'react';
import CardFooter from '@packages/ui/card/card-footer.tsx';
import { OnlineOrderListItemFragmentDoc, OnlineOrderState } from '@client/graphql/types/graphql.ts';
import OnlineOrderDailySummarize from '@client/page/management/online-order/dashboard/component/online-order-daily-summarize.tsx';
import { useTranslate } from '@tolgee/react';
import OnlineOrderHeaderDeliveryToggle from './component/online-order-header-delivery-toggle';
import OnlineOrderHeaderPickupToggle from './component/online-order-header-pickup-toggle.tsx';

export const OnlineOrderDashboard = (props: {
  startTime: Dayjs;
  endTime: Dayjs;
  header?: ReactNode;
  footer?: ReactNode;
  ignore?: OnlineOrderState[];
  navigationPrefix?: string;
}) => {
  // Params
  const branchProvider = useFocusedBranchContext();
  const branchID = branchProvider.branch?.id ?? '';

  const { data, loading, error } = useOnlineOrderDashboardPage(branchID, {
    startTime: props.startTime.toISOString(),
    endTime: props.endTime.toISOString(),
  });
  const { t } = useTranslate();
  const orders = getFragmentData(OnlineOrderListItemFragmentDoc, data?.onlineOrdersDashboard) ?? [];
  const filterOrders = orders.filter((order) => !props.ignore?.includes(order.state));

  if (loading) return <Loading />;

  return (
    <>
      <ApplicationErrorView className="my-4" error={error} />
      <Card>
        <CardHeader title={t('online-order.dashboard.title')}>
          <div className="flex flex-wrap sm:flex-nowrap gap-2">
            {props.startTime.isSame(dayjs(), 'day') && props.endTime.isSame(dayjs(), 'day') && (
              <>
                <OnlineOrderHeaderDeliveryToggle />
                <OnlineOrderHeaderPickupToggle />
              </>
            )}
            {props.header}
          </div>
        </CardHeader>
        <CardTableContent>
          <OnlineOrderDailySummarize order={orders} />
          <OnlineOrderTable
            loading={loading}
            orders={filterOrders}
            branchID={branchID}
            navigationPrefix={props.navigationPrefix}
          />
        </CardTableContent>

        {props.footer && <CardFooter>{props.footer}</CardFooter>}
      </Card>
    </>
  );
};
