import { PickupDiscountType } from '@client/graphql/types/graphql.ts';

export function discountTypeName(type: PickupDiscountType): string {
  switch (type) {
    case PickupDiscountType.None:
      return 'Disable';
    case PickupDiscountType.Percentage:
      return 'Percentage';
    case PickupDiscountType.Amount:
      return 'Amount';
  }
}