import { PushNotificationServiceContext } from '@client/module/push-notification/push-notification-context.ts';
import { ReactNode } from 'react';
import { usePushNotificationServiceProvider } from '@client/module/push-notification/push-notification.tsx';

export const PushServiceContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const pushNotificationService = usePushNotificationServiceProvider();

  return (
    <PushNotificationServiceContext.Provider value={pushNotificationService}>
      {children}
    </PushNotificationServiceContext.Provider>
  );
};
