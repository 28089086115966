import { ReactNode } from 'react';

export default function DescriptionSection(props: {
  title: string;
  description?: string;
  className?: string;
  children?: ReactNode;
}) {
  return (
    <div className="pb-2">
      <h2 className="text-lg font-semibold leading-7 text-gray-900">{props.title}</h2>

      {!props.description ? null : <p className="text-sm leading-6 text-gray-600">{props.description}</p>}

      <div className="mt-0 flex flex-col">{props.children}</div>
    </div>
  );
}
