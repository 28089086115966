export function extractMessageError(error: unknown): string {
  if (error instanceof Error) {
    return error.message;
  }

  if (typeof error === 'string') {
    return error;
  }

  if (error instanceof Response) {
    return error.statusText;
  }

  if (error instanceof ErrorEvent) {
    return error.message;
  }

  return 'An error occurred';
}
