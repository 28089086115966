import { graphql } from '@client/graphql/types';
import { useMutation } from '@apollo/client';

const CREATE_OPENING_TIME_MUTATION = graphql(`
  mutation CreateOpeningTime($input: CreateOpeningTimeData!) {
    createOpeningTime(input: $input) {
      id
    }
  }
`);

export function useCreateOpeningTime() {
  return useMutation(CREATE_OPENING_TIME_MUTATION,{
    refetchQueries: ['OpeningTimes']
  });
}
