import { graphql } from '@client/graphql/types';
import { useQuery } from '@apollo/client';

const QUERY = graphql(`
  query OnlineOrderBranchConfiguration($branchID: ID!) {
    onlineOrderBranchConfiguration(branchID: $branchID) {
      ...OnlineOrderBranchSettingItem
    }
  }
`);

export default function useGetOnlineOrderBranchConfiguration(branchID: string) {
  return useQuery(QUERY, {
    variables: {
      branchID: branchID,
    },
  });
}
