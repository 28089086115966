import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import {
  useProductConfigurations,
} from '@client/page/management/menu/menu-product-configuration-list/logic/use-product-configuration.ts';
import { useNavigate, useParams } from 'react-router-dom';
import Table from '@packages/ui/table/table.tsx';
import TableHeaderColumn from '@packages/ui/table/table-header-column.tsx';
import { MenuProductConfigurationItemFragment } from '@client/graphql/types/graphql.ts';
import { TableSection } from '@packages/ui/table/table-section.tsx';
import TableRowColumn from '@packages/ui/table/table-row-cell.tsx';
import { useCompanyCurrency } from '@packages/core/company/focused-company-context.ts';
import SecondaryButton from '@packages/ui/button/secondary-button.tsx';
import PrimaryButton from '@packages/ui/button/primary-button.tsx';
import {
  formatProductConfigurationType,
} from '@client/page/management/menu/menu-product-configuration-edit/logic/format-product-configuration-type.ts';
import TableRow from '@packages/ui/table/table-row.tsx';
import { TableCellAlignment } from '@packages/ui/table/table-type.ts';
import { formatCurrency } from '@packages/core/utils/currency-formatter.ts';
import { getFragmentData } from '@client/graphql/types';
import {
  MenuProductConfigurationValueFragment,
} from '@packages/network-graphql/common/menu-product-configuration-value-fragment.ts';
import CardTableContent from '@packages/ui/card/card-content-table.tsx';
import { useTranslate } from "@tolgee/react";

export default function MenuProductConfigurationListContent() {
  const productId = useParams().productId ?? '';
  const navigate = useNavigate();
  const { t } = useTranslate();
  const { data, loading, error } = useProductConfigurations(productId);
  return (
    <>
      <Card>
        <CardHeader title={t('menu.menu-detail.product.edit.configurations.list.header')} withBackButton={true}>
          <SecondaryButton
            onClick={() => {
              navigate(`configurations/create`);
            }}
          >{t('menu.menu-detail.product.edit.configurations.list.add-config-button')}</SecondaryButton>
        </CardHeader>

        <CardTableContent>
          <Table
            data={data?.menuProductConfigurations ?? []}
            build={(item) => <ConfigurationBuilder key={item.id} configuration={item} />}
            loading={loading}
            error={error?.message}
          >
            <TableHeaderColumn>{t('common.table-header-column.name')}</TableHeaderColumn>
            <TableHeaderColumn
              alignment={TableCellAlignment.Right}>{t('common.table-header-column.price')}</TableHeaderColumn>
            <TableHeaderColumn
              alignment={TableCellAlignment.Right}>{t('common.table-header-column.actions')}</TableHeaderColumn>
          </Table>
        </CardTableContent>
      </Card>
    </>
  );
}

function ConfigurationBuilder(props: { configuration: MenuProductConfigurationItemFragment }) {
  const navigate = useNavigate();
  const currency = useCompanyCurrency();
  const { configuration } = props;
  const { t } = useTranslate();
  return (
    <>
      <TableSection colSpan={4}>
        <div className="flex items-center space-x-1">
          <p>{configuration.title.length > 0 ? configuration.title : 'Default configuration'}</p>
          <p>- {formatProductConfigurationType(configuration.type)}</p>
          <div className="flex-grow" />
          <div className="flex space-x-2">
            <SecondaryButton
              onClick={() => {
                navigate(`configurations/${configuration.id}/value/create`);
              }}
            >
              {t('menu.menu-detail.product.edit.configurations.list.add-price-button')}
            </SecondaryButton>
            <SecondaryButton
              onClick={() => {
                navigate(`configurations/${configuration.id}/edit`);
              }}
            >
              {t('menu.menu-detail.product.edit.configurations.list.edit-button')}
            </SecondaryButton>
          </div>
        </div>
      </TableSection>

      {configuration.values
        .map((e) => getFragmentData(MenuProductConfigurationValueFragment, e))
        .map((value) => {
          return (
            <TableRow key={value.id}>
              <TableRowColumn>{value.name.length > 0 ? value.name : t('menu.menu-detail.product.edit.configurations.list.Default price')}</TableRowColumn>
              <TableRowColumn alignment={TableCellAlignment.Right}>
                {formatCurrency(value.price, currency)}
              </TableRowColumn>
              <TableRowColumn alignment={TableCellAlignment.Right}>
                <div className="space-x-4">
                  <PrimaryButton
                    onClick={() => {
                      navigate(`configurations/${configuration.id}/value/${value.id}`);
                    }}
                  >
                    {t('menu.menu-detail.product.edit.configurations.list.edit-button')}
                  </PrimaryButton>
                </div>
              </TableRowColumn>
            </TableRow>
          );
        })}
    </>
  );
}
