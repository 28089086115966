import { useParams } from 'react-router-dom';
import { getFragmentData } from '@client/graphql/types';
import { OnlineOrderFragment, OnlineOrderProductsFragment } from '@client/graphql/online-order-fragment';
import { ContentLayout, PrimaryContent } from 'ui/src/content-layout';
import Card from 'ui/src/card/card';
import CardHeader from 'ui/src/card/card-header';
import CardContent from 'ui/src/card/card-content';
import FormLayout from 'ui/src/form/form-layout';
import DescriptionItem from 'ui/src/description/description-item';
import Loading from 'ui/src/loading';
import { OnlineOrderDeliveryMode, OnlineOrderItemFragment } from '@client/graphql/types/graphql';
import { capitalize } from 'lodash';
import CardTableContent from '@packages/ui/card/card-content-table.tsx';
import OnlineProductTable from '@client/page/management/online-order/detail/component/online-product-table.tsx';
import DescriptionSection from '@packages/ui/description/description-section.tsx';
import ApplicationErrorView from '@client/module/error/application-error-view.tsx';
import {
  useFocusedBranchContext,
} from '@client/page/management/table-group/table-group-list/component/branch-selection/focused-branch-context.ts';
import { OnlineOrderBranchSettingFragment } from '@client/graphql/online-order-branch-setting-fragment.ts';
import OnlineOrderAction from '@client/page/management/online-order/detail/component/online-order-action.tsx';
import { MapPreview } from '@client/page/management/online-order/detail/map-preview.tsx';
import { useTranslate } from "@tolgee/react";
import useGetOnlineOrder from './logic/use-get-online-order';

export default function OnlineOrderDetail() {
  // Params
  const onlineOrderID = useParams().onlineOrderID ?? '';

  const branchProvider = useFocusedBranchContext();
  const branchID = branchProvider.branch?.id ?? '';

  const { data, loading, error } = useGetOnlineOrder(onlineOrderID, branchID);

  const onlineOrderData = getFragmentData(OnlineOrderFragment, data?.onlineOrder);
  const onlineOrderProducts = getFragmentData(OnlineOrderProductsFragment, onlineOrderData?.onlineOrderProducts);
  const configuration = getFragmentData(OnlineOrderBranchSettingFragment, data?.onlineOrderBranchConfiguration);
  const { t } = useTranslate();
  if (loading) {
    return <Loading />;
  }

  if (!onlineOrderData || !onlineOrderProducts || !configuration) {
    return <>No data</>;
  }

  return (
    <>
      <ContentLayout>
        <PrimaryContent>
          <ApplicationErrorView error={error} />

          <Card>
            <CardHeader title={t('online-order.detail.title')} withBackButton={true} backTarget={-1}></CardHeader>
            <CardContent>
              <FormLayout>
                <DescriptionItem title={t('common.form-input.ID')}>{onlineOrderData.shortID}</DescriptionItem>

                <DescriptionItem
                  title={t('common.form-input.status')}>{capitalize(onlineOrderData.state.replace(/_/g, ' '))}</DescriptionItem>

                <div className="grid md:grid-cols-2">
                  <DescriptionItem
                    title={t('common.form-input.guest-name')}>{onlineOrderData.guestName}</DescriptionItem>

                  <DescriptionItem title={t('common.form-input.email')}>{onlineOrderData.email}</DescriptionItem>

                  <DescriptionItem title={t('common.form-input.phone')}>{onlineOrderData.phone}</DescriptionItem>

                  {(onlineOrderData.message?.length ?? 0) > 0 && (
                    <DescriptionItem title={t('common.form-input.message')}>{onlineOrderData.message}</DescriptionItem>
                  )}
                </div>

                <DescriptionSection title={t('online-order.detail.delivery.title')}>
                  <DescriptionItem
                    title={t('online-order.detail.delivery.information')}>
                    {t('online-order.detail.delivery.type.' + capitalize(onlineOrderData.deliveryMode) + '.title')}
                  </DescriptionItem>

                  {onlineOrderData.deliveryMode == OnlineOrderDeliveryMode.Delivery && (
                    <>
                      <DescriptionItem
                        title={t('online-order.detail.delivery.type.Delivery.location')}>{formatAddress(onlineOrderData)}</DescriptionItem>
                      <DescriptionItem title={t('online-order.detail.delivery.type.Delivery.distance')}>
                        {((onlineOrderData.distanceToRestaurant ?? 0) / 1000).toFixed(2)} kmm
                      </DescriptionItem>
                    </>
                  )}

                  {onlineOrderData.latitude && onlineOrderData.longitude && (
                    <MapPreview latitude={onlineOrderData.latitude} longitude={onlineOrderData.longitude} />
                  )}
                </DescriptionSection>
              </FormLayout>
            </CardContent>
          </Card>

          <Card>
            <CardHeader title={t('online-order.detail.order.title')} />
            <CardTableContent>
              <OnlineProductTable order={onlineOrderData} configuration={configuration} />
            </CardTableContent>
          </Card>

          <OnlineOrderAction order={onlineOrderData} configuration={configuration} compact={true} />
        </PrimaryContent>
      </ContentLayout>
    </>
  );
}

const formatAddress = (onlineOrder: OnlineOrderItemFragment) => {
  const components = [
    onlineOrder.streetAddress,
    onlineOrder.streetAddress2,
    onlineOrder.addressLocality,
    `${onlineOrder.postalCode ?? ''} ${onlineOrder.addressRegion ?? ''}`,
  ].filter((component) => (component?.length ?? 0) > 0);

  return components.join(', ');
};
