import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import PrimaryButton from '@packages/ui/button/primary-button.tsx';
import SecondaryButton from '@packages/ui/button/secondary-button.tsx';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { Link, useNavigate } from 'react-router-dom';
import { TableGroupItemFragment } from '@client/graphql/types/graphql.ts';
import { getFragmentData } from '@client/graphql/types';
import Table from '@packages/ui/table/table.tsx';
import TableHeaderColumn from '@packages/ui/table/table-header-column.tsx';
import TableRowColumn from '@packages/ui/table/table-row-cell.tsx';
import { useTableGroupList } from '@client/page/management/table-group/table-group-list/logics/use-table-group-list';
import { TableGroupFragment } from '@client/graphql/table-group-fragment.ts';
import { useFocusedBranchContext } from '@client/page/management/table-group/table-group-list/component/branch-selection/focused-branch-context.ts';
import { useDialog } from '@packages/ui/modal/use-dialog.tsx';
import TableGroupCreate from '@client/page/management/table-group/table-group-create/table-group-create.tsx';
import TableRow from '@packages/ui/table/table-row.tsx';
import { TableCellAlignment } from '@packages/ui/table/table-type.ts';
import { ContentLayout, PrimaryContent } from '@packages/ui/content-layout.tsx';
import CardTableContent from '@packages/ui/card/card-content-table.tsx';
import { useTranslate } from "@tolgee/react";
import { Helmet } from 'react-helmet-async';

export default function TableGroupList() {
  return <Content />;
}

function Content() {
  const { t } = useTranslate();
  const dialog = useDialog();

  const { branch } = useFocusedBranchContext();
  const branchId = branch?.id ?? '';

  const result = useTableGroupList(branch?.id);
  const { data, loading, error, refetch } = result;

  return (
    <ContentLayout>
      <Helmet>
        <title>Table</title>
        <meta name="description" content="This page displays a list of table groups" />
      </Helmet>
      <PrimaryContent>
        <div className="flex-col space-y-4">
          <Card>
            <CardHeader title={t('table-group-list.header')}>
              <PrimaryButton
                onClick={() => {
                  dialog.showComponent((onClose) => <TableGroupCreate branchId={branchId} onClose={onClose} />);
                }}
              >
                {t('table-group-list.create-table-group')}
              </PrimaryButton>
              <SecondaryButton
                onClick={() => {
                  refetch().catch((e: unknown) => void e);
                }}
              >
                <ArrowPathIcon className="w-4" />
              </SecondaryButton>
            </CardHeader>

            <CardTableContent>
              <Table
                loading={loading}
                error={error?.message}
                data={
                  data?.tableGroups.map((raw) => {
                    return getFragmentData(TableGroupFragment, raw);
                  }) ?? []
                }
                build={(tableGroup) => <TableGroupRow key={tableGroup.id} tableGroup={tableGroup} />}
              >
                <TableHeaderColumn className="w-8">{t('table-group-list.code')}</TableHeaderColumn>
                <TableHeaderColumn>{t('common.name')}</TableHeaderColumn>
                <TableHeaderColumn alignment={TableCellAlignment.Right}>{t('common.action')}</TableHeaderColumn>
              </Table>
            </CardTableContent>
          </Card>
        </div>
      </PrimaryContent>
    </ContentLayout>
  );
}

function TableGroupRow(props: { tableGroup: TableGroupItemFragment }) {
  const navigate = useNavigate();
  const { tableGroup } = props;
  const { t } = useTranslate();

  return (
    <>
      <TableRow key={tableGroup.id}>
        <TableRowColumn alignment={TableCellAlignment.Center}>{tableGroup.arrangementCode ?? ''}</TableRowColumn>
        <TableRowColumn>
          <Link to={`${tableGroup.id}/tables`}>
            <p className="font-medium">{tableGroup.name}</p>
          </Link>
        </TableRowColumn>
        <TableRowColumn alignment={TableCellAlignment.Right}>
          <div className="space-x-2">
            <PrimaryButton
              onClick={() => {
                navigate(`${tableGroup.id}/tables`);
              }}
            >
              {t('table-group-list.table')}
            </PrimaryButton>
            <SecondaryButton
              onClick={() => {
                navigate(tableGroup.id);
              }}
            >
              {t('common.edit')}
            </SecondaryButton>
          </div>
        </TableRowColumn>
      </TableRow>
    </>
  );
}
