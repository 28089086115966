import { formatGraphQlError, validationErrors } from '@client/module/error/error';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import FormLayout from 'ui/src/form/form-layout';
import FormItem from 'ui/src/form/form-item';
import TextInput from 'ui/src/form/input/text-input';
import PrimaryButton from 'ui/src/button/primary-button';
import CheckBoxInput from 'ui/src/form/input/check-box-input';
import CardContent from 'ui/src/card/card-content';
import { ContentLayout, PrimaryContent } from 'ui/src/content-layout';
import CardHeader from 'ui/src/card/card-header';
import Card from 'ui/src/card/card';
import CardFooter from 'ui/src/card/card-footer';
import SecondaryButton from 'ui/src/button/secondary-button';
import { useNotificationCenter } from 'ui/src/notification/notification-center';
import { useDialog } from 'ui/src/modal/use-dialog';
import useResetCurrentUserPassword from './logic/use-reset-current-user-password';
export default function ResetPassword() {
  const dialog = useDialog();
  const navigate = useNavigate();
  const notificationCenter = useNotificationCenter();
  const userID = useParams().userID ?? '';

  const [update, { error: updateError }] = useResetCurrentUserPassword();
  const [oldPwd, setOldPwd] = useState<string>('');
  const [newPwd, setNewPwd] = useState<string>('');
  const [retypeNewPwd, setRetypeNewPwd] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const applicationErrors = formatGraphQlError(updateError?.graphQLErrors);
  const validationError = validationErrors(applicationErrors);

  const onSubmit = () => {
    dialog.confirmAlert({
      title: 'Update your password',
      message: 'Are you sure you want to update your password?',
      textButton: 'Update',
      onConfirm: async () => {
        await update({
          variables: {
            userID: userID,
            input: {
              oldPassword: oldPwd,
              newPassword: newPwd,
            },
          },
        });

        notificationCenter.showAlert({
          title: 'Update password',
          message: 'Update success!',
          status: 'success',
        });

        setNewPwd('');
        setOldPwd('');
        setRetypeNewPwd('');
      },
    });
  };

  return (
    <>
      <ContentLayout>
        <PrimaryContent>
          <Card>
            <CardHeader title="User profile" />

            <CardContent>
              <FormLayout>
                <FormItem title="Old password">
                  <TextInput
                    data-testid="old-password"
                    type={showPassword ? 'text' : 'password'}
                    label="old-password"
                    value={oldPwd}
                    name="old-password"
                    placeholder="Old password"
                    error={validationError.oldPassword}
                    onChange={(e) => {
                      setOldPwd(e.target.value);
                    }}
                  />
                </FormItem>
                <FormItem title="New password">
                  <TextInput
                    data-testid="new-password"
                    type={showPassword ? 'text' : 'password'}
                    label="new-password"
                    value={newPwd}
                    name="new-password"
                    placeholder="new-password"
                    error={validationError.newPassword}
                    onChange={(e) => {
                      setNewPwd(e.target.value);
                    }}
                  />
                </FormItem>

                <FormItem title="Retype new password">
                  <TextInput
                    data-testid="retype-new-password"
                    type={showPassword ? 'text' : 'password'}
                    label="retype-new-password"
                    value={retypeNewPwd}
                    name="retype-new-password"
                    placeholder="Retype new password"
                    error={validationError.newPassword}
                    onChange={(e) => {
                      setRetypeNewPwd(e.target.value);
                    }}
                  />
                </FormItem>
                <div className="flex gap-2 text-gray-500">
                  <CheckBoxInput
                    value={showPassword}
                    onChange={(_, newValue) => {
                      setShowPassword(newValue);
                    }}
                  />
                  <p>Click to show passwords</p>
                </div>
              </FormLayout>
            </CardContent>
            <CardFooter>
              <PrimaryButton disabled={newPwd !== retypeNewPwd} onClick={onSubmit}>
                Done
              </PrimaryButton>
              <SecondaryButton
                onClick={() => {
                  navigate('/user-profile');
                }}
              >
                Cancel
              </SecondaryButton>
            </CardFooter>
          </Card>
        </PrimaryContent>
      </ContentLayout>
    </>
  );
}
