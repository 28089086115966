import { ReactNode } from 'react';

export default function FormItem(props: {
  title: string;
  subtitle?: string;
  className?: string;
  children?: ReactNode;
  obligatory?: boolean;
}) {
  return (
    <div className={props.className}>
      <label className="block flex items-center gap-1.5 text-sm font-medium leading-6 text-gray-900">
        <span>{props.title}
          {props.obligatory && <span className="text-red-500"> *</span>}
        </span>
        <span className="font-normal text-gray-500 italic text-xs">{props.subtitle} </span>
      </label>
      <div className="mt-2">{props.children}</div>
    </div>
  );
}
