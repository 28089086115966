import { ReactNode } from 'react';
import ninetysix from '../../../public/black-logo.png';


function DesktopPanel(props: { children?: ReactNode }) {
  return (
    <div className="hidden lg:fixed lg:inset-y-0 lg:z-10 lg:flex lg:w-72 lg:flex-col">
      <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4">
        <div className="flex h-16 shrink-0 items-center">
          <img
            className="h-10 w-auto"
            src={ninetysix}
            alt="Digital InHouse"
          />
        </div>
        <nav className="flex flex-1 flex-col">{props.children}</nav>
      </div>
    </div>
  );
}

export default DesktopPanel;
