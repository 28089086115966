import Card from '@packages/ui/card/card.tsx';
import FormItem from '@packages/ui/form/form-item.tsx';
import AlertError from '@packages/ui/alert/alert-error.tsx';
import FormLayout from '@packages/ui/form/form-layout.tsx';
import { useCallback, useEffect } from 'react';
import { formatGraphQlError, validationErrors } from '@client/module/error/error.ts';
import { useNavigate, useParams } from 'react-router-dom';
import PrimaryButton from '@packages/ui/button/primary-button.tsx';
import TextInput from '@packages/ui/form/input/text-input.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import CardContent from '@packages/ui/card/card-content.tsx';
import CardFooter from '@packages/ui/card/card-footer.tsx';
import MenuSectionDelete from '@client/page/management/menu/menu-section-edit/components/menu-section-delete.tsx';
import { Helmet } from 'react-helmet-async';
import { useTranslate } from '@tolgee/react';
import { DaysOfWeek, MenuSectionTimeRuleType } from '@client/graphql/types/graphql';
import useFormData from '@client/module/utils/use-form-data';
import MenuSelectionInput from 'ui/src/form/input/menu-selection-input';
import ToggleInput from 'ui/src/form/input/toggle-input';
import ApplicationErrorView from '@client/module/error/application-error-view';
import CheckBoxInput from 'ui/src/form/input/check-box-input';
import classNames from 'classnames';
import SelectDaysOfWeek from '../menu-section-create/component/select-days-of-week';
import { useGetMenuSection } from './logic/use-get-menu-section';
import { useUpdateMenuSection } from './logic/use-update-menu-section';

interface FormData {
  name: string;
  description: string;
  code: string;
  enabled: boolean;
  timeRuleType: MenuSectionTimeRuleType;
  start: string;
  end: string;
  daysOfWeek: DaysOfWeek[],
  isAllDays: boolean
}


const menuSectionTypeList = [
  { key: 'Always', value: MenuSectionTimeRuleType.Always },
  { key: 'Range time', value: MenuSectionTimeRuleType.RangeTime },
];

export default function MenuSectionEdit() {
  const sectionId = useParams().menuSectionId ?? '';
  const navigate = useNavigate();
  const { t } = useTranslate();

  const { data, error: sectionDataError } = useGetMenuSection(sectionId);

  const [edit, { loading, error }] = useUpdateMenuSection();
  const {
    data: form,
    setData: setForm,
    handle,
    manualHandle,
  } = useFormData<FormData>({
    name: '',
    description: '',
    code: '',
    enabled: false,
    timeRuleType: MenuSectionTimeRuleType.Always,
    daysOfWeek: [],
    start: '00:00',
    end: '23:59',
    isAllDays: false
  });

  const applicationErrors = formatGraphQlError(error?.graphQLErrors);
  const validationError = validationErrors(applicationErrors);

  const updateForm = useCallback(
    (target: string, value: string) => {
      setForm({ ...form, [target]: value });
    },
    [form, setForm]
  );

  useEffect(() => {
    // Check data
    if (!data) {
      return;
    }
    // Get start, end values base on timeRule typename
    let start = '';
    let end = '';
    let daysOfWeek : DaysOfWeek[] = [];
    let isAllDays = false;

    if (data.menuSection.timeRule.__typename == 'MenuSectionRangeTimeRule') {
      start = data.menuSection.timeRule.start;
      end = data.menuSection.timeRule.end;
      daysOfWeek = data.menuSection.timeRule.daysOfWeek ?? [];
      isAllDays = data.menuSection.timeRule.isAllDays ?? false
    }

    // set form value
    setForm({
      name: data.menuSection.name,
      description: data.menuSection.description,
      code: data.menuSection.code,
      enabled: data.menuSection.enabled,
      timeRuleType: data.menuSection.timeRule.type,
      daysOfWeek: daysOfWeek,
      isAllDays: isAllDays,
      start: start || '00:00', // '||' operator check truthly 
      end: end || '23:59',
    });
  }, [data, setForm]);

  const onSubmit = useCallback(() => {
    edit({
      variables: {
        id: sectionId,
        input: {
          name: form.name,
          description: form.description,
          code: form.code,
          enabled: form.enabled,
        },
        timeRuleInput:
          form.timeRuleType == MenuSectionTimeRuleType.Always
            ? {
                type: form.timeRuleType,
              }
            : {
                type: form.timeRuleType,
                start: form.start,
                end: form.end,
                daysOfWeek: form.daysOfWeek,
                isAllDays: form.isAllDays
              },
      },
    })
      .then(() => {
        navigate('..');
      })
      .catch((e) => void e);
  }, [edit, sectionId, form.name, form.description, form.code, form.enabled, form.timeRuleType, form.start, form.end, 
    form.daysOfWeek, form.isAllDays, navigate]);

  return (
    <div className="flex-col space-y-4">
      <Helmet>
        <title>{t('menu.menu-detail.category.edit.helmet.title')}</title>
        <meta name="description" content="This page allows you to edit a menu section" />
      </Helmet>
      <Card>
        <CardHeader title={t('menu.menu-detail.category.edit.header')} />
        <CardContent>
          <ApplicationErrorView error={sectionDataError} />
          <FormLayout>
            <FormItem className="max-w-md" title={t('menu.menu-detail.category.edit.form-input.position.title')}>
              <TextInput
                data-testid="name-input"
                type="text"
                label="code"
                name="code"
                placeholder={t('menu.menu-detail.category.edit.form-input.position.placeholder')}
                value={form.code}
                error={validationError.name}
                autoComplete={'off'}
                onChange={(event) => {
                  updateForm(event.target.name, event.target.value);
                }}
              />
            </FormItem>

            <FormItem className="max-w-md" title={t('menu.menu-detail.category.edit.form-input.name.title')}>
              <TextInput
                data-testid="title-input"
                type="text"
                label="name"
                name="name"
                placeholder={t('menu.menu-detail.category.edit.form-input.name.placeholder')}
                value={form.name}
                error={validationError.name}
                autoComplete={'off'}
                onChange={(event) => {
                  updateForm(event.target.name, event.target.value);
                }}
              />
            </FormItem>

            <FormItem className="max-w-md" title={t('menu.menu-detail.category.edit.form-input.description.title')}>
              <TextInput
                data-testid="description-input"
                type="text"
                label="description"
                name="description"
                placeholder={t('menu.menu-detail.category.edit.form-input.description.placeholder')}
                value={form.description}
                error={validationError.description}
                autoComplete={'off'}
                onChange={(event) => {
                  updateForm(event.target.name, event.target.value);
                }}
              />
            </FormItem>

            <FormItem title={'Time rule type'} className="max-w-md">
              <div className="flex flex-col gap-4">
                <MenuSelectionInput
                  title=""
                  data={menuSectionTypeList}
                  value={menuSectionTypeList.find((item) => item.value == form.timeRuleType) ?? menuSectionTypeList[0]}
                  onChange={(newValue) => {
                    manualHandle('timeRuleType', newValue.value);
                  }}
                  build={(item) => {
                    return {
                      id: item.value,
                      name: item.key,
                    };
                  }}
                  className="-my-2 w-full"
                />
              </div>
              {form.timeRuleType == MenuSectionTimeRuleType.RangeTime && (
              <>
                <div className='mt-8'>
                  <label className="flex items-center gap-1.5 text-sm font-medium leading-6 text-gray-900">
                    <span>Range</span>
                  </label>
                  <FormItem title={''} className={classNames('relative', form.isAllDays ? 'opacity-45' : '')}>
                    <div className="max-w-full flex gap-2">
                      <input
                        className="rounded-md max-w-full border-gray-300"
                        aria-label="Start Date"
                        id="start"
                        name="start"
                        value={form.start}
                        type="time"
                        onChange={handle}
                      />

                      <input
                        className="rounded-md max-w-full border-gray-300"
                        aria-label="End Date"
                        id="end"
                        name="end"
                        value={form.end}
                        type="time"
                        onChange={handle}
                      />
                    </div>

                    {form.isAllDays && <div className="absolute inset-0"></div>}
                  </FormItem>
                </div>

                <FormItem className="" title={''}>
                  <div className="flex items-center">
                    <CheckBoxInput
                      value={form.isAllDays}
                      onChange={function (target: string, value: boolean): void {
                       manualHandle('isAllDays', value);
                      }}
                    />
                    <span className="text-xs italic text-gray-600">Apply for all time on current day opening time</span>
                  </div>
                </FormItem>

                <FormItem className="mt-6" title="Days of week">
                  <SelectDaysOfWeek
                    value={form.daysOfWeek}
                    onChange={(value: DaysOfWeek[]) => {
                      manualHandle('daysOfWeek', value);
                    }}
                  />
                </FormItem>
              </>
            )}


              {form.timeRuleType == MenuSectionTimeRuleType.Always && <div></div>}
            </FormItem>

            <FormItem title="Active" className="w-32">
              <ToggleInput
                value={form.enabled}
                onChange={(newValue) => {
                  manualHandle('enabled', newValue);
                }}
              />
            </FormItem>

            {applicationErrors?.map((e, index) => {
              return <AlertError key={index} title={e.domain} message={e.message} />;
            })}
          </FormLayout>
        </CardContent>

        <CardFooter>
          <div className="flex justify-center">
            <PrimaryButton
              id="save-button"
              data-testid="save-button"
              className="w-32"
              onClick={() => {
                onSubmit();
              }}
              loading={loading}
            >
              {t('common.button.save')}
            </PrimaryButton>
          </div>
        </CardFooter>
      </Card>

      <Card>
        <CardHeader title={t('menu.menu-detail.category.edit.extra-action.header')} />
        <CardContent>
          <MenuSectionDelete sectionId={sectionId} />
        </CardContent>
      </Card>
    </div>
  );
}
