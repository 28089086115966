import { graphql } from '@client/graphql/types';
import { useMutation } from '@apollo/client';

const MUTATION = graphql(`
  mutation SetupOnlineOrderBranchConfiguration($branchID: ID!) {
    setupOnlineOrderBranchConfiguration(branchID: $branchID) {
      branchID
      menuID
    }
  }
`);

export default function useSetupOnlineOrderBranchSetting(branchId: string) {
  return useMutation(MUTATION, {
    variables: {
      branchID: branchId,
    },
  });
}
