import OverlayBannerCreate from '@client/page/management/banner/banner-create/overlay-banner-create.tsx';
import TopBannerCreate from '@client/page/management/banner/banner-create/top-banner-create.tsx';
import OverlayBannerUpdate from '@client/page/management/banner/banner-update/overlay-banner-update.tsx';
import TopBannerUpdate from '@client/page/management/banner/banner-update/top-banner-update.tsx';
import BannerList from './banner-list/banner-list';

export const BannerRouter = {
  path: 'banners',
  handle: {
    crumb: () => 'All banner(s)',
  },
  children: [
    {
      path: '',
      element: <BannerList />
    },
    {
      path: 'create',
      children: [
        {
          path: 'overlay',
          handle: {
            crumb: () => 'Create overlay banner',
          },
          element: <OverlayBannerCreate />
        },
        {
          path: 'top',
          handle: {
            crumb: () => 'Create top banner',
          },
          element: <TopBannerCreate />
        },
      ],
    },
    {
      path: ':bannerId',
      children: [
        {
          path: 'overlay',
          handle: {
            crumb: () => 'Update overlay banner',
          },
          element: <OverlayBannerUpdate />
        },
        {
          path: 'top',
          handle: {
            crumb: () => 'Update top banner',
          },
          element: <TopBannerUpdate />
        },
      ],
    },
  ],
};
