import { useCompanyService } from '@packages/core/company/company-context.ts';
import { useAuth } from '@client/module/auth/auth-context.ts';

export default function useSignOut() {
  const auth = useAuth();
  const company = useCompanyService();

  return {
    signOut: async () => {
      try {
        await auth.logout();
      } catch (e) {
        /* eslint-disable no-console */
        console.error(e);
      }

      company.clear();
    },
  };
}
