import CardSection from '@packages/ui/card/card-section.tsx';
import DestroyButton from '@packages/ui/button/destroy-button.tsx';
import { useCallback } from 'react';
import { useDialog } from '@packages/ui/modal/use-dialog.tsx';
import { useNavigate } from 'react-router-dom';
import useDeleteMenuSection from '@client/page/management/menu/menu-section-edit/logic/use-delete-menu-section.ts';
import { useTranslate } from "@tolgee/react";

export default function MenuSectionDelete(props: { sectionId: string }) {
  const navigate = useNavigate();
  const dialog = useDialog();
  const [deleteMenuSection] = useDeleteMenuSection();
  const { t } = useTranslate();

  const onDestroy = useCallback(() => {
    dialog.destructAlert({
      title: t('menu.menu-detail.category.edit.extra-action.delete.alert.title'),
      message: t('menu.menu-detail.category.edit.extra-action.delete.alert.message'),
      textButton: t('menu.menu-detail.category.edit.extra-action.delete.alert.text-button'),
      onConfirm: async () => {
        await deleteMenuSection({ variables: { id: props.sectionId } });
        navigate('..');
      },
    });
  }, [deleteMenuSection, dialog, navigate, props.sectionId, t]);
  return (
    <div className="flex space-x-4">
      <CardSection
        title={t('menu.menu-detail.category.edit.extra-action.delete.title')}
        descriptions={t('menu.menu-detail.category.edit.extra-action.delete.description')}
      >
        <DestroyButton data-testid="delete-button" onClick={onDestroy}>
          {t('menu.menu-detail.category.edit.extra-action.delete.button')}
        </DestroyButton>
      </CardSection>
    </div>
  );
}
