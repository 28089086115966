import { useParams } from 'react-router-dom';
import Card from '@packages/ui/card/card.tsx';
import CardContent from '@packages/ui/card/card-content.tsx';
import useFormData from '@client/module/utils/use-form-data';
import {
  useUpdateOpeningTime,
} from '@client/page/management/opening-time/opening-time-edit/logic/use-update-opening-time';
import { useCallback, useEffect } from 'react';
import { stringToDaysOfWeek } from '@client/graphql/days-of-week.ts';
import FormLayout from '@packages/ui/form/form-layout.tsx';
import FormItem from '@packages/ui/form/form-item.tsx';
import TextInput from '@packages/ui/form/input/text-input.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import ToggleInput from '@packages/ui/form/input/toggle-input.tsx';
import SecondaryButton from '@packages/ui/button/secondary-button.tsx';
import PrimaryButton from '@packages/ui/button/primary-button.tsx';
import { useGetOpeningTime } from '@client/page/management/opening-time/opening-time-edit/logic/use-get-opening-time';
import Center from '@packages/ui/center.tsx';
import Spinner from '@packages/ui/spinner.tsx';
import AlertError from '@packages/ui/alert/alert-error.tsx';
import OpeningTimeDeleteButton
  from '@client/page/management/opening-time/opening-time-edit/components/delete-opening-time-button';
import { useTranslate } from "@tolgee/react";

interface OpeningTimeEditForm {
  openTime: string;
  closeTime: string;
  note: string;
  isActive: boolean;
}

export default function OpeningTimeEdit(props: {
  onClose: () => void;
  openingTimeId: string
}) {
  const dayOfWeek = useParams().dayOfWeek ?? '';
  const { onClose, openingTimeId } = props;

  const {
    data: form,
    setData,
    handle,
    manualHandle,
  } = useFormData<OpeningTimeEditForm>({
    openTime: '',
    closeTime: '',
    note: '',
    isActive: false,
  });

  const { loading, error: loadingError, data } = useGetOpeningTime(openingTimeId);
  const [update, { error: updatingError, loading: updating }] = useUpdateOpeningTime();

  const submit = useCallback(() => {
    update({
      variables: {
        id: openingTimeId,
        input: {
          dayOfWeek: stringToDaysOfWeek(dayOfWeek),
          ...form,
        },
      },
    })
      .then(() => {
        onClose();
      })
      .catch((e) => void e);
  }, [update, openingTimeId, dayOfWeek, form, onClose]);

  useEffect(() => {
    setData({
      openTime: data?.openingTime.openTime ?? '',
      closeTime: data?.openingTime.closeTime ?? '',
      note: data?.openingTime.note ?? '',
      isActive: data?.openingTime.isActive ?? false,
    });
  }, [data, setData]);

  const { t } = useTranslate();
  const error = loadingError ?? updatingError;

  if (loading) {
    return (
      <Center className="h-32">
        <Spinner />
      </Center>
    );
  }
  return (
    <>
      <Card>
        <CardHeader title={t('reservation.setting.edit-opening-time.title')} withBackButton={true} />
        <CardContent>
          <FormLayout>
            <FormItem className="max-w-md" title={t('reservation.setting.edit-opening-time.open-time')}>
              <TextInput
                type="time"
                name="openTime"
                label="openTime"
                autoComplete="off"
                placeholder="Open Time"
                value={form.openTime}
                onChange={handle}
              />
            </FormItem>

            <FormItem className="max-w-md" title={t('reservation.setting.edit-opening-time.close-time')}>
              <TextInput
                type="time"
                name="closeTime"
                label="closeTime"
                autoComplete="off"
                placeholder="Close Time"
                value={form.closeTime}
                onChange={handle}
              />
            </FormItem>

            <FormItem className="max-w-md" title={t('common.toggle.active')}>
              <ToggleInput
                value={form.isActive}
                onChange={(newValue) => {
                  manualHandle('isActive', newValue);
                }}
              />
            </FormItem>

            <FormItem className="max-w-md" title={t('common.form-input.note')}>
              <TextInput
                type="text"
                name="note"
                label="note"
                autoComplete="off"
                placeholder={t('reservation.setting.edit-opening-time.note.placeholder')}
                value={form.note}
                onChange={handle}
              />
            </FormItem>
          </FormLayout>

          {error && <AlertError className="mt-4" title={'Error'} message={error.message} />}
        </CardContent>

        <div className="bg-gray-50 px-2 py-3 flex justify-between rounded-b-lg space-x-2
         md:px-4 md:space-x-4 md:py-5 sm:px-6">
          <OpeningTimeDeleteButton id={openingTimeId} />
          <div className="flex gap-x-2">
            <SecondaryButton
              className="w-32"
              onClick={() => {
                onClose();
              }}
            >
              {t('common.button.cancel')}
            </SecondaryButton>
            <PrimaryButton className="w-32" onClick={submit} disabled={updating} loading={updating}>
              {t('common.button.save')}
            </PrimaryButton>
          </div>
        </div>
      </Card>
    </>
  );
}
