export function MapPreview(props: { longitude: number; latitude: number }) {
  return (
    <>
      <img
        className="h-96 object-cover hidden md:block"
        src={`https://api.mapbox.com/styles/v1/mapbox/streets-v12/static/pin-s-l+000(${props.longitude.toString()},${props.latitude.toString()})/${props.longitude.toString()},${props.latitude.toString()},15/1280x860?access_token=${import.meta.env.VITE_MAP_BOX as string}`}
        alt="Delivery location"
      />

      <img
        className="w-full object-cover block md:hidden"
        src={`https://api.mapbox.com/styles/v1/mapbox/streets-v12/static/pin-s-l+000(${props.longitude.toString()},${props.latitude.toString()})/${props.longitude.toString()},${props.latitude.toString()},16/640x480?access_token=${import.meta.env.VITE_MAP_BOX as string}`}
        alt="Delivery location"
      />
    </>
  );
}
