import { RouteObject } from 'react-router-dom';
import TableReservationSetting from '@client/page/management/table-reservation/setting/table-reservation-setting.tsx';
import TableReservationBranchOverview from '@client/page/management/table-reservation/branch-overview/table-reservation-branch-overview.tsx';
import TableReservationAnalytic from '@client/page/management/table-reservation/analytic/table-reservation-analytic.tsx';
import { TableReservationRangeFilter } from '@client/page/management/table-reservation/list/component/table-reservation-range-filter.tsx';
import dayjs from 'dayjs';
import TableReservationHistory from '@client/page/management/table-reservation/list/table-reservation-history.tsx';
import { OpeningTimeRouter } from '@client/page/management/opening-time/opening-time.router.tsx';
import TableReservationSinglePage from '@client/page/management/table-reservation/list/table-reservation-single-page.tsx';
import TableReservationDetail from './detail/table-reservation-detail';
import TableReservationCreate from './create/table-reservation-create';

export const TableReservationRouter: RouteObject = {
  path: 'table-reservation',
  handle: {
    crumb: () => 'breadcumb.reservation.title',
  },
  children: [
    {
      path: '',
      element: <TableReservationBranchOverview />,
    },
    {
      path: 'list',
      handle: {
        crumb: () => 'breadcumb.reservation.schedule',
      },
      children: [
        {
          path: '',
          element: <TableReservationSinglePage />,
        },
        {
          path: ':id',
          element: <TableReservationDetail />,
          handle: {
            crumb: () => 'breadcumb.reservation.edit',
          },
        },
        {
          path: 'create',
          element: <TableReservationCreate />,
          handle: {
            crumb: () => 'breadcumb.reservation.create',
          },
        },
      ],
    },
    {
      path: 'history',
      handle: {
        crumb: () => 'breadcumb.reservation.history',
      },
      children: [
        {
          path: '',
          element: (
            <TableReservationHistory
              key="history"
              initialStartTime={dayjs().subtract(7, 'day').startOf('day')}
              initialEndTime={dayjs().add(0, 'day').startOf('day')}
              filter={(data) => (
                <TableReservationRangeFilter
                  startTime={data.startTime}
                  endTime={data.endTime}
                  onChange={data.onChange}
                />
              )}
            />
          ),
        },
      ],
    },
    {
      path: 'analytics',
      handle: {
        crumb: () => 'breadcumb.reservation.analytics',
      },
      element: <TableReservationAnalytic />,
    },
    {
      path: 'preferences',
      handle: {
        crumb: () => 'breadcumb.reservation.setting',
      },
      children: [
        {
          index: true,
          element: <TableReservationSetting />,
        },
        OpeningTimeRouter,
      ],
    },
  ],
};
