const PreviewBrowserNavigationBar = () => (
  <div className="flex flex-col h-8 border-b-1 justify-center">
    <div className="flex justify-center items-center">
      <div className="flex space-x-2 pl-4">
        <div className="rounded-full bg-red-500 w-3 h-3" />
        <div className="rounded-full bg-yellow-500 w-3 h-3" />
        <div className="rounded-full bg-green-500 w-3 h-3" />
      </div>
      <div className="flex flex-grow" />
      <p className="bg-gray-100 px-8 rounded">example.com</p>
      <div className="flex flex-grow w-12" />
    </div>
  </div>
);

export default PreviewBrowserNavigationBar;
