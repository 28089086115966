import { graphql } from '@client/graphql/types';
import { useMutation } from '@apollo/client';

const MUTATION = graphql(`
  mutation OnlineOrderDone($id: ID!) {
    onlineOrderOwnerDone(id: $id)
  }
`);

export default function useDoneOnlineOrder() {
  return useMutation(MUTATION, {
    refetchQueries: ['OnlineOrderDetail','OnlineOrderDashboard'],
  });
}
