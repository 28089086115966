import { GeneralDeleteButton } from '@client/page/component/general-delete-button.tsx';
import {
  useDeleteOpeningTime,
} from '@client/page/management/opening-time/opening-time-edit/logic/use-delete-opening-time.ts';
import { useTranslate } from "@tolgee/react";

export default function OpeningTimeDeleteButton(props: { id: string }) {
  const [deleteOpeningTime] = useDeleteOpeningTime();
  const { t } = useTranslate();

  return (
    <GeneralDeleteButton
      alert={{
        label: t('common.button.delete'),
        title: t('reservation.setting.edit-opening-time.delete-alert.title'),
        message: t('reservation.setting.edit-opening-time.delete-alert.message'),
      }}
      delete={async () => {
        await deleteOpeningTime({ variables: { id: props.id } });
      }}
    />
  );
}