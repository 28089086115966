import { ProductConfigurationType } from '@client/graphql/types/graphql';
import useFormData from '@client/module/utils/use-form-data';
import Card from 'ui/src/card/card';
import CardContent from 'ui/src/card/card-content';
import CardHeader from 'ui/src/card/card-header';
import FormItem from 'ui/src/form/form-item';
import FormLayout from 'ui/src/form/form-layout';
import MenuSelectionInput from 'ui/src/form/input/menu-selection-input';
import TextInput from 'ui/src/form/input/text-input';
import SecondaryButton from 'ui/src/button/secondary-button';
import PrimaryButton from 'ui/src/button/primary-button';
import DestroyButton from 'ui/src/button/destroy-button';
import { useNotificationCenter } from 'ui/src/notification/notification-center';
import { formatProductConfigurationType } from '../../menu-product-configuration-edit/logic/format-product-configuration-type';

interface FormData {
  title: string;
  type: ProductConfigurationType;
}

interface MenuProductConfigurationWithoutValues {
  id: string;
  title: string;
  type: ProductConfigurationType;
}
export default function MenuProductConfigurationUpdate(props: {
  data: MenuProductConfigurationWithoutValues;
  onClose: () => void;
  onUpdate: (id: string, action: 'update' | 'delete', updateItem?: MenuProductConfigurationWithoutValues) => void;
}) {
  const { showAlert } = useNotificationCenter();

  const {
    data: form,
    handle,
    manualHandle,
  } = useFormData<FormData>({
    title: props.data.title,
    type: props.data.type,
  });

  const handleUpdateButton = (action: 'delete' | 'update') => {
    // action: update
    if (action == 'update') {
      props.onUpdate(props.data.id, action, {
        id: props.data.id,
        title: form.title,
        type: form.type,
      });
      showAlert({
        status: 'success',
        title: 'Success',
        message: 'Configuration has been updated',
      });
      return;
    }
    // action: delete
    props.onUpdate(props.data.id, action);
    showAlert({
      status: 'success',
      title: 'Success',
      message: 'Configuration has been deleted',
    });
  };

  const selectionList =
    props.data.type == ProductConfigurationType.Base
      ? [ProductConfigurationType.Base]
      : [ProductConfigurationType.Selection, ProductConfigurationType.Option, ProductConfigurationType.Base];
  return (
    <Card>
      <CardHeader title="Edit configuration" />

      <CardContent>
        <FormLayout>
          <FormItem title="Title">
            <TextInput
              type="text"
              label="title"
              value={form.title}
              name="title"
              placeholder="Title"
              onChange={handle}
            />
          </FormItem>
          <MenuSelectionInput
            title="Type"
            data={selectionList}
            value={form.type}
            onChange={(newValue) => {
              manualHandle('type', newValue);
            }}
            build={(type) => ({
              id: type,
              name: formatProductConfigurationType(type),
            })}
          />
        </FormLayout>
      </CardContent>

      <div className="bg-gray-50 px-2 py-3 flex justify-between rounded-b-lg space-x-2 md:px-4 md:space-x-4 md:py-5 sm:px-6">
        <div className="flex gap-2">
          <SecondaryButton
            className="w-32"
            onClick={() => {
              props.onClose();
            }}
          >
            Cancel
          </SecondaryButton>
          {props.data.type !== ProductConfigurationType.Base && (
            <DestroyButton
              onClick={() => {
                handleUpdateButton('delete');
                props.onClose();
              }}
            >
              Delete
            </DestroyButton>
          )}
        </div>
        <PrimaryButton
          className="w-32"
          onClick={() => {
            handleUpdateButton('update');
            props.onClose();
          }}
        >
          Update
        </PrimaryButton>
      </div>
    </Card>
  );
}
