import { Link, useMatches, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import {
  ArchiveBoxIcon,
  BanknotesIcon,
  CalendarIcon,
  ChartBarSquareIcon,
  Cog6ToothIcon,
  FilmIcon,
  HomeIcon,
  RectangleStackIcon,
  Square3Stack3DIcon,
  UsersIcon,
  WalletIcon,
} from '@heroicons/react/24/outline';
import { useFocusedCompany } from '@packages/core/company/focused-company-context.ts';
import { ComponentProps, ComponentType } from 'react';
import { ArrowsPointingInIcon } from '@heroicons/react/24/solid';
import { UserCompanyPermission } from '@client/graphql/types/graphql.ts';
import FocusedBranchSelection
  from '@client/page/management/table-group/table-group-list/component/branch-selection/focused-branch-selection.tsx';
import { captureException } from '@sentry/react';
import getImageLink from '@packages/core/image-source/get-image-link.ts';
import checkPermission from '@client/module/utils/check-permission.ts';
import { useAuth } from '@client/module/auth/auth-context.ts';
import { useTolgee, useTranslate } from '@tolgee/react';

interface NavigationItem {
  title: string;
  href: string;
  icon: ComponentType<ComponentProps<'svg'>>;
  permission?: UserCompanyPermission;
  query?: Record<string, string> | (() => Record<string, string>);
}


const core: NavigationItem[] = [
  // { title: 'Dashboard', href: '/', icon: HomeIcon },
  // { title: 'Reports', href: '/reports', icon: ChartPieIcon },
  {
    title: 'Users',
    href: '/user-company-roles',
    icon: UsersIcon,
    permission: UserCompanyPermission.ManageUsers,
  },
  {
    title: 'Location',
    href: '/branches',
    icon: RectangleStackIcon,
    permission: UserCompanyPermission.ManageBranches,
  },
  {
    title: 'Table',
    href: '/table-groups',
    icon: ArrowsPointingInIcon,
    permission: UserCompanyPermission.ManageTables,
  },
];

/*
const order: NavigationItem[] = [
  { title: 'Order', href: '/orders', icon: PencilIcon },
  { title: 'Invoice', href: '/invoices', icon: NewspaperIcon },
];
 */

const website: NavigationItem[] = [
  {
    title: 'Banner',
    href: '/banners',
    icon: FilmIcon,
    permission: UserCompanyPermission.ManageBanners,
  },
  {
    title: 'Invoice',
    href: '/invoices',
    icon: BanknotesIcon,
    permission: UserCompanyPermission.ManageInvoices,
  },
];

const dashboard: NavigationItem[] = [
  {
    title: 'Dashboard',
    href: '/dashboard',
    icon: HomeIcon,
  },
];

const reservation: NavigationItem[] = [
  {
    title: 'Schedule',
    href: '/table-reservation/list',
    icon: CalendarIcon,
    permission: UserCompanyPermission.ManageTableReservation,
  },
  {
    title: 'Report',
    href: '/table-reservation/analytics',
    icon: ChartBarSquareIcon,
    permission: UserCompanyPermission.ManageTableReservation,
  },
  {
    title: 'History',
    href: '/table-reservation/history',
    icon: ArchiveBoxIcon,
    permission: UserCompanyPermission.ManageTableReservation,
  },
  {
    title: 'Reservation Setup',
    href: '/table-reservation/preferences',
    icon: Cog6ToothIcon,
    permission: UserCompanyPermission.ManageTableReservation,
  },
];

const onlineOrder: NavigationItem[] = [
  {
    title: 'Orders',
    href: '/online-order/dashboard',
    icon: WalletIcon,
    permission: UserCompanyPermission.ManageOnlineOrder,
  },
  {
    title: 'Menu',
    href: '/menu',
    icon: Square3Stack3DIcon,
    permission: UserCompanyPermission.ManageMenus,
  },
  {
    title: 'History',
    href: '/online-order/history',
    icon: ArchiveBoxIcon,
    permission: UserCompanyPermission.ManageOrders,
  },
  {
    title: 'Orders Setup',
    href: '/online-order/setting',
    icon: Cog6ToothIcon,
    permission: UserCompanyPermission.ManageOnlineOrderBranchConfiguration,
  },
];

function NavigationContent(props: { closeSidebar?: () => void }) {
  const matches = useMatches();
  const navigate = useNavigate();
  const company = useFocusedCompany();
  const { t } = useTranslate();

  const currentLocation = (path: string): boolean => {
    for (const match of matches.slice(4, 5)) {
      if (match.pathname === path) {
        return true;
      }
    }
    return false;
  };
  return (
    <ul role="list" className="flex flex-1 flex-col justify-between gap-y-7">
      <div className="flex flex-col gap-y-7">
        <div
          className="flex items-center space-x-2 cursor-pointer"
          onClick={() => {
            navigate('/company-selection');
          }}
        >
          {company.logo && <img src={getImageLink(company.logo)} alt={company.name} className="w-6 h-6 rounded" />}
          <p className="text-lg font-medium">{company.name}</p>
        </div>

        <FocusedBranchSelection />

        <NavigationItemList title="Dashboard" items={dashboard} closeSidebar={props.closeSidebar} />

        <NavigationItemList title="Reservation" items={reservation} closeSidebar={props.closeSidebar} />

        <NavigationItemList title="Online order" items={onlineOrder} closeSidebar={props.closeSidebar} />

        <NavigationItemList title="Features" items={website} closeSidebar={props.closeSidebar} />

        <NavigationItemList title="System" items={core} closeSidebar={props.closeSidebar} />
      </div>

      <div className="flex flex-col gap-y-6">
        {/* <LiveChatSupport /> */}

        <div className="mt-auto flex space-x-4">
          <p className="text-lg font-medium">{t('navigation-content.Language.title', 'Language')}:</p>
          <LanguageIcon language="English" languageCode="en" country="us" />
          <LanguageIcon language="Germany" languageCode="de" country="de" />
          <LanguageIcon language="Vietnamese" languageCode="vi" country="vn" />
        </div>

        <li>
          <Link
            to="/settings"
            onClick={() => {
              props.closeSidebar?.();
            }}
            className={classNames(
              'group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600',
              {
                'bg-gray-50 text-indigo-600': currentLocation('/settings'),
              },
            )}
          >
            <Cog6ToothIcon
              className={classNames('h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-600', {
                'text-indigo-600': currentLocation('/settings'),
              })}
              aria-hidden="true"
            />
            {t('navigation-content.Settings.title', 'Settings')}
          </Link>
        </li>
      </div>
    </ul>
  );
}

function LanguageIcon(props: { language: string; languageCode: string; country: string }) {
  const { changeLanguage } = useTolgee(['language']);

  const changeLanguageCallback = (lng: string) => {
    changeLanguage(lng).catch(captureException);
    localStorage.setItem('language', lng);
  };

  return (
    <button
      onClick={() => {
        changeLanguageCallback(props.languageCode);
      }}
    >
      <img src={`https://flagcdn.com/16x12/${props.country}.png`} width="20" height="16" alt={props.country} />
      {/*<p>{props.language}</p>*/}
    </button>
  );
}

function NavigationItemList(props: { title?: string; items: NavigationItem[]; closeSidebar?: () => void }) {
  const items = props.items;
  const matches = useMatches();
  const { t } = useTranslate();
  const featTitle = props.title ?? '';

  const { auth } = useAuth();
  const company = useFocusedCompany();

  const currentLocation = (path: string): boolean => {
    const additionalPath = path.match(/\//g)?.length ?? 0;

    for (const match of matches.slice(3 + additionalPath, 4 + additionalPath)) {
      if (match.pathname === path) {
        return true;
      }
    }
    return false;
  };

  const filterItem = items.filter((item) => checkPermission(item.permission, company.id, auth));

  if (filterItem.length == 0) {
    return <></>;
  }

  return (
    <li>
      {props.title &&
        <p className="text-lg font-medium pb-2">{t('navigation-content.' + props.title + '.title', props.title)}</p>}

      <ul role="list" className="-mx-2 space-y-1">
        {filterItem.map((item) => (
          <li key={item.title}>
            <Link
              to={item.href}
              onClick={() => {
                props.closeSidebar && props.closeSidebar();
              }}
              className={classNames(
                currentLocation(item.href)
                  ? 'bg-gray-50 text-indigo-600'
                  : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
              )}
            >
              <item.icon
                className={classNames(
                  currentLocation(item.href) ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600',
                  'h-6 w-6 shrink-0',
                )}
                aria-hidden="true"
              />
              {t('navigation-content.' + featTitle + '.item.' + item.title, item.title)}
            </Link>
          </li>
        ))}
      </ul>
    </li>
  );
}

export default NavigationContent;
