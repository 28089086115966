import { useParams, useNavigate } from 'react-router-dom';
import Card from '@packages/ui/card/card.tsx';
import PrimaryButton from '@packages/ui/button/primary-button.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import { daysOfWeekToString, stringToDaysOfWeek } from '@client/graphql/days-of-week.ts';
import { useOpeningTimesQuery } from '@client/page/management/opening-time/opening-time-setup/logic/use-opening-times-query.ts';

import Table from '@packages/ui/table/table.tsx';
import TableHeaderColumn from '@packages/ui/table/table-header-column.tsx';
import { getFragmentData } from '@client/graphql/types';
import { OpeningTimeFragment } from '@client/graphql/opening-time-fragment.ts';
import TableRowColumn from '@packages/ui/table/table-row-cell.tsx';
import TableRow from '@packages/ui/table/table-row.tsx';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import { OpeningTimeItemFragment, OpeningTimeServiceType } from '@client/graphql/types/graphql';
import { ContentLayout, PrimaryContent } from 'ui/src/content-layout';
import CardTableContent from '@packages/ui/card/card-content-table.tsx';
import { Helmet } from 'react-helmet-async';
import { useFocusedBranchContext } from '@client/page/management/table-group/table-group-list/component/branch-selection/focused-branch-context.ts';

const OpeningTimeDayOfWeek = () => {
  const params = useParams();

  const service = params.service as OpeningTimeServiceType;
  const daysOfWeek = stringToDaysOfWeek(params.dayOfWeek ?? '') ?? undefined;

  const { branch } = useFocusedBranchContext();

  const navigate = useNavigate();

  const { data, error, loading } = useOpeningTimesQuery(branch?.id, service, undefined, daysOfWeek);

  if (!daysOfWeek) {
    return <div>Invalid day of week</div>;
  }

  return (
    <ContentLayout>
      <Helmet>
        <title>{daysOfWeekToString(daysOfWeek)}</title>
        <meta name="description" content={`This page displays the opening time on ${daysOfWeekToString(daysOfWeek)}`} />
      </Helmet>
      <PrimaryContent>
        <Card>
          <CardHeader withBackButton={true} title={daysOfWeekToString(daysOfWeek)}>
            <PrimaryButton
              onClick={() => {
                navigate('create');
              }}
            >
              Add opening times
            </PrimaryButton>
          </CardHeader>
          <CardTableContent>
            <Table
              loading={loading}
              error={error?.message}
              data={data?.openingTimes.map((raw) => getFragmentData(OpeningTimeFragment, raw)) ?? []}
              build={(openingTime) => <OpeningTimeRow key={openingTime.id} openingTime={openingTime} />}
            >
              <TableHeaderColumn>Open Time</TableHeaderColumn>
              <TableHeaderColumn>Close Time</TableHeaderColumn>
              <TableHeaderColumn>Status</TableHeaderColumn>
              <TableHeaderColumn>Note</TableHeaderColumn>
              <TableHeaderColumn></TableHeaderColumn>
            </Table>
          </CardTableContent>
        </Card>
      </PrimaryContent>
    </ContentLayout>
  );
};

function OpeningTimeRow(props: { openingTime: OpeningTimeItemFragment }) {
  const navigate = useNavigate();
  const { openingTime } = props;
  return (
    <>
      <TableRow
        key={openingTime.id}
        onClick={() => {
          navigate(openingTime.id);
        }}
      >
        <TableRowColumn>{openingTime.openTime}</TableRowColumn>

        <TableRowColumn>{openingTime.closeTime}</TableRowColumn>

        <TableRowColumn>{openingTime.isActive ? 'Active' : 'Inactive'}</TableRowColumn>

        <TableRowColumn>{openingTime.note}</TableRowColumn>

        <TableRowColumn>
          <PencilSquareIcon className="h-6 w-6" />
        </TableRowColumn>
      </TableRow>
    </>
  );
}

export default OpeningTimeDayOfWeek;
