import { graphql } from '@client/graphql/types';

export const TableFragment = graphql(`
  fragment TableItem on Table {
    id
    name
    seats
    tableGroupId
  }
`);

export const TableWithGroupFragment = graphql(`
  fragment TableWithGroupItem on Table {
    id
    name
    seats
    tableGroupId
    tableGroup {
      id
      name
      arrangementCode
    }
  }
`);
