import { useParams } from 'react-router-dom';
import Card from 'ui/src/card/card';
import CardHeader from '@packages/ui/card/card-header.tsx';
import InvoiceProductTable from '@client/page/management/invoice/invoice-detail/component/invoice-product-table.tsx';
import CardTableContent from '@packages/ui/card/card-content-table.tsx';
import moment from 'moment';
import { useInvoiceManagement } from './logic/use-invoice-management';

export default function InvoiceDetail() {
  const invoiceId = useParams().invoiceId ?? '';
  const { data: invoiceData } = useInvoiceManagement(invoiceId);
  if (!invoiceData) {
    return <div>Data is empty</div>;
  }
  return (
    <div className="p-2">
      <Card>
        <CardHeader withBackButton={true} backTarget={-1} title={`Invoice`}>
          <div className='flex flex-col items-end'>
            <p className={'text-gray-500 italic'}><span className='font-semibold'>Invoice ID:</span> {invoiceData.invoice.shortID}</p>
            <p className={'text-gray-500 italic'}><span className='font-semibold'>Created at:
              </span> {moment(invoiceData.invoice.createdAt).format("HH:mm - DD/MM/yy")}</p>
          </div>
        </CardHeader>

        <CardTableContent>
          <InvoiceProductTable invoice={invoiceData.invoice} />
        </CardTableContent>
      </Card>
    </div>
  );
}
