import CardHeader from '@packages/ui/card/card-header.tsx';
import Card from '@packages/ui/card/card.tsx';
import CardContent from '@packages/ui/card/card-content.tsx';
import useFormData from '@client/module/utils/use-form-data.ts';
import FormLayout from '@packages/ui/form/form-layout.tsx';
import FormItem from '@packages/ui/form/form-item.tsx';
import TextInput from '@packages/ui/form/input/text-input.tsx';
import AlertError from '@packages/ui/alert/alert-error.tsx';
import { useCreateBranch } from '@client/page/management/branch/branch-create/logic/use-create-branch.ts';
import { useCallback } from 'react';
import CardFooter from '@packages/ui/card/card-footer.tsx';
import PrimaryButton from '@packages/ui/button/primary-button.tsx';
import {
  formatGraphQlError,
  validationErrors,
} from '@client/module/error/error.ts';
import { useNavigate } from 'react-router-dom';
import SecondaryButton from '@packages/ui/button/secondary-button.tsx';
import { useCompanyId } from '@packages/core/company/focused-company-context.ts';
import { ContentLayout, PrimaryContent } from 'ui/src/content-layout';
import { Helmet } from 'react-helmet-async';

interface FormData {
  name: string;
  streetAddress: string;
  addressLocality: string;
  addressRegion: string;
  postalCode: string;
  addressCountry: string;
}

export default function BranchCreate() {
  const navigate = useNavigate();
  const companyId = useCompanyId();

  const { data: form, handle } = useFormData<FormData>({
    name: '',
    streetAddress: '',
    addressLocality: '',
    addressRegion: '',
    postalCode: '',
    addressCountry: '',
  });

  const [create, { error, loading }] = useCreateBranch();
  const applicationErrors = formatGraphQlError(error?.graphQLErrors);
  const validationError = validationErrors(applicationErrors);
  const submit = useCallback(() => {
    create({
      variables: {
        companyId: companyId,
        input: form,
      },
    })
      .then(() => {
        navigate('..');
      })
      .catch((e) => void e);
  }, [companyId, create, form, navigate]);

  return (
    <ContentLayout>
      <Helmet>
        <title>Create Location</title>
        <meta
          name="description"
          content="This page allows you to create a branch"
        />
      </Helmet>

      <PrimaryContent>
        <Card>
          <CardHeader title="Create Location" />

          <CardContent>
            <FormLayout>
              <FormItem className="max-w-md" title="Name" obligatory={true}>
                <TextInput
                  type="text"
                  name="name"
                  label="name"
                  autoComplete="off"
                  placeholder="Name"
                  error={validationError.name}
                  value={form.name}
                  onChange={handle}
                />
              </FormItem>


              <FormItem className="max-w-md" title="Postal Code"  obligatory={true}>
                <TextInput
                  type="text"
                  name="postalCode"
                  label="postalCode"
                  autoComplete="off"
                  placeholder="Postal Code"
                  error={validationError.postalCode}
                  value={form.postalCode}
                  onChange={handle}
                />
              </FormItem>

              <FormItem className="max-w-md" title="City" obligatory={true}>
                <TextInput
                  type="text"
                  name="addressLocality"
                  label="addressLocality"
                  autoComplete="off"
                  placeholder="City"
                  error={validationError.addressLocality}
                  value={form.addressLocality}
                  onChange={handle}
                />
              </FormItem>

              <FormItem className="max-w-md" title="Street Address" obligatory={true}>
                <TextInput
                  type="text"
                  name="streetAddress"
                  label="streetAddress"
                  autoComplete="off"
                  error={validationError.streetAddress}
                  placeholder="Street"
                  value={form.streetAddress}
                  onChange={handle}
                />
              </FormItem>


              <FormItem className="max-w-md" title="Region">
                <TextInput
                  type="text"
                  name="addressRegion"
                  label="addressRegion"
                  autoComplete="off"
                  placeholder="Region"
                  value={form.addressRegion}
                  onChange={handle}
                />
              </FormItem>

              <FormItem className="max-w-md" title="Country" obligatory={true}>
                <TextInput
                  type="text"
                  name="addressCountry"
                  label="addressCountry"
                  autoComplete="off"
                  error={validationError.addressCountry}
                  placeholder="Country"
                  value={form.addressCountry}
                  onChange={handle}
                />
              </FormItem>
            </FormLayout>

            {error && (
              <AlertError
                className="mt-4"
                title={'Error'}
                message={error.message}
              />
            )}
          </CardContent>

          <CardFooter>
            <div className="flex justify-center space-x-4">
              <SecondaryButton
                className="w-32"
                onClick={() => {
                  navigate('..');
                }}
              >
                Cancel
              </SecondaryButton>
              <PrimaryButton
                id="create-button"
                data-testid="create-button"
                className="w-32"
                onClick={submit}
                loading={loading}
              >
                Create
              </PrimaryButton>
            </div>
          </CardFooter>
        </Card>
      </PrimaryContent>
    </ContentLayout>
  );
}
