import { graphql } from '@client/graphql/types';
import { useQuery } from '@apollo/client';

const QUERY = graphql(`
  query PushTokens {
    pushNotificationTokens
  }
`);

export function usePushTokens() {
  return useQuery(QUERY, {});
}
