import { MenuProductConfigurationValue } from '@client/graphql/types/graphql';
import useFormData from '@client/module/utils/use-form-data';
import { useCompanyCurrency } from 'core/src/company/focused-company-context';
import { formatRawCurrency, normalizeCurrency } from 'core/src/utils/currency-formatter';
import DestroyButton from 'ui/src/button/destroy-button';
import PrimaryButton from 'ui/src/button/primary-button';
import SecondaryButton from 'ui/src/button/secondary-button';
import Card from 'ui/src/card/card';
import CardContent from 'ui/src/card/card-content';
import CardHeader from 'ui/src/card/card-header';
import FormItem from 'ui/src/form/form-item';
import FormLayout from 'ui/src/form/form-layout';
import TextInput from 'ui/src/form/input/text-input';
import { useNotificationCenter } from 'ui/src/notification/notification-center';

interface Form {
  name: string;
  price: string;
}

export default function MenuProductConfigurationValueUpdate(props: {
  configurationID: string;
  data: MenuProductConfigurationValue;
  onClose: () => void;
  onUpdate: (
    configurationID: string,
    id: string,
    action: 'update' | 'delete',
    updateItem?: MenuProductConfigurationValue
  ) => void;
}) {
  const { showAlert } = useNotificationCenter();
  const currency = useCompanyCurrency();
  const { data: form, handle } = useFormData<Form>({
    name: props.data.name,
    price: formatRawCurrency(props.data.price, currency).value,
  });

  const onSubmit = (action: 'update' | 'delete') => {
    // action: update
    if (action == 'update') {
      props.onUpdate(props.configurationID, props.data.id, action, {
        id: props.data.id,
        name: form.name,
        price: normalizeCurrency(form.price, currency),
      });
      showAlert({
        status: 'success',
        title: 'Success',
        message: 'Configuration value has been updated',
      });
      return;
    }
    // action: delete
    props.onUpdate(props.configurationID, props.data.id, action);
    showAlert({
      status: 'success',
      title: 'Success',
      message: 'Configuration value has been deleted',
    });
  };

  return (
    <Card>
      <CardHeader title="Price settings" />

      <CardContent>
        <FormLayout>
          <FormItem className="w-64" title={'Name'}>
            <TextInput
              data-testid="name-input"
              type="text"
              label="name"
              value={form.name}
              name="name"
              placeholder="Name"
              onChange={handle}
            />
          </FormItem>

          <FormItem className="w-32" title={'Price'}>
            <TextInput
              data-testid="price-input"
              type="number"
              label="price"
              value={form.price}
              name="price"
              placeholder="Price"
              onChange={handle}
            />
          </FormItem>
        </FormLayout>
      </CardContent>

      <div className="bg-gray-50 px-2 py-3 flex justify-between rounded-b-lg space-x-2 md:px-4 md:space-x-4 md:py-5 sm:px-6">
        <div className='flex gap-2'>
          <SecondaryButton
            className="w-32"
            onClick={() => {
              props.onClose();
            }}
          >
            Back
          </SecondaryButton>
          <DestroyButton
            onClick={() => {
              onSubmit('delete');
              props.onClose();
            }}
          >
            Delete
          </DestroyButton>
        </div>

        <PrimaryButton
          className="w-32"
          data-testid="create-button"
          onClick={() => {
            onSubmit('update');
            props.onClose();
          }}
        >
          Update
        </PrimaryButton>
      </div>
    </Card>
  );
}
