import { useQuery } from '@apollo/client';
import { graphql } from '@client/graphql/types';

export const MENU_SECTION_QUERY = graphql(`
  query getMenuSection($id: ID!) {
    menuSection(id: $id) {
      id
      name
      description
      code
      enabled
      timeRule{
        __typename
        ... on MenuSectionAlwaysTimeRule {
          type
        }
      
        __typename
        ... on MenuSectionRangeTimeRule {
          type
          start
          end
          daysOfWeek
          isAllDays
        }
      }
    }
  }
`);

export function useGetMenuSection(sectionId: string) {
  return useQuery(MENU_SECTION_QUERY, { variables: { id: sectionId } });
}
