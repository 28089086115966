import { Banner } from '@client/graphql/types/graphql';
import { Link } from 'react-router-dom';
import Table from 'ui/src/table/table';
import TableRow from 'ui/src/table/table-row';
import TableRowColumn from 'ui/src/table/table-row-cell';
import TableHeaderColumn from 'ui/src/table/table-header-column';
import { useCompanyId } from '@packages/core/company/focused-company-context.ts';
import DotStatus from '@packages/ui/status/dot-status.tsx';
import moment from 'moment';
import { useTranslate } from '@tolgee/react';
import useBanners from '../logic/use-banners';

export default function BannerTable(props: { enable?: boolean }) {
  const companyID = useCompanyId();
  const { data, error, loading } = useBanners(companyID, 0, 50, props.enable);
  const { t } = useTranslate();
  return (
    <>
      <Table
        loading={loading}
        error={error?.message}
        data={data?.banners ?? []}
        build={(banner) => <BannerRow key={banner.bannerId} banner={banner} />}
      >
        <TableHeaderColumn className="w-6">{t('banner-table.status')}</TableHeaderColumn>
        <TableHeaderColumn>{t('banner-table.title')}</TableHeaderColumn>
        <TableHeaderColumn>{t('banner-table.content-type')}</TableHeaderColumn>
        <TableHeaderColumn>{t('banner.time-rule-type')}</TableHeaderColumn>
        <TableHeaderColumn>{t('banner-table.create-at')}</TableHeaderColumn>
      </Table>
    </>
  );
}

function BannerRow(props: { banner: Banner }) {
  const { banner } = props;

  if (!banner.content.type) {
    return null;
  }

  return (
    <>
      <TableRow key={banner.bannerId}>
        <TableRowColumn>
          <div className="flex justify-center">
            <DotStatus enabled={banner.enabled} />
          </div>
        </TableRowColumn>
        <TableRowColumn>
          <Link to={banner.bannerId + '/' + banner.content.type.toLowerCase()}>
            <p className="font-medium">{banner.title}</p>
          </Link>
        </TableRowColumn>
        <TableRowColumn>
          <Link to={banner.bannerId + '/' + banner.content.type.toLowerCase()}>
            {banner.content.type.toLowerCase()}
          </Link>
        </TableRowColumn>
        <TableRowColumn>
          <Link to={banner.bannerId + '/' + banner.content.type.toLowerCase()}>
            {banner.timeRule.type?.toLowerCase().split('_').join(' ')}
          </Link>
        </TableRowColumn>
        <TableRowColumn>{moment(banner.createAt).format('DD MMMM YYYY')}</TableRowColumn>
      </TableRow>
    </>
  );
}
