import { graphql } from '@client/graphql/types';
import { useMutation } from '@apollo/client';

const MUTATION = graphql(`
    mutation QuickCreateAccount($input: QuickSetupInput!) {
        clientQuickSetup(input: $input) {
            username
            password
        }
    }
`);

export default function useQuickCreateAccount() {
  return useMutation(MUTATION, {});
}
