import { useLocalStorage } from 'usehooks-ts';
import { clearAuthStatus } from '@client/module/auth/auth.ts';
import moment from 'moment';
import { useCallback, useEffect, useMemo } from 'react';
import { captureException } from '@sentry/react';
import { authClient, AuthUserCompanyRole } from './auth-client.ts';

export interface Auth {
  id: string;
  username: string;
  email: string;
  role: string[];
  userCompanyRole: AuthUserCompanyRole[];

  accessTokenExpiresAt: Date;
  accessToken: string;
  refreshToken: string;
}

export interface UseAuth {
  auth: Auth | null;

  login(username: string, password: string): Promise<void>;

  logout(): Promise<void>;
}

export const useAuthProvider = () => {
  const [auth, setAuth] = useLocalStorage<Auth | null>('auth', null);

  const login = useCallback(
    async (username: string, password: string) => {
      const res = await authClient.login(username, password);
      const authUser = await authClient.current(res.accessToken);

      setAuth({
        id: authUser.id,
        username: authUser.username,
        email: authUser.email,
        role: authUser.role,
        userCompanyRole: authUser.userCompanyRole,

        accessTokenExpiresAt: moment().add(res.expiresIn, 'seconds').toDate(),
        accessToken: res.accessToken,
        refreshToken: res.refreshToken,
      });
    },
    [setAuth]
  );

  const logout = useCallback(async () => {
    if (auth) {
      await authClient.logout(auth.refreshToken);
    }

    setAuth(null);
    clearAuthStatus();
  }, [auth, setAuth]);

  useEffect(() => {
    const interval = setInterval(() => {
      // Check before 5 minutes of the token expiration
      if (auth && moment(auth.accessTokenExpiresAt).subtract(10, 'minutes').isBefore(moment())) {
        authClient
          .refreshToken(auth.refreshToken)
          .then((authResult) => {
            if (!authResult) {
              return;
            }

            setAuth((prev) => {
              if (!prev) {
                return prev;
              }

              return {
                ...prev,
                accessTokenExpiresAt: moment().add(authResult.expiresIn, 'seconds').toDate(),
                accessToken: authResult.accessToken,
                refreshToken: authResult.refreshToken,
              };
            });
          })
          .catch(captureException);
      }
    }, 60 * 1000);

    // Cleanup function to clear the interval when the component unmounts
    return () => {
      clearInterval(interval);
    };
  }, [auth, setAuth]);

  return useMemo(
    () => ({
      auth,
      login,
      logout,
    }),
    [auth, login, logout]
  );
};
