import { useNavigate } from 'react-router-dom';
import CardHeader from 'ui/src/card/card-header';
import Card from '@packages/ui/card/card.tsx';
import CardContent from '@packages/ui/card/card-content.tsx';
import PrimaryButton from 'ui/src/button/primary-button';
import Pagination from '@packages/core/utils/pagination/pagination.tsx';
import usePagination from 'core/src/utils/pagination/use-pagination';
import { useCompanyId } from '@packages/core/company/focused-company-context.ts';
import ApplicationErrorView from '@client/module/error/application-error-view.tsx';
import { ContentLayout, PrimaryContent } from 'ui/src/content-layout.tsx';
import CardTableContent from '@packages/ui/card/card-content-table.tsx';
import { Helmet } from 'react-helmet-async';
import UserTable from './component/user-table';
import useUserCompanyRoleCount from './logic/use-user-count';
import useUserCompanyRoles from './logic/use-user-company-roles.ts';

export default function UserCompanyRoleList() {
  const navigate = useNavigate();
  const companyId = useCompanyId();

  const { data: quantityData } = useUserCompanyRoleCount(companyId);
  const paginationInfo = usePagination(
    quantityData?.userCompanyRolesCount ?? 0
  );
  const { data, loading, error } = useUserCompanyRoles(
    companyId,
    paginationInfo.info.current * paginationInfo.info.perPage,
    paginationInfo.info.perPage
  );

  if (!data && error) {
    return <ApplicationErrorView error={error} />;
  }

  return (
     
      <ContentLayout>
        <Helmet>
          <title>Users</title>
          <meta
            name="description"
            content="This page displays a list of users and their roles"
          />
        </Helmet>
        <PrimaryContent>
          <Card>
            <CardHeader title={'User'}>
              <PrimaryButton
                onClick={() => {
                  navigate('create');
                }}
                data-testid="list-create-button"
              >
                Create user
              </PrimaryButton>
            </CardHeader>

            <CardTableContent>
              <UserTable
                loading={loading}
                items={
                  data?.userCompanyRoles.map((item) => ({
                    user: item.user,
                    permissionCount: item.permission.length,
                  })) ?? []
                }
              />
            </CardTableContent>

            <CardContent>
              <Pagination
                info={paginationInfo.info}
                setCurrent={paginationInfo.updateCurrent}
              />
            </CardContent>
          </Card>
        </PrimaryContent>
      </ContentLayout>
     
  );
}
