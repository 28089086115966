import { useQuery } from '@apollo/client';
import { graphql } from '@client/graphql/types';

const CURRENT_USER = graphql(`
  query CurrentUser{
    currentUser{
        id
        email
        firstName
        lastName
        username
        status
        role
    }
  }
`);

export default function useCurrentUser() {
  return useQuery(CURRENT_USER, {});
}
