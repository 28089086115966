import { ContentLayout, PrimaryContent } from 'ui/src/content-layout';
import { Helmet } from 'react-helmet-async';
import BranchEdit from '@client/page/management/branch/branch-edit/components/branch-edit.tsx';

export default function BranchEditPage() {
  return (
    <ContentLayout>
      <Helmet>
        <title>Edit Branch</title>
        <meta name="description" content="This page allows you to edit a branch" />
      </Helmet>
      <PrimaryContent>
        <BranchEdit focusBranch={true}/>
      </PrimaryContent>
    </ContentLayout>
  );
}
