export interface AuthJwtResult {
  tokenType: string;
  expiresIn: number;
  accessToken: string;
  refreshToken: string;
}

export interface AuthUserCompanyRole {
  companyId: string;
  permission: string[];
}

export interface AuthUser {
  id: string;
  username: string;
  email: string;
  role: string[];
  userCompanyRole: AuthUserCompanyRole[];
}

const AUTH_URL = import.meta.env.VITE_AUTH_URL as string;

class AuthClient {
  async current(accessToken: string): Promise<AuthUser> {
    const res = await fetch(`${AUTH_URL}/current`, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    });

    if (res.ok) {
      return (await res.json()) as AuthUser;
    } else {
      throw new Error(res.statusText);
    }
  }

  async login(username: string, password: string): Promise<AuthJwtResult> {
    const res = await fetch(`${AUTH_URL}/direct/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username,
        password,
      }),
    });

    if (!res.ok) {
      throw new Error(res.statusText);
    }

    return (await res.json()) as AuthJwtResult;
  }

  async refreshToken(refreshToken: string): Promise<AuthJwtResult | null> {
    const res = await fetch(`${AUTH_URL}/direct/refreshToken`, {
      method: 'POST',
      headers: {
        authorization: `Bearer ${refreshToken}`,
      },
    });

    if (res.ok) {
      return (await res.json()) as AuthJwtResult;
    } else {
      if (res.status === 401) {
        return null;
      } else {
        throw new Error(res.statusText);
      }
    }
  }

  async logout(refreshToken: string): Promise<AuthJwtResult> {
    const res = await fetch(`${AUTH_URL}/direct/logout`, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        refresh_token: refreshToken,
      }),
    });

    if (res.ok) {
      return (await res.json()) as AuthJwtResult;
    } else {
      throw new Error(res.statusText);
    }
  }
}

export const authClient = new AuthClient();
