import { useMutation } from '@apollo/client';
import { graphql } from '@client/graphql/types';

const QUERY = graphql(`
  mutation FetchBranchCoordinate($branchID: ID!) {
    automaticFetchBranchCoordinate(branchID: $branchID)
  }
`);

export function useFetchBranchCoordinate() {
  return useMutation(QUERY);
}
