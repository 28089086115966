import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import moment from 'moment';
import { useTranslate } from "@tolgee/react";

interface Item {
  title: string;
  stat: string;
  previousStat: string;
  change: string;
  changeType: string;
}

export default function TableReservationAnalyticDynamicChange(props: {
  dailyCount: { date: string; count: number }[];
}) {
  const { t } = useTranslate();

  const items = [
    aggregateData(props.dailyCount, 'day'),
    aggregateData(props.dailyCount, 'week'),
    aggregateData(props.dailyCount, 'month'),
  ];

  return (
    <dl
      className="mt-5 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-3 md:divide-x md:divide-y-0">
      {items.map((item) => (
        <div key={item.title} className="px-4 py-5 sm:p-6">
          <dt className="text-base font-normal text-gray-900">
            {t('reservation.report.dynamic-change.' + item.title)}
          </dt>
          <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
            <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
              {item.stat}
              <span className="ml-2 text-sm font-medium text-gray-500">
                {t('reservation.report.from')} {item.previousStat}
              </span>
            </div>

            <div
              className={classNames(
                item.changeType === 'increase'
                  ? 'bg-green-100 text-green-800'
                  : item.changeType === 'decrease'
                    ? 'bg-red-100 text-red-800'
                    : 'bg-gray-400 text-white',
                'inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0',
              )}
            >
              {item.changeType === 'increase' ? (
                <ArrowUpIcon
                  className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500"
                  aria-hidden="true"
                />
              ) : item.changeType === 'decrease' ? (
                <ArrowDownIcon
                  className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-red-500"
                  aria-hidden="true"
                />
              ) : (
                <div> Empty data </div>
              )}

              <span className="sr-only">
                {' '}
                {item.changeType === 'increase'
                  ? 'Increased'
                  : 'Decreased'} by{' '}
              </span>
              {item.change}
            </div>
          </dd>
        </div>
      ))}
    </dl>
  );
}

function aggregateData(
  dailyCount: { date: string; count: number }[],
  period: 'day' | 'week' | 'month',
): Item {
  const REFERENCE = moment(); // fixed just for testing, use moment();

  const TODAY_START = REFERENCE.clone().startOf('day');
  const TODAY_END = REFERENCE.clone().endOf('day');

  const YESTERDAY_START = REFERENCE.clone().subtract(1, 'days').startOf('day');
  const YESTERDAY_END = REFERENCE.clone().subtract(1, 'days').endOf('day');

  const CURRENT_WEEK_START = REFERENCE.clone().startOf('week').startOf('day');
  const CURRENT_WEEK_END = REFERENCE.clone().endOf('week').startOf('day');

  const PREVIOUS_WEEK_START = REFERENCE.clone()
    .subtract(1, 'week')
    .startOf('week')
    .startOf('day');
  const PREVIOUS_WEEK_END = REFERENCE.clone()
    .subtract(1, 'week')
    .endOf('week')
    .startOf('day');

  const PREVIOUS_MONTH_START = REFERENCE.clone()
    .subtract(1, 'month')
    .startOf('month')
    .startOf('day');
  const PREVIOUS_MONTH_END = REFERENCE.clone()
    .subtract(1, 'month')
    .endOf('month')
    .startOf('day');

  const CURRENT_MONTH_START = REFERENCE.clone().startOf('month').startOf('day');
  const CURRENT_MONTH_END = REFERENCE.clone().endOf('month').startOf('day');

  let previousTotal = 0;
  let currentTotal = 0;

  for (const record of dailyCount) {
    const date = moment(record.date).startOf('day');

    switch (period) {
      case 'day':
        if (date.isBetween(TODAY_START, TODAY_END, undefined, '[]')) {
          currentTotal += record.count;
        } else if (
          date.isBetween(YESTERDAY_START, YESTERDAY_END, undefined, '[]')
        ) {
          previousTotal += record.count;
        }
        break;
      case 'week':
        if (
          date.isBetween(CURRENT_WEEK_START, CURRENT_WEEK_END, undefined, '[]')
        ) {
          currentTotal += record.count;
        } else if (
          date.isBetween(
            PREVIOUS_WEEK_START,
            PREVIOUS_WEEK_END,
            undefined,
            '[]',
          )
        ) {
          previousTotal += record.count;
        }
        break;
      case 'month':
        if (
          date.isBetween(
            CURRENT_MONTH_START,
            CURRENT_MONTH_END,
            undefined,
            '[]',
          )
        ) {
          currentTotal += record.count;
        } else if (
          date.isBetween(
            PREVIOUS_MONTH_START,
            PREVIOUS_MONTH_END,
            undefined,
            '[]',
          )
        ) {
          previousTotal += record.count;
        }
        break;
    }
  }

  let changeType = '';
  let change = '';

  if (currentTotal == 0) {
    changeType = 'decrease';
    change = '100%';
  } else {
    if (currentTotal > previousTotal) {
      changeType = 'increase';
      change =
        (((currentTotal - previousTotal) / currentTotal) * 100)
          .toFixed(2)
          .toString() + '%';
    }
    if (currentTotal < previousTotal) {
      changeType = 'decrease';
      change =
        (((currentTotal - previousTotal) / currentTotal) * 100)
          .toFixed(2)
          .toString() + '%';
    }
  }

  let title: string;
  switch (period) {
    case 'day':
      title = 'analytic.today-yesterday';
      break;
    case 'week':
      title = 'analytic.this-week-prev-week';
      break;
    case 'month':
      title = 'analytic.this-month-prev-month';
      break;
  }

  return {
    title: title,
    stat: currentTotal.toString(),
    previousStat: previousTotal.toString(),
    change: change,
    changeType: changeType,
  };
}
