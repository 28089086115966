import { HomeIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { useTranslate } from '@tolgee/react';
import { Link } from 'react-router-dom';

export interface BreadcrumbPage {
  id: string;
  name: string;
  to: string;
  current: boolean;
}

export default function Breadcrumb(props: { pages: BreadcrumbPage[] }) {
  const pages = props.pages;
  const { t } = useTranslate();

  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol role="list" className="flex space-x-4 rounded-md bg-white px-6 shadow">
        <li className="flex">
          <div className="flex items-center">
            <div className="text-gray-400 hover:text-gray-500">
              <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
              <span className="sr-only">Home</span>
            </div>
          </div>
        </li>

        {/* Desktop */}
        <div className="hidden md:inline-flex">
          {pages.map((page) => (
            <li key={page.id} className="flex">
              <div className="flex items-center">
                <svg
                  className="h-full w-6 flex-shrink-0 text-gray-200"
                  viewBox="0 0 24 44"
                  preserveAspectRatio="none"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                </svg>
                <Link
                  to={page.to}
                  className={classNames('ml-4 text-sm font-medium text-gray-500 hover:text-black', {
                    'text-gray-900': page.current,
                  })}
                >
                  {t(page.name)}
                </Link>
              </div>
            </li>
          ))}
        </div>

        {/* Mobile */}
        <div className="md:hidden">
          {pages.slice(-1).map((page) => (
            <li key={page.id} className="flex">
              <div className="flex items-center">
                <svg
                  className="h-full w-6 flex-shrink-0 text-gray-200"
                  viewBox="0 0 24 44"
                  preserveAspectRatio="none"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                </svg>
                <Link
                  to={page.to}
                  className={classNames('ml-4 text-sm font-medium text-gray-500 hover:text-black', {
                    'text-gray-900': page.current,
                  })}
                >
                  {t(page.name)}
                </Link>
              </div>
            </li>
          ))}
        </div>
      </ol>
    </nav>
  );
}
