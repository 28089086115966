import { BellIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import useGetUserNotification from '@client/page/management/user-notificartion/user-notification-list/logic/use-get-user-notifications.ts';
import { useCompanyId } from '@packages/core/company/focused-company-context.ts';
import moment from 'moment';
import { useLocalStorage } from 'usehooks-ts';

const UserNotificationButton = () => {
  const navigate = useNavigate();
  const companyID = useCompanyId();

  const { data } = useGetUserNotification(companyID);

  const [newNotification, setNewNotification] = useState<boolean>(false);

  const [latestAccessTime, setLatestAccessTime] = useLocalStorage<string | undefined>('latestAccessTime', undefined, {
    serializer: (value) => value ?? moment().toISOString(),
    deserializer: (value) => value,
  });

  useEffect(() => {
    const notifications = data?.userNotifications ?? [];

    if (latestAccessTime) {
      if (notifications.length > 0) {
        const notification = notifications[0];
        if (moment(parseInt(notification.createdAt)) > moment(latestAccessTime)) {
          setNewNotification(true);
        } else {
          setNewNotification(false);
        }
      }
    }
  }, [data, latestAccessTime, setNewNotification]);

  useEffect(() => {
    if (latestAccessTime === undefined) {
      setLatestAccessTime(moment().toISOString());
    }
  }, [latestAccessTime, setLatestAccessTime]);

  return (
    <button
      type="button"
      className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500 relative"
      onClick={() => {
        navigate('/user-notification');
        setNewNotification(false);
      }}
    >
      <div className={classNames({ 'h-3 w-3 bg-red-600 rounded-full absolute right-2.5 top-2': newNotification })}></div>
      <BellIcon className="h-6 w-6" aria-hidden="true" />
    </button>
  );
};

export default UserNotificationButton;
