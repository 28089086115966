import { UIMatch, useMatches } from 'react-router-dom';
import Breadcrumb, { BreadcrumbPage } from '@packages/ui/breadcrumb.tsx';

export interface CrumbData {
  crumb?: (data: unknown) => string;
}

const AppBreadcrumb = () => {
  const matches = useMatches() as UIMatch<unknown, CrumbData | undefined>[];

  const pages: BreadcrumbPage[] = matches
    .filter((match) => Boolean(match.handle?.crumb))
    .map((match) => {
      return {
        id: match.pathname,
        name: match.handle?.crumb?.(match.data) ?? 'Unknown',
        to: match.pathname,
        current: true,
      };
    });

  if (pages.length === 0) {
    return <></>;
  } else {
    return (
      <div className="max-md:hidden">
        <Breadcrumb pages={pages} />
      </div>
    );
  }
};

export default AppBreadcrumb;
