import { UserCompanyPermission } from '@client/graphql/types/graphql.ts';
import { AuthUser } from '@client/module/auth/auth-client.ts';

export default function checkPermission(
  permission: UserCompanyPermission | undefined,
  companyID: string,
  authUser: AuthUser | null
): boolean {
  if (!permission) {
    return true;
  }

  if (authUser?.role.includes('ADMIN')) {
    return true;
  }

  const companyPermission = authUser?.userCompanyRole.find((role) => {
    return role.companyId === companyID;
  });

  if (companyPermission) {
    if (companyPermission.permission.includes(permission)) {
      return true;
    }
  }

  return false;
}
