import { OnlineOrderListItemFragment, OnlineOrderState } from '@client/graphql/types/graphql.ts';
import CurrencyView from '@packages/core/utils/currency-view.tsx';
import { useTranslate } from "@tolgee/react";

const OnlineOrderDailySummarize = (props: { order: readonly OnlineOrderListItemFragment[] }) => {
  // Total completed orders for today
  const totalCompletedOrders = props.order.filter((order) => order.state === OnlineOrderState.Done).length;
  const inProgressOrders = props.order.filter((order) => order.state === OnlineOrderState.InProgress).length;
  const canceledOrders = props.order.filter((order) => order.state === OnlineOrderState.Canceled).length;

  const sumTotalCompletedOrders = props.order
    .filter((order) => order.state === OnlineOrderState.Done)
    .reduce((acc, order) => acc + (order.totalPrice ?? 0), 0);
  const { t } = useTranslate();
  return (
    <div className="px-6 py-2 flex flex-col space-y-0">
      <div className="text-lg text-gray-700">
        <span className="">
          <CurrencyView
            price={sumTotalCompletedOrders} /> / {totalCompletedOrders} {t('online-order.dashboard.daily-summarize.completed')}
        </span>
      </div>
      <div>
        <span className="text-sm text-gray-500">
          {inProgressOrders}
          {t('online-order.dashboard.daily-summarize.in-progress')} / {canceledOrders}
          {t('online-order.dashboard.daily-summarize.cancelled')}
        </span>
      </div>
    </div>
  );
};

export default OnlineOrderDailySummarize;
