import InvoiceDetail from '@client/page/management/invoice/invoice-detail/invoice-detail.tsx';
import InvoiceListPage from '@client/page/management/invoice/invoice-list/invoice-list-page.tsx';

export const InvoiceRouter = {
  path: 'invoices',
  children: [
    { path: '', element: <InvoiceListPage />},
    {
      path: ':invoiceId',
      element: <InvoiceDetail />,
    },
  ],
};
