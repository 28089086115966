import Card from 'ui/src/card/card';
import CardHeader from 'ui/src/card/card-header';
import { ContentLayout, PrimaryContent } from 'ui/src/content-layout';
import CardTableContent from '@packages/ui/card/card-content-table.tsx';
import { Helmet } from 'react-helmet-async';
import BannerTable from './component/banner-table';
import CreateBannerButton from './component/create-banner-button';

export default function BannerList() {
  return (
      <ContentLayout>
        <Helmet>
          <title>Banner</title>
          <meta
            name="description"
            content="This page displays a list of active banners and its setting"
          />
        </Helmet>
        <PrimaryContent>
          <div className="flex flex-col gap-6">
            <Card>
              <CardHeader title="Active banners">
                <CreateBannerButton />
              </CardHeader>
              <CardTableContent>
                <BannerTable />
              </CardTableContent>
            </Card>
          </div>
        </PrimaryContent>
      </ContentLayout>
  );
}
