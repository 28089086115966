import { ApolloError } from '@apollo/client';
import AlertError from '@packages/ui/alert/alert-error.tsx';
import { ReactNode } from 'react';

const ApplicationErrorView = (props: { className?: string; error: ApolloError | undefined; children?: ReactNode }) => {
  if (!props.error) {
    return <div className="hidden" />;
  }

  return (
    <AlertError className={props.className} title="Error" message={props.error.message}>
      {props.children}
    </AlertError>
  );
};

export default ApplicationErrorView;
