import { RouteObject } from 'react-router-dom';
import OnlineOrderDashboardSinglePage from '@client/page/management/online-order/dashboard/online-order-dashboard-single-page.tsx';
import OnlineOrderHistory from '@client/page/management/online-order/history/online-order-history.tsx';
import { OpeningTimeRouter } from '@client/page/management/opening-time/opening-time.router.tsx';
import OnlineOrderBranchSettingPage from './setting/online-order-branch-setting-page.tsx';
import OnlineOrderDetail from './detail/online-order-detail.tsx';

export const OnlineOrderRouter: RouteObject = {
  path: 'online-order',
  handle: {},
  children: [
    {
      path: 'dashboard',
      children: [
        {
          path: '',
          element: <OnlineOrderDashboardSinglePage />,
        },
        {
          path: ':onlineOrderID',
          element: <OnlineOrderDetail />,
        },
      ],
    },
    {
      path: 'history',
      children: [
        {
          path: '',
          element: <OnlineOrderHistory />,
        },
        {
          path: ':onlineOrderID',
          element: <OnlineOrderDetail />,
        },
      ],
    },
    {
      path: 'setting',
      children: [
        {
          index: true,
          element: <OnlineOrderBranchSettingPage />,
        },
        OpeningTimeRouter,
      ],
    },
  ],
};
