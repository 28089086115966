import { OnlineOrderDashboard } from '@client/page/management/online-order/dashboard/online-order-dashboard.tsx';
import { useNavigate, useSearchParams } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
import { useCallback } from 'react';
import SecondaryButton from '@packages/ui/button/secondary-button.tsx';
import { useTranslate } from "@tolgee/react";
import TableReservationList from '@client/page/management/table-reservation/list/table-reservation-list.tsx';
import classNames from 'classnames';
import { UserCompanyPermission } from '@client/graphql/types/graphql.ts';
import { DatePicker } from 'antd';
import { useFocusedCompany } from '@packages/core/company/focused-company-context.ts';
import checkPermission from '@client/module/utils/check-permission.ts';
import { useAuth } from '@client/module/auth/auth-context.ts';

function Dashboard() {
  const navigate = useNavigate();
  const { auth } = useAuth();
  const company = useFocusedCompany();

  const { t } = useTranslate();
  const [params, setParams] = useSearchParams();

  const startTime = dayjs(params.get('startTime') ?? dayjs())
    .tz(company.settings.timezone)
    .startOf('day');
  const endTime = dayjs(params.get('endTime') ?? dayjs())
    .tz(company.settings.timezone)
    .endOf('day');

  const setTime = useCallback(
    (start: Dayjs, end: Dayjs) => {
      params.set('startTime', start.toISOString());
      params.set('endTime', end.toISOString());
      setParams(params);
    },
    [params, setParams]
  );

  const previousDayHandler = () => {
    const target = startTime.subtract(1, 'day');
    setTime(target.startOf('day'), target.endOf('day'));
  };

  const nextDayHandler = () => {
    const target = startTime.add(1, 'day');
    setTime(target.startOf('day'), target.endOf('day'));
  };

  return (
    <div>
      <div className="flex space-x-2 items-center justify-center">
        <SecondaryButton onClick={previousDayHandler}>{t('common.previous-day')}</SecondaryButton>
        <div className="flex-grow" />

        <SecondaryButton
          className="hidden md:block"
          onClick={() => {
            const today = dayjs().tz(company.settings.timezone);
            setTime(today.startOf('day'), today.endOf('day'));
          }}
        >
          {t('common.today')}
        </SecondaryButton>

        <div className="">
          <DatePicker
            allowClear={false}
            variant={'outlined'}
            value={startTime}
            format="DD-MM-YYYY"
            onChange={(date) => {
              setTime(date.startOf('day'), date.endOf('day'));
            }}
          />
        </div>

        <div className="flex-grow" />
        <SecondaryButton onClick={nextDayHandler}>{t('common.next-day')}</SecondaryButton>
      </div>

      <div className={classNames('flex flex-col xl:flex-row xl:flex py-4 gap-4 items-start')}>
        {checkPermission(UserCompanyPermission.ManageOnlineOrder, company.id, auth) && (
          <div className="flex-grow w-full">
            <OnlineOrderDashboard
              navigationPrefix="online-order"
              startTime={startTime}
              endTime={endTime}
              // ignore={[OnlineOrderState.Canceled, OnlineOrderState.Done]}
              footer={
                <>
                  <SecondaryButton
                    onClick={() => {
                      navigate('/online-order/history');
                    }}
                  >
                   {t('online-order.dashboard.order-history', 'Order history')}
                  </SecondaryButton>
                </>
              }
            />
          </div>
        )}
        {checkPermission(UserCompanyPermission.ManageTableReservation, company.id, auth) && (
          <div className="flex-grow w-full">
            <TableReservationList
              navigationPrefix={'table-reservation'}
              withTitle={true}
              startTime={startTime}
              endTime={endTime}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Dashboard;
