import PreviewBrowser from '@client/page/management/banner/banner-preview/component/preview-browser.tsx';
import PreviewBrowserPlaceholderContent from './component/preview-browser-placeholder-content.tsx';

export function TopBannerPreview(props: {
  content: string;
  backgroundColour: string;
  foregroundColour: string;
}) {
  return (
    <div className="flex flex-col ml-4 space-y-4 h-full relative">
      <PreviewBrowser>
        <div className="bg-gray-200">
          <div
            className="flex justify-center [&_a]:underline "
            style={{ backgroundColor: props.backgroundColour }}
          >
            <span style={{ color: props.foregroundColour }}>
              {props.content}
            </span>
          </div>
        </div>

        <PreviewBrowserPlaceholderContent />
      </PreviewBrowser>
    </div>
  );
}
