import ToggleInput from 'ui/src/form/input/toggle-input';
import {
  useFocusedBranchContext,
} from '@client/page/management/table-group/table-group-list/component/branch-selection/focused-branch-context.ts';
import { useCallback, useEffect } from 'react';
import { useDialog } from '@packages/ui/modal/use-dialog.tsx';
import { useLocalStorage } from 'usehooks-ts';
import { useNotificationCenter } from 'ui/src/notification/notification-center';
import { useTranslate } from "@tolgee/react";
import useTableReservationSetting from '../logic/use-table-reservation-setting';
import useUpdateTableReservationSetting from '../logic/use-update-table-reservation-setting';


export default function PauseToggleInput() {
  const branchProvider = useFocusedBranchContext();
  const branchId = branchProvider.branch?.id ?? '';

  const dialog = useDialog();
  const { showAlert } = useNotificationCenter();

  const { data } = useTableReservationSetting(branchId);
  const [update] = useUpdateTableReservationSetting();
  const [pause, setPause] = useLocalStorage<boolean>('isTableReservationPaused', false);
  const { t } = useTranslate();

  useEffect(() => {
    if (data) {
      setPause(data.tableReservationConfiguration.pause);
    }
  }, [data, setPause]);

  const handlePauseInput = useCallback(
    (newValue: boolean) => {
      if (!data) {
        return;
      }

      const message = pause
        ? 'Are you sure you want to resume the table reservation?'
        : 'Are you sure you want to pause the table reservation?';

      const reuseUpdate = () => {
        return update({
          variables: {
            id: data.tableReservationConfiguration.id,
            input: {
              pause: newValue,
            },
          },
        })
          .then(() => {
            setPause(newValue);
            showAlert({
              status: 'success',
              title: 'Success',
              message: 'Action has been performed',
            });
          })
          .catch((e: Error) => {
            showAlert({
              status: 'error',
              title: 'Error',
              message: e.message,
            });
          });
      };

      if (!newValue) {
        void reuseUpdate();
        return;
      }

      dialog.confirmAlert({
        title: 'Pause',
        message: message,
        textButton: 'Yes',
        onConfirm: reuseUpdate,
      });
    },
    [data, pause, dialog, update, setPause, showAlert],
  );

  return (
    <div className="flex gap-x-2 border py-1 px-2 rounded-md items-center">
      <p className="font-semibold text-sm">{t('common.toggle.pause')}</p>
      <ToggleInput
        value={pause}
        onChange={(newValue) => {
          handlePauseInput(newValue);
        }}
      />
    </div>
  );
}
