import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AlertError from '@packages/ui/alert/alert-error.tsx';
import { useAuth } from '@client/module/auth/auth-context.ts';
import CheckBoxInput from 'ui/src/form/input/check-box-input';

export default function SignIn() {
  const navigate = useNavigate();
  const authContext = useAuth();
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  useEffect(() => {
    if (authContext.auth) {
      navigate('/');
    }
  }, [authContext, navigate]);

  const handleSubmit = useCallback(
    (email: string, password: string) => {
      setLoading(true);

      authContext
        .login(email, password)
        .then(() => {
          navigate('/');
        })
        .catch((e) => {
          // Login failed.
          if (e instanceof Error) {
            const message = e.message;
            setError(message);
          } else {
            setError('Unknown error');
          }
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [authContext, navigate]
  );

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img className="mx-auto h-12 w-auto" src="./icon.png" alt="Digital InHouse" />
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6">
            <div>
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  disabled={loading}
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300
                   placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                  Password
                </label>
              </div>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type={!showPassword ? 'password' : 'text'}
                  disabled={loading}
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  autoComplete="current-password"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300
                   placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />

                <div className="flex gap-2 mt-2 text-sm italic">
                  <CheckBoxInput
                    value={showPassword}
                    onChange={(_, newValue) => {
                      setShowPassword(newValue);
                    }}
                  />
                  <label htmlFor="vehicle1">Show your password</label>
                </div>
              </div>
            </div>

            {error != null && <AlertError title="Oops!" message="Username or password is wrong" />}

            <div>
              <button
                type="submit"
                disabled={loading}
                onClick={(e) => {
                  e.preventDefault();
                  handleSubmit(email, password);
                }}
                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6
                 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2
                 focus-visible:outline-indigo-600"
              >
                Sign in
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
