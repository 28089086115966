import { useQuery } from '@apollo/client';
import { graphql } from '@client/graphql/types';

const USER_COMPANY_ROLE_COUNT = graphql(`
  query UserCompanyRolesCount($companyID: ID!) {
    userCompanyRolesCount(companyID: $companyID)
  }
`);

export default function useUserCompanyRoleCount(companyId: string) {
  return useQuery(USER_COMPANY_ROLE_COUNT, {
    variables: {
      companyID: companyId,
    },
  });
}
