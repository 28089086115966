import { useCompanies } from '@packages/core/company/hook/use-companies.ts';
import Loading from '@packages/ui/loading.tsx';
import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import CardTableContent from '@packages/ui/card/card-content-table.tsx';
import Table from '@packages/ui/table/table.tsx';
import TableRow from '@packages/ui/table/table-row.tsx';
import TableRowColumn from '@packages/ui/table/table-row-cell.tsx';
import TableHeaderColumn from '@packages/ui/table/table-header-column.tsx';
import { ReactNode } from 'react';

const CompanySelection = (props: { onSelect: (companyID: string) => void; header?: ReactNode }) => {
  const { data, loading, error } = useCompanies(0, 50);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    throw error;
  }

  if (!data) {
    return <div>No companies found</div>;
  }

  return (
    <div className="container max-w-4xl  mx-auto px-4 py-8">
      <Card>
        <CardHeader title="Company">{props.header}</CardHeader>
        <CardTableContent>
          <Table
            data={data.companies}
            build={(item) => {
              return (
                <TableRow key={item.id}>
                  <TableRowColumn>
                    <div
                      onClick={() => {
                        props.onSelect(item.id);
                      }}
                    >
                      {item.name}
                    </div>
                  </TableRowColumn>
                </TableRow>
              );
            }}
            loading={loading}
            error={error}
          >
            <TableHeaderColumn>Name</TableHeaderColumn>
          </Table>
        </CardTableContent>
      </Card>
    </div>
  );
};

export default CompanySelection;
