import { ApolloClient, HttpLink, InMemoryCache, split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { Kind, OperationTypeNode } from 'graphql';
import { setContext } from '@apollo/client/link/context';
import { clearAuthStatus, getValidAccessToken } from '@client/module/auth/auth.ts';
import { onError } from '@apollo/client/link/error';
import { SentryLink } from 'apollo-link-sentry';

const httpLink = new HttpLink({
  uri: import.meta.env.VITE_GRAPHQL_URL as string,
});

const wsLink = new GraphQLWsLink(
  createClient({
    shouldRetry: () => true,
    url: import.meta.env.VITE_GRAPHQL_WS as string,
  })
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === Kind.OPERATION_DEFINITION && definition.operation === OperationTypeNode.SUBSCRIPTION;
  },
  wsLink,
  httpLink
);

let accessTokenPromise: Promise<string | null> | null = null;

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  if (!accessTokenPromise) {
    accessTokenPromise = getValidAccessToken(3).then((token) => {
      accessTokenPromise = null;
      return token;
    });
  }

  const accessToken = await accessTokenPromise;

  if (!accessToken) {
    return {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      headers: headers,
    };
  }

  // return the headers to the context so httpLink can read them
  return {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    headers: {
      ...headers,
      authorization: `Bearer ${accessToken}`,
    },
  };
});

const errorHandlerLink = onError(({ networkError, graphQLErrors }) => {
  if (graphQLErrors?.some((error) => error.message === 'Unauthorized')) {
    clearAuthStatus();
  }

  if (networkError) {
    if (networkError.name === 'UnauthorizedError') {
      clearAuthStatus();
    }
  }
});

const sentryLink = new SentryLink({});

export const client = new ApolloClient({
  link: errorHandlerLink.concat(authLink.concat(splitLink.concat(sentryLink))),
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache',
    },
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
  },
});
