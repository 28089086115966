import { graphql } from '@packages/core/graphql/types';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import {
  Compression,
  convertImage,
} from '@packages/core/uploading/image-processing.ts';

const GET_PRESIGN_UPLOAD = graphql(`
  mutation GetPreSignUpload($filename: String!) {
    preSignImageUpload(filename: $filename) {
      origin
      originPath
      thumbnail
      thumbnailPath
    }
  }
`);

export async function uploadImage(
  client: ApolloClient<NormalizedCacheObject>,
  file: File
) {
  const preSignResponse = await client.mutate({
    mutation: GET_PRESIGN_UPLOAD,
    variables: { filename: file.name },
  });

  if (preSignResponse.errors) {
    throw Object.assign(new Error('Pre-sign upload failed'), {
      errors: preSignResponse.errors,
    });
  }

  const headers = {
    'x-amz-acl': 'public-read',
  };

  if (!preSignResponse.data) {
    throw new Error('Pre-sign upload failed');
  }

  await Promise.all([
    fetch(preSignResponse.data.preSignImageUpload.origin, {
      method: 'PUT',
      headers: headers,
      body: await convertImage(file, Compression.high),
    }),
    fetch(preSignResponse.data.preSignImageUpload.thumbnail, {
      method: 'PUT',
      headers: headers,
      body: await convertImage(file, Compression.low),
    }),
  ]);

  return preSignResponse;
}
