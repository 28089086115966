import { graphql } from '@client/graphql/types';
import { useMutation } from '@apollo/client';

const MUTATION = graphql(`
  mutation UpdateBranchTableBookingConfiguration(
    $id: ID!
    $input: TableReservationConfigurationUpdateInput!
  ) {
    updateTableReservationConfiguration(id: $id, input: $input) {
      id
      hourBlocking
      defaultBookingTimeDuration
      status
      pause
      autoConfirm
      ignoreStatus
      notificationMails
      phone
    }
  }
`);

export default function useUpdateTableReservationSetting() {
  return useMutation(MUTATION, {});
}
