import { Radio, RadioGroup } from '@headlessui/react';
import classNames from 'classnames';

export interface CompactRadioData<T> {
  name: string;
  available?: boolean;
  value: T;
}

export function CompactRadioInput<T>(props: {
  options: CompactRadioData<T>[];
  value: T;
  onChange: (value: T) => void;
}) {
  const { options, value, onChange } = props;

  return (
    <RadioGroup value={value} onChange={onChange} className="mt-2 grid grid-cols-3 gap-3 sm:grid-cols-6">
      {options.map((option) => (
        <Radio
          key={option.name}
          value={option.value}
          className={({ focus, checked }) =>
            classNames(
              option.available ?? true ? 'cursor-pointer focus:outline-none' : 'cursor-not-allowed opacity-25',
              focus ? 'ring-2 ring-indigo-600 ring-offset-2' : '',
              checked
                ? 'bg-indigo-600 text-white hover:bg-indigo-500'
                : 'bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50',
              'flex items-center justify-center rounded-md px-2 py-1.5 text-sm font-semibold sm:flex-1'
            )
          }
          disabled={!(option.available ?? true)}
        >
          {option.name}
        </Radio>
      ))}
    </RadioGroup>
  );
}
