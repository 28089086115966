import { useMutation } from '@apollo/client';
import { graphql } from '@client/graphql/types';

const MUTATION = graphql(`
  mutation UserCompanyRoleCreate(
    $companyID: ID!
    $input: UserCompanyRoleCreate!
  ) {
    userCompanyRoleCreate(companyID: $companyID, input: $input) {
      password
      userCompanyRole{
        user {
          id
          firstName
          lastName
          username
          email
          status
        }
      }
    }
  }
`);

export default function useUserCompanyRoleCreate() {
  return useMutation(MUTATION, {});
}
