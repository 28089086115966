import { ReactNode } from 'react';
import { CompanyContext } from '@packages/core/company/company-context.ts';
import { useCompanyService } from '@packages/core/company/company-hook.ts';

export function CompanyProvider(props: { children: ReactNode }) {
  const companyService = useCompanyService();

  return (
    <CompanyContext.Provider value={companyService}>
      {props.children}
    </CompanyContext.Provider>
  );
}
