import { useMutation } from '@apollo/client';
import { graphql } from '@client/graphql/types';

const MUTATION = graphql(`
  mutation UserCompanyRoleUpdate(
    $companyID: ID!
    $input: UserCompanyRoleUpdate!
  ) {
    userCompanyRoleUpdate(companyID: $companyID, input: $input) {
      id
    }
  }
`);

export default function useUserCompanyRoleUpdate() {
  return useMutation(MUTATION, {});
}
