import { TableReservationConfirmationStatus, TableReservationItemFragment } from '@client/graphql/types/graphql.ts';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { TableReservationIcon } from '@client/page/management/table-reservation/list/component/reservation-table-list.tsx';
import { useNotificationCenter } from '@packages/ui/notification/notification-center.ts';
import useUpdateConfirmTableReservation from '@client/page/management/table-reservation/operative/logic/use-update-confirm.ts';
import { useTranslate } from "@tolgee/react";

export function ReservationTableListConfirmationStatus(props: { item: TableReservationItemFragment }) {
  const notificationCenter = useNotificationCenter();
  const [updateConfirmationStatus] = useUpdateConfirmTableReservation();

  const onUpdateStatusHandler = (status: TableReservationConfirmationStatus) => {
    updateConfirmationStatus({
      variables: {
        id: props.item.id,
        status: status,
      },
    })
      .then(() => {
        notificationCenter.showAlert({
          status: 'success',
          message: 'Update success!',
          title: 'Update reservation',
        });
      })
      .catch((error: Error) => {
        notificationCenter.showAlert({
          status: 'error',
          message: 'Update failed! ' + error.message,
          title: 'Update reservation',
        });
      });
  };

  const { t } = useTranslate();
  return (
    <Menu>
      <MenuButton
        className={
          'flex justify-center items-center gap-1 focus:outline-none rounded-md bg-white font-semibold' +
          ' text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 px-2 py-1 text-sm'
        }
      >
        <TableReservationIcon status={props.item.confirmationStatus} />
      </MenuButton>

      <MenuItems
        anchor="bottom start"
        className={
          'focus:outline-none absolute min-w-28 mt-1 z-50 rounded-md border border-gray-300  p-1 text-sm/6 bg-white'
        }
      >
        <MenuItem>
          <span
            onClick={() => {
              onUpdateStatusHandler(TableReservationConfirmationStatus.Confirmed);
            }}
            className="flex px-2 py-2 gap-1 hover:bg-gray-200"
          >
            <TableReservationIcon status={TableReservationConfirmationStatus.Confirmed} />
            <p className="font-semibold">{t('common.confirmation.confirm')} </p>
          </span>
        </MenuItem>
        <MenuItem>
          <span
            onClick={() => {
              onUpdateStatusHandler(TableReservationConfirmationStatus.Cancelled);
            }}
            className="flex px-2 py-2 gap-1 hover:bg-gray-200"
          >
            <TableReservationIcon status={TableReservationConfirmationStatus.Cancelled} />
            <p className="font-semibold">{t('common.confirmation.cancel')}</p>
          </span>
        </MenuItem>
        <MenuItem>
          <span
            onClick={() => {
              onUpdateStatusHandler(TableReservationConfirmationStatus.Waiting);
            }}
            className="flex px-2 py-2 gap-1 hover:bg-gray-200"
          >
            <TableReservationIcon status={TableReservationConfirmationStatus.Waiting} />
            <p className="font-semibold">{t('common.confirmation.waiting')}</p>
          </span>
        </MenuItem>
      </MenuItems>
    </Menu>
  );
}
