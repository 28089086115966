import useDeleteMenu from '@client/page/management/menu/menu-detail/logic/use-delete-menu.ts';
import { useDialog } from '@packages/ui/modal/use-dialog.tsx';
import { useNavigate } from 'react-router-dom';
import DestroyButton from '@packages/ui/button/destroy-button';
import CardSection from '@packages/ui/card/card-section';
import { useCallback } from 'react';
import { useTranslate } from "@tolgee/react";

export default function MenuDetailDeleteButton(props: { menuId: string }) {
  const navigate = useNavigate();
  const dialog = useDialog();
  const [deleteMenu] = useDeleteMenu();
  const { t } = useTranslate();


  const onDestroy = useCallback(() => {
    dialog.destructAlert({
      title: t('menu.menu-detail.extra-action.delete.alert.title'),
      message: t('menu.menu-detail.extra-action.delete.alert.message'),
      textButton: t('menu.menu-detail.extra-action.delete.alert.text-button'),
      onConfirm: async () => {
        await deleteMenu({ variables: { id: props.menuId } });
        navigate('..');
      },
    });
  }, [deleteMenu, dialog, navigate, props.menuId, t]);
  return (
    <div className="flex space-x-4">
      <CardSection
        title={t('menu.menu-detail.extra-action.delete.title')}
        descriptions={t('menu-detail.delete-description')}
      >
        <DestroyButton data-testid="delete-button" onClick={onDestroy}>
          {t('menu.menu-detail.extra-action.delete.button')}
        </DestroyButton>
      </CardSection>
    </div>
  );
}
