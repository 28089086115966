import { graphql } from '@client/graphql/types';
import { useQuery } from '@apollo/client';
import {  OnlineOrderFilter } from '@client/graphql/types/graphql';

const QUERY = graphql(`
  query OnlineOrderHistory($branchID: ID!, $filter : OnlineOrderFilter!) {
    onlineOrdersHistory(branchID: $branchID, filter: $filter) {
      ...OnlineOrderListItem
    }
  }
`);

export default function useOnlineOrdersHistory(branchId: string, filter: OnlineOrderFilter){
  return useQuery(QUERY, {
    variables: {
      branchID: branchId,
      filter : filter
    },
  });
}
