import { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMenuProduct } from '@client/page/management/menu/menu-product-edit/logic/use-menu-product';
import { useUpdateMenuProduct } from '@client/page/management/menu/menu-product-edit/logic/use-update-menu-product';
import { formatGraphQlError, validationErrors } from '@client/module/error/error';
import { getFragmentData } from '@client/graphql/types';
import CardContent from '@packages/ui/card/card-content';
import Center from '@packages/ui/center';
import Spinner from '@packages/ui/spinner';
import FormLayout from '@packages/ui/form/form-layout';
import TextInput from '@packages/ui/form/input/text-input';
import FormItem from '@packages/ui/form/form-item';
import Card from '@packages/ui/card/card';
import CardHeader from '@packages/ui/card/card-header';
import AlertError from '@packages/ui/alert/alert-error';
import CardFooter from '@packages/ui/card/card-footer';
import SecondaryButton from '@packages/ui/button/secondary-button';
import PrimaryButton from '@packages/ui/button/primary-button';
import useFormData from '@client/module/utils/use-form-data';
import ToggleInput from '@packages/ui/form/input/toggle-input';
import ProductMenuDelete from '@client/page/management/menu/menu-product-edit/components/product-menu-delete';
import { MenuProductFragment } from '@packages/network-graphql/common/menu-product-fragment.ts';
import MenuSectionSelection
  from '@client/page/management/menu/menu-section/menu-section-selection-input/menu-section-selection.tsx';
import { ContentLayout, PrimaryContent } from 'ui/src/content-layout';
import ImageInput from '@packages/core/uploading/image-input.tsx';
import { client } from '@client/graphql/client.ts';
import { useTranslate } from "@tolgee/react";
import { Helmet } from 'react-helmet-async';
import MenuProductLabelSelect
  from '@client/page/management/menu/menu-product-create/component/menu-product-label-select.tsx';
import { AllergicType, MenuProductLabel } from '@client/graphql/types/graphql';
import MenuProductConfigurationListContent
  from '../menu-product-configuration-list/component/menu-product-configuration-list-content';
import AllergicSelection from './components/allergic-selection';

interface FormData {
  title: string;
  code: string;
  description: string;
  images: string[];
  enabled: boolean;
  ingredients: string[];
  sectionId: string | null | undefined;
  labels: MenuProductLabel[];
  allergics: AllergicType[];
}

const DESCRIPTION_MAX_LENGTH = 200;

export default function MenuProductEdit() {
  const productId = useParams().productId ?? '';
  const navigate = useNavigate();

  const {
    data: form,
    setData,
    handle,
    manualHandle,
  } = useFormData<FormData>({
    title: '',
    description: '',
    images: [''],
    ingredients: [''],
    enabled: false,
    sectionId: null,
    code: '000',
    allergics: [],
    labels: [],
  });

  const { data, loading, error: loadingError } = useMenuProduct(productId);

  const [update, { loading: updating, error: updateError }] = useUpdateMenuProduct();

  const error = loadingError ?? updateError;
  const applicationErrors = formatGraphQlError(error?.graphQLErrors);
  const validationError = validationErrors(applicationErrors);

  const menuProduct = getFragmentData(MenuProductFragment, data?.menuProduct);
  const { t } = useTranslate();

  useEffect(() => {
    if (menuProduct) {
      setData({
        enabled: menuProduct.enabled,
        title: menuProduct.title,
        description: menuProduct.description,
        images: menuProduct.images,
        ingredients: menuProduct.ingredients,
        sectionId: menuProduct.sectionId,
        code: menuProduct.code,
        allergics: menuProduct.allergics ?? [],
        labels: menuProduct.labels ?? [],
      });
    }
  }, [menuProduct, setData]);

  const submit = useCallback(() => {
    update({ variables: { id: productId, input: form } })
      .then(() => {
        navigate('..');
      })
      .catch((e) => void e);
  }, [productId, form, navigate, update]);

  if (!menuProduct) {
    if (loading) {
      return (
        <Center>
          <Spinner />
        </Center>
      );
    } else {
      return <div>Product not found</div>;
    }
  }
  return (
    <ContentLayout>
      <Helmet>
        <title> {t('menu.menu-detail.product.edit.helmet.title')}</title>
        <meta name="description" content="This page allows you to edit a menu"></meta>
      </Helmet>
      <PrimaryContent>
        <div className="space-y-4 ">
          <Card>
            <CardHeader withBackButton={true} title={t('menu.menu-detail.product.edit.header')} />

            <CardContent>
              <FormLayout>
                <FormItem title={t('menu.menu-detail.product.edit.form-input.code.title')} className="max-w-md">
                  <TextInput
                    data-testid="code-input"
                    type="text"
                    label="code"
                    value={form.code}
                    name="code"
                    placeholder={t('menu.menu-detail.product.edit.form-input.code.placeholder')}
                    error={validationError.code}
                    onChange={handle}
                  />
                </FormItem>

                <FormItem title={t('menu.menu-detail.product.edit.form-input.name.title')} className="max-w-md">
                  <TextInput
                    data-testid="title-input"
                    type="text"
                    label="title"
                    value={form.title}
                    name="title"
                    placeholder={t('menu.menu-detail.product.edit.form-input.name.placeholder')}
                    error={validationError.title}
                    onChange={handle}
                  />
                </FormItem>
                <FormItem
                  title={t('menu.menu-detail.product.edit.form-input.description.title')}
                  subtitle={`(${String(form.description.length)}/${String(DESCRIPTION_MAX_LENGTH)})`}
                  className="max-w-md"
                >
                  <textarea
                    className="w-full p-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-200"
                    placeholder={t('menu.menu-detail.product.edit.form-input.description.placeholder')}
                    value={form.description}
                    name="description"
                    onChange={(event) => {
                      manualHandle('description', event.target.value);
                    }}
                    maxLength={DESCRIPTION_MAX_LENGTH}
                  />
                </FormItem>

                <div className="max-w-md">
                  <MenuSectionSelection
                    menuId={menuProduct.menuId}
                    value={form.sectionId}
                    onChange={(sectionId) => {
                      manualHandle('sectionId', sectionId);
                    }}
                  />
                </div>

                <FormItem title={t('menu.menu-detail.product.edit.form-input.label.title')} className="max-w-md">
                  <MenuProductLabelSelect
                    label={form.labels}
                    handleLabel={(values, enabled) => {
                      if (enabled) {
                        manualHandle('labels', [...form.labels, values]);
                      } else {
                        manualHandle(
                          'labels',
                          form.labels.filter((label) => label !== values),
                        );
                      }
                    }}
                  />
                </FormItem>

                <FormItem title={t('menu.menu-detail.product.edit.form-input.allergic.title')} className="w-lg">
                  <AllergicSelection
                    allergics={form.allergics}
                    onChange={(newValue) => {
                      manualHandle('allergics', newValue);
                    }}
                  />
                </FormItem>

                <div>
                  <ImageInput
                    client={client}
                    images={form.images}
                    onChange={(newValue) => {
                      manualHandle('images', newValue);
                    }}
                    multiple={true}
                  />
                  <p className="text-red-500 text-sm mt-2">{validationError.images}</p>
                </div>

                <FormItem title={t(t('menu.menu-detail.product.edit.form-input.active-toggle.title'))} className="w-32">
                  <ToggleInput
                    value={form.enabled}
                    onChange={(newValue) => {
                      manualHandle('enabled', newValue);
                    }}
                    data-testid="product-active-toggle"
                  />
                </FormItem>

                {error && <AlertError className="mt-4" title={error.name} message={error.message} />}
              </FormLayout>
            </CardContent>

            <CardFooter>
              <SecondaryButton
                className="w-32"
                onClick={() => {
                  navigate('..');
                }}
              >
                {t('common.button.cancel')}
              </SecondaryButton>
              <PrimaryButton
                className="w-32"
                data-testid="update-button"
                loading={loading || updating}
                onClick={submit}
              >
                {t('common.button.update')}
              </PrimaryButton>
            </CardFooter>
          </Card>

          <MenuProductConfigurationListContent />

          <Card>
            <CardHeader title={t('menu.menu-detail.product.edit.extra-action.header')} />
            <CardContent>
              <ProductMenuDelete productId={productId} />
            </CardContent>
          </Card>
        </div>
      </PrimaryContent>
    </ContentLayout>
  );
}
