import {
  FocusedBranchContext,
  useFocusedBranchContext,
  useFocusedBranchProvider,
} from '@client/page/management/table-group/table-group-list/component/branch-selection/focused-branch-context.ts';
import { ReactNode } from 'react';
import Loading from '@packages/ui/loading.tsx';
import DialogAlert from '@packages/ui/dialog-alert.tsx';
import { DialogTitle } from '@headlessui/react';
import PrimaryButton from '@packages/ui/button/primary-button.tsx';
import { captureException } from '@sentry/react';
import FocusedBranchSelection from '@client/page/management/table-group/table-group-list/component/branch-selection/focused-branch-selection.tsx';

export default function FocusedBranch(props: { children?: ReactNode }) {
  const provider = useFocusedBranchProvider();

  return (
    <FocusedBranchContext.Provider value={provider}>
      <BranchProtection>{props.children}</BranchProtection>
    </FocusedBranchContext.Provider>
  );
}

function BranchProtection({ children }: { children: ReactNode }) {
  const { branch, loading, error, refetch } = useFocusedBranchContext();

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return (
      <DialogAlert>
        <div className="flex flex-col space-y-4 items-center">
          <DialogTitle className="text-xl font-semibold">Error</DialogTitle>
          <p className="line-clamp-4">{error.message}</p>
          <PrimaryButton
            onClick={() => {
              refetch().catch(captureException);
            }}
          >
            Reload
          </PrimaryButton>
        </div>
      </DialogAlert>
    );
  }

  if (!branch) {
    return <FocusedBranchSelection />;
  }

  return children;
}
