import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import CardTableContent from '@packages/ui/card/card-content-table.tsx';
import { useTableReservations } from '@client/page/management/table-reservation/list/logic/use-table-reservations.ts';
import { ReactNode, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { useFocusedBranchContext } from '@client/page/management/table-group/table-group-list/component/branch-selection/focused-branch-context.ts';
import CardFooter from '@packages/ui/card/card-footer.tsx';
import { useTranslate } from "@tolgee/react";
import SecondaryButton from '@packages/ui/button/secondary-button.tsx';
import TableReservationHistoryTableList from './component/reservation-history-table-list';
import TableReservationListStats from './component/table-reservation-list-stats';

const TableReservationHistory = (props: {
  initialStartTime: Dayjs;
  initialEndTime: Dayjs;
  filter: (data: { startTime: Dayjs; endTime: Dayjs; onChange: (dates: [Dayjs, Dayjs]) => void }) => ReactNode;
  footer?: (data: { startTime: Dayjs; endTime: Dayjs; onChange: (dates: [Dayjs, Dayjs]) => void }) => ReactNode;
}) => {
  const branchProvider = useFocusedBranchContext();
  const branchID = branchProvider.branch?.id ?? '';
  const { t } = useTranslate();

  const [startTime, setStartTime] = useState<Dayjs>(props.initialStartTime);
  const [endTime, setEndTime] = useState<Dayjs>(props.initialEndTime);

  const { data: reservations, loading } = useTableReservations(branchID, {
    startTime: startTime.format('YYYY-MM-DDTHH:mm'),
    endTime: endTime.format('YYYY-MM-DDTHH:mm'),
  });

  const handleQuickPick = (type: 'currentMonth' | 'lastMonth' | 'last7Days') => {
    let newStartTime, newEndTime;
    const today = dayjs();

    switch (type) {
      case 'currentMonth':
        newStartTime = today.startOf('month');
        newEndTime = today.endOf('month');
        break;
      case 'lastMonth':
        newStartTime = today.subtract(1, 'month').startOf('month');
        newEndTime = today.subtract(1, 'month').endOf('month');
        break;
      case 'last7Days':
        newStartTime = today.subtract(7, 'days').startOf('day');
        newEndTime = today.endOf('day');
        break;
    }

    setStartTime(newStartTime);
    setEndTime(newEndTime);
  };

  return (
    <Card>
      <CardHeader title={t('table-reservation-list.header')} hideTitle={true}>
        <SecondaryButton
          onClick={() => {
            handleQuickPick('currentMonth');
          }}
        >
          {t('common.button.current-month')}
        </SecondaryButton>
        <SecondaryButton
          onClick={() => {
            handleQuickPick('lastMonth');
          }}
        >
          {t('common.button.last-month')}
        </SecondaryButton>
        <SecondaryButton
          onClick={() => {
            handleQuickPick('last7Days');
          }}
        >
          {t('common.button.last-7-days')}
        </SecondaryButton>
        {props.filter({
          startTime: startTime,
          endTime: endTime,
          onChange: (dates) => {
            setStartTime(dates[0]);
            setEndTime(dates[1]);
          },
        })}
      </CardHeader>

      <CardTableContent>
        <TableReservationListStats loading={loading} items={reservations} />
        <TableReservationHistoryTableList loading={loading} items={reservations} />
      </CardTableContent>

      {props.footer && (
        <CardFooter>
          {props.footer({
            startTime: startTime,
            endTime: endTime,
            onChange: (dates) => {
              setStartTime(dates[0]);
              setEndTime(dates[1]);
            },
          })}
        </CardFooter>
      )}
    </Card>
  );
};

export default TableReservationHistory;
