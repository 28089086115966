import { useMutation } from '@apollo/client';
import { graphql } from '@client/graphql/types';

const UPDATE_COMPANY_QUERY = graphql(`
  mutation updateCompany($companyID: ID!, $input: UpdateCompanyInput!) {
    updateCompany(companyID: $companyID, input: $input) {
      id
      logo
      name
    }
  }
`);

export function useUpdateCompany() {
  return useMutation(UPDATE_COMPANY_QUERY, {});
}
