import classNames from 'classnames';

const productEnabled: Record<string, string> = {
  false: 'text-gray-400 bg-gray-400/10 ring-gray-400/20',
  true: 'text-green-400 bg-indigo-400/10 ring-indigo-400/30',
};

const DotStatus = (props: { enabled: boolean }) => {
  return (
    <div
      className={classNames(
        productEnabled[props.enabled.toString()],
        'h-4 w-4 flex-none rounded-full p-1'
      )}
    >
      <div className="h-2 w-2 rounded-full bg-current" />
    </div>
  );
};

export default DotStatus;
