import { ContentLayout, PrimaryContent } from '@packages/ui/content-layout.tsx';
import { Helmet } from 'react-helmet-async';
import CompanyBasicSettings from '@client/page/management/company-settings/company-basic-settings.tsx';
import CompanyAdvancedSettings from '@client/page/management/company-settings/company-advanced-settings.tsx';

export default function CompanySettings() {
  return (
    <ContentLayout>
      <Helmet>
        <title>Setting</title>
        <meta
          name="description"
          content="This page displays a list of company settings"
        />
      </Helmet>
      <PrimaryContent>
        <CompanyBasicSettings />
        <CompanyAdvancedSettings />
      </PrimaryContent>
    </ContentLayout>
  );
}
