import { TimeRuleType, TimeRuleUnion } from '@client/graphql/types/graphql.ts';
import useFormData from '@client/module/utils/use-form-data.ts';
import { useEffect } from 'react';

interface TimeRuleForm {
  timeRuleType: TimeRuleType;
  start: string | undefined;
  end: string | undefined;
}

export function useTimeRuleForm(initialData?: TimeRuleUnion) {
  const form = useFormData<TimeRuleForm>({
    timeRuleType: TimeRuleType.Always,
    start: '',
    end: '',
  });

  useEffect(() => {
    if (initialData) {
      if (initialData.__typename === 'RangeTimeRule') {
        form.setData({
          timeRuleType: initialData.type ?? TimeRuleType.Always,
          start: initialData.start ?? undefined,
          end: initialData.end ?? undefined,
        });
      } else if (initialData.__typename === 'AlwaysTimeRule') {
        form.setData({
          timeRuleType: TimeRuleType.Always,
          start: undefined,
          end: undefined,
        });
      }
    }
  }, [form, initialData]);

  return {
    ...form,

    setTimeRuleType: (timeRuleType: TimeRuleType) => {
      switch (timeRuleType) {
        case TimeRuleType.Always:
          form.setData({
            timeRuleType: TimeRuleType.Always,
            start: undefined,
            end: undefined,
          });
          break;
        case TimeRuleType.RangeTime:
          form.setData({
            timeRuleType: TimeRuleType.RangeTime,
            start: undefined,
            end: undefined,
          });
          break;
      }
    },
  };
}
