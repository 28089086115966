import { ReactNode } from 'react';
import classNames from 'classnames';

export const ContentLayout = (props: { className?: string; children?: ReactNode }) => {
  return <div className={classNames('xl:flex space-x-4 space-y-4 items-start', props.className)}>{props.children}</div>;
};

export const FullPrimaryContent = (props: { children?: ReactNode }) => {
  return <div className="w-full space-y-4">{props.children}</div>;
};

export const PrimaryContent = (props: { children?: ReactNode }) => {
  return <div className="xl:w-2/3 space-y-4">{props.children}</div>;
};

export const SecondaryContent = (props: { children?: ReactNode }) => {
  return <div className="xl:w-1/3 ">{props.children}</div>;
};
