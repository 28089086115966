import {
  MenuProductConfiguration,
  MenuProductConfigurationItemFragment,
  MenuProductConfigurationValue,
  ProductConfigurationType,
} from '@client/graphql/types/graphql';
import { useCompanyCurrency } from 'core/src/company/focused-company-context';
import SecondaryButton from 'ui/src/button/secondary-button';
import Card from 'ui/src/card/card';
import CardTableContent from 'ui/src/card/card-content-table';
import CardHeader from 'ui/src/card/card-header';
import Table from 'ui/src/table/table';
import TableHeaderColumn from 'ui/src/table/table-header-column';
import { TableSection } from 'ui/src/table/table-section';
import { TableCellAlignment } from 'ui/src/table/table-type';
import { getFragmentData } from '@client/graphql/types';
import { MenuProductConfigurationValueFragment } from 'network-graphql/src/common/menu-product-configuration-value-fragment';
import TableRow from 'ui/src/table/table-row';
import TableRowColumn from 'ui/src/table/table-row-cell';
import { formatCurrency } from 'core/src/utils/currency-formatter';
import PrimaryButton from 'ui/src/button/primary-button';
import { useEffect, useState } from 'react';
import { useModalManagerContext } from 'ui/src/modal/modal-manager-context';
import { formatProductConfigurationType } from '../../menu-product-configuration-edit/logic/format-product-configuration-type';
import MenuProductConfigurationCreate from './menu-product-configuration-create';
import MenuProductConfigurationValueCreate from './menu-product-configuration-value-create';
import MenuProductConfigurationUpdate from './menu-product-configuration-update';
import MenuProductConfigurationValueUpdate from './menu-product-configuration-value-update';

interface MenuProductConfigurationWithoutValues {
  id: string;
  title: string;
  type: ProductConfigurationType;
}

export default function MenuProductConfigurationCreateTable(props: {
  data: MenuProductConfiguration[];
  configurationHandle: (value: unknown) => void;
}) {
  // Hooks
  const dialogManager = useModalManagerContext();
  const [configList, setConfigList] = useState<MenuProductConfiguration[]>(props.data);

  useEffect(() => {
    props.configurationHandle(configList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configList, props.configurationHandle]);

  // Configlist Array action Method
  const addConfiguration = (newConfig: MenuProductConfiguration) => {
    setConfigList([...configList, newConfig]);
  };

  const editConfiguration = (
    id: string,
    action: 'update' | 'delete',
    updateItem?: MenuProductConfigurationWithoutValues
  ) => {
    if (action == 'delete') {
      setConfigList(configList.filter((item) => item.id !== id));
      return;
    }
    if (!updateItem) {
      return;
    }
    setConfigList(
      configList.map((item) => {
        if (item.id == id) {
          item = {
            ...item,
            ...updateItem,
          };
        }
        return item;
      })
    );
  };

  const addConfigurationValue = (configurationID: string, newConfigValue: MenuProductConfigurationValue) => {
    setConfigList(
      configList.map((item) => {
        if (item.id == configurationID) {
          item.values.push(newConfigValue);
        }
        return item;
      })
    );
  };

  const editConfigurationValue = (
    configurationID: string,
    id: string,
    action: 'update' | 'delete',
    updateItem?: MenuProductConfigurationValue
  ) => {
    if (action == 'delete') {
      setConfigList(
        configList.map((item) => {
          if (item.id == configurationID) {
            item.values = item.values.filter((valueItem) => valueItem.id !== id);
          }
          return item;
        })
      );
      return;
    }

    if (!updateItem) {
      return;
    }

    setConfigList(
      configList.map((item) => {
        if (item.id == configurationID) {
          item.values = item.values.map((valueItem) => {
            if (valueItem.id == id) {
              valueItem = updateItem;
            }
            return valueItem;
          });
        }
        return item;
      })
    );
  };

  // Dialog method
  const showCreateConfigurationModal = () => {
    dialogManager.showDialog((onClose) => (
      <MenuProductConfigurationCreate onClose={onClose} onCreate={addConfiguration} />
    ));
  };

  const showCreateConfigurationValueModal = (configurationID: string) => {
    dialogManager.showDialog((onClose) => (
      <MenuProductConfigurationValueCreate
        onClose={onClose}
        onCreate={addConfigurationValue}
        configurationID={configurationID}
      />
    ));
  };

  const showUpdateConfigurationModal = (data: MenuProductConfigurationWithoutValues) => {
    dialogManager.showDialog((onClose) => (
      <MenuProductConfigurationUpdate data={data} onClose={onClose} onUpdate={editConfiguration} />
    ));
  };

  const showUpdateConfigurationValueModal = (configurationID: string, data: MenuProductConfigurationValue) => {
    dialogManager.showDialog((onClose) => (
      <MenuProductConfigurationValueUpdate
        configurationID={configurationID}
        data={data}
        onClose={onClose}
        onUpdate={editConfigurationValue}
      />
    ));
  };

  return (
    <>
      <Card>
        <CardHeader title="Product variation price">
          <SecondaryButton onClick={showCreateConfigurationModal}>Add configuration</SecondaryButton>
        </CardHeader>

        <CardTableContent>
          <Table
            data={props.data}
            build={(item) => (
              <ConfigurationBuilder
                key={item.id}
                configuration={item}
                showCreateConfigurationValueModal={showCreateConfigurationValueModal}
                showUpdateConfigurationModal={showUpdateConfigurationModal}
                showUpdateConfigurationValueModal={showUpdateConfigurationValueModal}
              />
            )}
          >
            <TableHeaderColumn>Name</TableHeaderColumn>
            <TableHeaderColumn alignment={TableCellAlignment.Right}>Price</TableHeaderColumn>
            <TableHeaderColumn alignment={TableCellAlignment.Right}>Actions</TableHeaderColumn>
          </Table>
        </CardTableContent>
      </Card>
    </>
  );
}

function ConfigurationBuilder(props: {
  configuration: MenuProductConfigurationItemFragment;
  showCreateConfigurationValueModal: (configurationID: string) => void;
  showUpdateConfigurationModal: (data: MenuProductConfigurationWithoutValues) => void;
  showUpdateConfigurationValueModal: (configurationID: string, data: MenuProductConfigurationValue) => void;
}) {
  const currency = useCompanyCurrency();
  const { configuration } = props;

  return (
    <>
      <TableSection colSpan={4}>
        <div className="flex items-center space-x-1">
          <p>{configuration.title.length > 0 ? configuration.title : 'Default configuration'}</p>
          <p>- {formatProductConfigurationType(configuration.type)}</p>
          <div className="flex-grow" />
          <div className="flex space-x-2">
            <SecondaryButton
              onClick={() => {
                props.showCreateConfigurationValueModal(props.configuration.id);
              }}
            >
              Add price
            </SecondaryButton>
            <SecondaryButton
              onClick={() => {
                props.showUpdateConfigurationModal(props.configuration);
              }}
            >
              Edit
            </SecondaryButton>
          </div>
        </div>
      </TableSection>

      {configuration.values
        .map((e) => getFragmentData(MenuProductConfigurationValueFragment, e))
        .map((value) => {
          return (
            <TableRow key={value.id}>
              <TableRowColumn>{value.name.length > 0 ? value.name : 'Default price'}</TableRowColumn>
              <TableRowColumn alignment={TableCellAlignment.Right}>
                {formatCurrency(value.price, currency)}
              </TableRowColumn>
              <TableRowColumn alignment={TableCellAlignment.Right}>
                <div className="space-x-4">
                  <PrimaryButton
                    onClick={() => {
                      props.showUpdateConfigurationValueModal(props.configuration.id, value);
                    }}
                  >
                    Edit
                  </PrimaryButton>
                </div>
              </TableRowColumn>
            </TableRow>
          );
        })}
    </>
  );
}
