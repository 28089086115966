import {
  OnlineOrderBranchSettingItemFragment,
  OnlineOrderConfirmationState,
  OnlineOrderDeliveryMode,
  OnlineOrderItemFragment,
  OnlineOrderState,
  OnlineOrderStateEventItemFragment,
} from '@client/graphql/types/graphql.ts';
import { CompactRadioInput } from '@packages/ui/form/input/compact-radio-input.tsx';
import useFormData from '@client/module/utils/use-form-data.ts';
import Card from '@packages/ui/card/card.tsx';
import CardContent from '@packages/ui/card/card-content.tsx';
import CardFooter from '@packages/ui/card/card-footer.tsx';
import FormLayout from '@packages/ui/form/form-layout.tsx';
import FormItem from '@packages/ui/form/form-item.tsx';
import { formatCurrency } from '@packages/core/utils/currency-formatter.ts';
import { useCompanyCurrency, useFocusedCompany } from '@packages/core/company/focused-company-context.ts';
import CardHeader from '@packages/ui/card/card-header.tsx';
import DescriptionItem from '@packages/ui/description/description-item.tsx';
import moment from 'moment-timezone';
import { getFragmentData } from '@client/graphql/types';
import { OnlineOrderStateEventFragment } from '@client/graphql/online-order-fragment.ts';
import OnlineOrderActionConfirm
  from '@client/page/management/online-order/detail/component/online-order-action-confirm.tsx';
import OnlineOrderActionDecline
  from '@client/page/management/online-order/detail/component/online-order-action-decline.tsx';
import OnlineOrderActionCancel
  from '@client/page/management/online-order/detail/component/online-order-action-cancel.tsx';
import { capitalize } from 'lodash';
import OnlineOrderActionDone from '@client/page/management/online-order/detail/component/online-order-action-done.tsx';
import { useCallback } from 'react';
import { useNotificationCenter } from 'ui/src/notification/notification-center';
import CurrencyView from '@packages/core/utils/currency-view.tsx';
import { useTranslate } from "@tolgee/react";
import useChangeDeliveryPrice from '../logic/use-change-delivery-price';

const preparingTimeBlock = [10, 15, 20, 30, 40, 45, 60, 90];

interface Form {
  preparingTime: number;
  deliveryFee: number;
}

const OnlineOrderAction = (props: {
  order: OnlineOrderItemFragment;
  compact?: boolean;
  configuration: OnlineOrderBranchSettingItemFragment;
  onClose?: () => void;
}) => {
  const compact = props.compact ?? false;
  const { t } = useTranslate();

  const { order, configuration, onClose } = props;
  // Hooks
  const currency = useCompanyCurrency();
  const [updateDeliveryPrice] = useChangeDeliveryPrice();
  const { showAlert } = useNotificationCenter();
  const { data, manualHandle } = useFormData<Form>({
    preparingTime: configuration.defaultPreparingTime,
    deliveryFee: order.deliveryPrice ?? 0,
  });

  const events = order.events.map((e) => getFragmentData(OnlineOrderStateEventFragment, e));

  const handleDeliveryPrice = useCallback(
    (value: number) => {
      updateDeliveryPrice({
        variables: {
          id: order.id,
          deliveryPrice: value,
        },
      })
        .then(() => {
          showAlert({
            status: 'success',
            title: 'Success',
            message: 'Your online order delivery fee is update',
          });
        })
        .catch((e: Error) => {
          showAlert({
            status: 'error',
            title: 'Error',
            message: e.message,
          });
        });
    },
    [order.id, showAlert, updateDeliveryPrice],
  );

  const display = (
    <>
      {!compact && (
        <DescriptionItem title={t('online-order.dashboard.order-status.dialog.amount')}>
          <CurrencyView price={order.totalPrice} />
        </DescriptionItem>
      )}

      <DescriptionItem title={t('online-order.dashboard.order-status.dialog.accept-time')}>
        <PreparingTime events={events} />
      </DescriptionItem>

      <DescriptionItem title={t('online-order.dashboard.order-status.dialog.prepare-time')}>
        <span>{data.preparingTime} minutes</span>
      </DescriptionItem>

      {(order.state === OnlineOrderState.Done || order.state === OnlineOrderState.Canceled) && (
        <DescriptionItem title={t('online-order.dashboard.order-status.dialog.finish-time')}>
          <FinishTime events={events} />
        </DescriptionItem>
      )}
    </>
  );

  const form = (
    <FormLayout>
      <FormItem title={t('online-order.dashboard.order-status.dialog.prepare-time-in-minute')}>
        <CompactRadioInput
          options={preparingTimeBlock.map((time) => ({
            name: time.toString(),
            value: time,
          }))}
          value={data.preparingTime}
          onChange={(value) => {
            if (order.confirmationState === OnlineOrderConfirmationState.Pending) {
              manualHandle('preparingTime', value);
            }
          }}
        />
      </FormItem>

      {order.deliveryMode == OnlineOrderDeliveryMode.Delivery && (
        <FormItem title="Delivery price">
          <CompactRadioInput
            options={
              configuration.deliveryTableData?.map((data) => ({
                name: `<${(data.deliveryDistance / 1000).toFixed(2)} km ${formatCurrency(data.deliveryFee, currency)}`,
                value: data.deliveryFee,
              })) ?? []
            }
            value={data.deliveryFee}
            onChange={(value) => {
              if (order.confirmationState === OnlineOrderConfirmationState.Pending) {
                manualHandle('deliveryFee', value);
                handleDeliveryPrice(value);
              }
            }}
          />
        </FormItem>
      )}
    </FormLayout>
  );

  const pendingActions = (
    <>
      <OnlineOrderActionDecline orderID={order.id} onClose={props.onClose} />
      <div className="flex-grow" />
      <OnlineOrderActionConfirm orderID={order.id} preparingTime={data.preparingTime} onClose={props.onClose} />
    </>
  );

  const inProgressActions = (
    <>
      <OnlineOrderActionCancel orderID={order.id} onClose={props.onClose} />
      <div className="flex-grow" />
      <OnlineOrderActionDone orderID={order.id} onClose={props.onClose} />
    </>
  );

  return (
    <Card>
      <CardHeader
        title={t('online-order.dashboard.order-status.dialog.title')}
        onCrossButton={
          onClose &&
          (() => {
            onClose();
          })
        }
      ></CardHeader>
      <CardContent>
        {!compact && <DescriptionItem
          title={t('online-order.dashboard.order-status.dialog.status')}>{capitalize(order.state.replace(/_/g, ' '))}</DescriptionItem>}

        {order.confirmationState === OnlineOrderConfirmationState.Pending ? form : display}
      </CardContent>

      {order.state !== OnlineOrderState.Done && order.state !== OnlineOrderState.Canceled && (
        <CardFooter>
          {order.confirmationState === OnlineOrderConfirmationState.Pending && pendingActions}
          {order.state === OnlineOrderState.InProgress && inProgressActions}
        </CardFooter>
      )}
    </Card>
  );
};

const PreparingTime = (props: { events: readonly OnlineOrderStateEventItemFragment[] }) => {
  const { settings } = useFocusedCompany();

  const waitingForConfirmationEvent = props.events.find((event) => event.state === OnlineOrderState.InProgress);

  if (waitingForConfirmationEvent) {
    return <>{moment(waitingForConfirmationEvent.timestamp).tz(settings.timezone).format('HH:mm')}</>;
  }
};

const FinishTime = (props: { events: readonly OnlineOrderStateEventItemFragment[] }) => {
  const { settings } = useFocusedCompany();

  const waitingForConfirmationEvent = props.events.find(
    (event) => event.state === OnlineOrderState.Done || event.state === OnlineOrderState.Canceled,
  );

  if (waitingForConfirmationEvent) {
    return <>{moment(waitingForConfirmationEvent.timestamp).tz(settings.timezone).format('HH:mm')}</>;
  }
};

export default OnlineOrderAction;
