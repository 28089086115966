import { useMutation } from '@apollo/client';
import { graphql } from '@client/graphql/types';

const UPDATE_OPENING_TIME = graphql(`
  mutation UpdateOpeningTime($id: ID!, $input: UpdateOpeningTimeData!) {
    updateOpeningTime(id: $id, input: $input) {
        id
        branchId
        dayOfWeek
        openTime
        closeTime
        note
        isActive
    }
  }
`);

export function useUpdateOpeningTime() {
  return useMutation(UPDATE_OPENING_TIME, {
    refetchQueries: ['OpeningTimes'],
  });
}
