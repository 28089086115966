import { useMutation } from '@apollo/client';
import { graphql } from '@client/graphql/types';

const MUTATION = graphql(`
    mutation ResetUserPassword($userID: ID!, $companyID: ID!){
        resetUserPassword(userID: $userID, companyID: $companyID)
    }
`)

export default function useResetUserPassword(){
    return useMutation(MUTATION,{});
}