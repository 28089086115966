import { graphql } from '@client/graphql/types';

export const OpeningTimeFragment = graphql(`
  fragment OpeningTimeItem on OpeningTime {
    id
    branchId
    dayOfWeek
    openTime
    closeTime
    note
    isActive
  }
`);
