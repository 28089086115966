import { graphql } from '@client/graphql/types';
import { useMutation } from '@apollo/client';

const MUTATION = graphql(`
  mutation CreateTableBooking($branchId: ID!, $input: TableReservationCreate!) {
    createTableReservation(branchId: $branchId, input: $input) {
      id
      platformType
      tableId
      guestName
      status
      message
      startTime
      seats
    }
  }
`);

export default function useCreateTableBooking() {
  return useMutation(MUTATION, {});
}
