import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import CardContent from 'ui/src/card/card-content';
import FormLayout from 'ui/src/form/form-layout';
import { useEffect } from 'react';
import { useCompanyId } from 'core/src/company/focused-company-context';
import CardFooter from 'ui/src/card/card-footer';
import PrimaryButton from 'ui/src/button/primary-button';
import { useCompanyService } from '@packages/core/company/company-context.ts';
import { useTranslate } from "@tolgee/react";
import FormItem from 'ui/src/form/form-item';
import TextInput from 'ui/src/form/input/text-input';
import { client } from '@client/graphql/client.ts';
import ImageInput from 'core/src/uploading/image-input';
import useFormData from '@client/module/utils/use-form-data';
import { useNotificationCenter } from '@packages/ui/notification/notification-center.ts';
import { captureException } from '@sentry/react';
import { useCompanySettings } from './logic/use-company-setting';
import { useUpdateCompany } from './logic/use-update-company-profile';

interface CompanyUpdateInput {
  name: string;
  logo: string | null;
}

export default function CompanyBasicSettings() {
  const { t } = useTranslate();
  const companyID = useCompanyId();
  const companyService = useCompanyService();
  const notificationCenter = useNotificationCenter();

  const { data } = useCompanySettings(companyID);
  const [updateCompanyProfile] = useUpdateCompany();

  const {
    data: form,
    handle,
    setData,
    manualHandle,
  } = useFormData<CompanyUpdateInput>({
    name: '',
    logo: null,
  });

  useEffect(() => {
    setData({
      name: companyService.company?.name ?? '',
      logo: companyService.company?.logo ?? null,
    });
  }, [
    companyService.company?.logo,
    companyService.company?.name,
    data,
    setData,
  ]);

  const onUpdateHandler = () => {
    updateCompanyProfile({
      variables: {
        companyID: companyService.companyID ?? '',
        input: {
          name: form.name,
          logo: form.logo,
        },
      },
    })
      .then(() => {
        location.reload();
      })
      .catch((e) => {
        notificationCenter.showAlert({
          title: 'Update error',
          status: 'error',
          message: 'Something went wrong. Please try again later.',
        });

        captureException(e);
      });
  };

  return (
    <Card>
      <CardHeader title={'General'} />

      <CardContent>
        <FormLayout>
          <FormItem title={t('common.company-name')}>
            <TextInput
              type="text"
              label="id"
              value={form.name}
              name="name"
              placeholder={t('common.company-name-placeholder')}
              onChange={handle}
            />
          </FormItem>

          <FormItem title={''} className="max-w-md">
            <ImageInput
              client={client}
              images={form.logo ? [form.logo] : []}
              onChange={(newValue) => {
                if (newValue.length > 0) {
                  manualHandle('logo', newValue[0]);
                } else {
                  manualHandle('logo', null);
                }
              }}
            />
          </FormItem>
        </FormLayout>
      </CardContent>

      <CardFooter>
        <div className="flex justify-center space-x-4 w-32">
          <PrimaryButton
            id="create-button"
            data-testid="create-button"
            className="w-32 flex-1"
            onClick={() => {
              onUpdateHandler();
            }}
          >
            {t('common.update')}
          </PrimaryButton>
        </div>
      </CardFooter>
    </Card>
  );
}
