import { GeneralDeleteButton } from '@client/page/component/general-delete-button.tsx';
import { useNavigate } from 'react-router-dom';
import { useTranslate } from '@tolgee/react';
import useBannerDelete from '../logic/use-banner-delete';

export default function BannerDeleteButton(props: { bannerId: string }) {
  const navigate = useNavigate();
  const [deleteBranch] = useBannerDelete();
  const { t } = useTranslate();
  return (
    <GeneralDeleteButton
      alert={{
        title: t('banner-delete-alert.title'),
        message: t('banner-delete-alert.message'),
      }}
      card={{
        title: t('banner-delete-card.title'),
        descriptions: t('banner-delete-card.descriptions'),
        buttonTitle: t('banner-delete-card.button-title'),
      }}
      delete={async () => {
        await deleteBranch({ variables: { bannerId: props.bannerId } });
        navigate('..');
      }}
    />
  );
}
