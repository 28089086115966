import { Outlet } from 'react-router-dom';
import { CompanyProvider } from '@packages/core/company/company-provider.tsx';

export default function Root() {
  return (
    <CompanyProvider>
      <Outlet />
    </CompanyProvider>
  );
}
