import {
  CompanyContext,
  useCompanyService,
} from '@packages/core/company/company-context.ts';
import { useContext } from 'react';

export function useFocusedCompanyContext() {
  const context = useCompanyService();

  if (!context.company) {
    throw new Error(
      'useFocusedCompanyContext must be used within a CompanyContextProvider'
    );
  }

  return context.company;
}

export function useCompanyCurrency() {
  const companyContext = useFocusedCompanyContext();
  return companyContext.settings.currency;
}

export function useCompanyId() {
  const companyContext = useFocusedCompanyContext();
  return companyContext.id;
}

export function useFocusedCompany() {
  const context = useContext(CompanyContext);

  if (context === undefined) {
    throw new Error(
      'useCompanyContext must be used within a CompanyContextProvider'
    );
  }

  if (!context.company) {
    throw new Error('Company is not set in CompanyContextProvider');
  }

  return context.company;
}
