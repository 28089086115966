import PrimaryButton from 'ui/src/button/primary-button';
import Card from 'ui/src/card/card';
import CardContent from 'ui/src/card/card-content';
import CardHeader from 'ui/src/card/card-header';
import FormItem from 'ui/src/form/form-item';
import FormLayout from 'ui/src/form/form-layout';
import TextInput from 'ui/src/form/input/text-input';
import CardFooter from 'ui/src/card/card-footer';
import SecondaryButton from 'ui/src/button/secondary-button';
import useFormData from '@client/module/utils/use-form-data';
import { useNotificationCenter } from 'ui/src/notification/notification-center';
import { v4 as uuidv4 } from 'uuid';
import { OnlineOrderDelivery } from '@client/page/management/online-order/setting/model/online-order-delivery.model.ts';
import { currencySymbol, normalizeCurrency } from '@packages/core/utils/currency-formatter.ts';
import { useCompanyCurrency } from '@packages/core/company/focused-company-context.ts';
import useUpdateOnlineOrderConfiguration from '../logic/use-update-online-order-branch-setting';

interface Form {
  deliveryDistance: string;
  deliveryFee: string;
  deliveryTime: string;
}

export default function OnlineOrderDeliveryTableDataCreate(props: {
  onClose: () => void;
  branchID: string;
  deliveryData: OnlineOrderDelivery[];
}) {
  // Params
  const { onClose, branchID, deliveryData } = props;

  // Hooks
  const currency = useCompanyCurrency();
  const { showAlert } = useNotificationCenter();
  const [update, { loading: updateLoading, error: updateError }] = useUpdateOnlineOrderConfiguration();

  // Data
  const { data: form, manualHandle } = useFormData<Form>({
    deliveryDistance: '0',
    deliveryFee: '0',
    deliveryTime: '0',
  });

  // Handlers
  const handleSubmit = () => {
    const sortedDeliveryTableData = [
      ...deliveryData.map((item) => ({
        id: item.id,
        deliveryDistance: item.deliveryDistance,
        deliveryFee: item.deliveryFee,
        deliveryTime: item.deliveryTime,
      })),
      {
        id: uuidv4(),
        deliveryDistance: parseInt(form.deliveryDistance) * 1000,
        deliveryFee: normalizeCurrency(form.deliveryFee, currency),
        deliveryTime: parseInt(form.deliveryTime),
      },
    ].sort((a, b) => a.deliveryDistance - b.deliveryDistance);

    update({
      variables: {
        branchID: branchID,
        input: {
          deliveryTableData: sortedDeliveryTableData,
        },
      },
    })
      .then(() => {
        showAlert({
          status: 'success',
          title: 'Success',
          message: 'Your online order configuration is update successful',
        });
      })
      .catch(() => {
        showAlert({
          status: 'error',
          title: 'Error',
          message: updateError?.message ?? '',
        });
      });

    onClose();
  };

  return (
    <>
      <Card>
        <CardHeader title={'Delivery table'}></CardHeader>

        <CardContent>
          <FormLayout>
            <FormItem title="Delivery distance" className="max-w-36">
              <TextInput
                data-testid="deliveryDistance"
                type={'number'}
                label="deliveryDistance"
                value={form.deliveryDistance}
                name="deliveryDistance"
                placeholder=""
                suffix={<span className="ml-2 text-gray-500">km</span>}
                onChange={(newVal) => {
                  manualHandle('deliveryDistance', newVal.target.value);
                }}
              />
            </FormItem>
            <FormItem title="Delivery time" className="max-w-36">
              <TextInput
                data-testid="deliveryTime"
                type={'number'}
                label="deliveryTime"
                value={form.deliveryTime}
                name="deliveryTime"
                placeholder=""
                suffix={<span className="ml-2 text-gray-500">minutes</span>}
                onChange={(newVal) => {
                  manualHandle('deliveryTime', newVal.target.value);
                }}
              />
            </FormItem>

            <FormItem title="deliveryFee" className="max-w-36">
              <TextInput
                data-testid="deliveryFee"
                type={'number'}
                label="deliveryFee"
                value={form.deliveryFee}
                name="deliveryFee"
                placeholder=""
                suffix={<span className="ml-2 text-gray-500">{currencySymbol(currency)}</span>}
                onChange={(newVal) => {
                  manualHandle('deliveryFee', newVal.target.value);
                }}
              />
            </FormItem>

            {/* <ApplicationErrorView error={updateError} /> */}
          </FormLayout>
        </CardContent>

        <CardFooter>
          <PrimaryButton
            disabled={updateLoading}
            loading={updateLoading}
            onClick={() => {
              handleSubmit();
            }}
          >
            Create
          </PrimaryButton>
          <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
        </CardFooter>
      </Card>
    </>
  );
}
