import BranchCreate from '@client/page/management/branch/branch-create/branch-create.tsx';
import BranchEditPage from '@client/page/management/branch/branch-edit/branch-edit-page.tsx';
import BranchList from './branch-list/branch-list';

export const BranchRouter = {
  path: 'branches',
  handle: {
    crumb: () => 'All location(s)',
  },
  children: [
    {
      path: '',
      element: <BranchList />
    },
    {
      path: 'create',
      handle: {
        crumb: () => 'Create Location',
      },
      element: <BranchCreate />
    },
    {
      path: ':branchId',
      handle: {
        crumb: () => 'Edit Location',
      },
      element: <BranchEditPage />
    },
  ],
};
