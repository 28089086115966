import { useSubscription } from '@apollo/client';
import { graphql } from '@client/graphql/types';
const QUERY = graphql(`
  subscription TableBookingUpdateSubscribe($branchId: ID!) {
    tableReservationUpdateSubscribe(branchId: $branchId) {
      event
      tableReservation {
        ...TableReservationItem
      }
    }
  }
`);

export default function useTableReservationUpdateSubscribe(branchId: string) {
  return useSubscription(QUERY, {
    variables: { branchId: branchId },
  });
}
