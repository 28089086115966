const PreviewBrowserPlaceholderContent = () => (
  <div className="flex flex-col py-8 space-y-4 px-4">
    <div className="flex justify-center items-center border-t-1 space-x-4">
      <div className="w-32 h-32 bg-gray-100 rounded" />
      <div className="w-32 h-32 bg-gray-100 rounded" />
      <div className="w-32 h-32 bg-gray-100 rounded" />
      <div className="w-32 h-32 bg-gray-100 rounded" />
    </div>

    <div className="h-4 bg-gray-100 rounded" />

    <div className="flex justify-center items-center border-t-1 space-x-4">
      <div className="w-32 h-32 bg-gray-100 rounded" />
      <div className="w-32 h-32 bg-gray-100 rounded" />
      <div className="w-32 h-32 bg-gray-100 rounded" />
      <div className="w-32 h-32 bg-gray-100 rounded" />
    </div>

    <div className="h-4 bg-gray-100 rounded" />
  </div>
);

export default PreviewBrowserPlaceholderContent;
