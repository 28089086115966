import { Navigate, RouteObject } from 'react-router-dom';
import MenuProductEdit from '@client/page/management/menu/menu-product-edit/menu-product-edit.tsx';
import MenuList from './menu-list/menu-list';
import MenuDetail from './menu-detail/menu-detail';
import MenuEdit from './menu-edit/menu-edit';
import MenuProductCreate from './menu-product-create/menu-product-create';
import MenuProductConfigurationAdd from './menu-product-configuration-add/menu-product-configuration-add';
import MenuProductConfigurationEdit from './menu-product-configuration-edit/menu-product-configuration-edit';
import MenuProductConfigurationValueCreate
  from './menu-product-configuration-value-create/menu-product-configuration-value-create';
import MenuProductConfigurationValueEdit
  from './menu-product-configuration-value-edit/menu-product-configuration-value-edit';
import MenuSectionCreate from './menu-section-create/menu-section-create';
import MenuSectionEdit from './menu-section-edit/menu-section-edit';
import MenuCreate from './menu-create/menu-create';

export const MenuRouter: RouteObject = {
  path: 'menu',
  handle: {
    crumb: () => 'breadcumb.menu.menu',
  },
  children: [
    {
      index: true,
      element: <MenuList />,
    },
    {
      path: ':menuId',
      handle: {
        crumb: () => 'breadcumb.menu.menuId',
      },
      children: [
        {
          index: true,
          element: <MenuDetail />,
        },
        {
          path: 'edit',
          handle: {
            crumb: () => 'breadcumb.menu.edit',
          },
          element: <MenuEdit />,
        },
        {
          path: 'products',
          children: [
            {
              index: true,
              element: <Navigate replace to=".." />,
            },
            {
              path: 'create',
              handle: {
                crumb: () => 'breadcumb.menu.create-product',
              },
              element: <MenuProductCreate />,
            },
            {
              path: ':productId',
              handle: {
                crumb: () => 'breadcumb.menu.product',
              },
              children: [
                {
                  index: true,
                  element: <MenuProductEdit />,
                },
                {
                  path: 'configurations',
                  handle: {
                    crumb: () => 'breadcumb.menu.configurations',
                  },
                  children: [
                    {
                      index: true,
                      element: <Navigate replace to=".." />,
                    },
                    {
                      path: 'create',
                      handle: {
                        crumb: () => 'breadcumb.menu.create-configuration',
                      },
                      element: <MenuProductConfigurationAdd />,
                    },
                    {
                      path: ':configurationId',
                      children: [
                        {
                          index: true,
                          element: <Navigate replace to=".." />,
                        },
                        {
                          path: 'edit',
                          handle: {
                            crumb: () => 'breadcumb.menu.edit-configuration',
                          },
                          element: <MenuProductConfigurationEdit />,
                        },
                        {
                          path: 'value/create',
                          handle: {
                            crumb: () => 'breadcumb.menu.add-price',
                          },
                          element: <MenuProductConfigurationValueCreate />,
                        },
                        {
                          path: 'value/:valueId',
                          handle: {
                            crumb: () => 'breadcumb.menu.edit-price',
                          },
                          element: <MenuProductConfigurationValueEdit />,
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: 'sections',
          handle: {
            crumb: () => 'breadcumb.menu.category',
          },
          children: [
            {
              index: true,
              element: <Navigate replace to=".." />,
            },
            {
              path: 'create',
              handle: {
                crumb: () => 'breadcumb.menu.create-category',
              },
              element: <MenuSectionCreate />,
            },
            {
              path: ':menuSectionId/edit',
              handle: {
                crumb: () => 'breadcumb.menu.edit-category',
              },
              element: <MenuSectionEdit />,
            },
          ],
        },
      ],
    },
    {
      path: 'create',
      handle: {
        crumb: () => 'breadcumb.menu.create-menu',
      },
      element: <MenuCreate />,
    },
  ],
};
