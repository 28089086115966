import React from 'react';
import classNames from 'classnames';
import { TableCellAlignment } from '@packages/ui/table/table-type.ts';

export default function TableRowColumn(props: {
  className?: string;
  children?: React.ReactNode;
  alignment?: TableCellAlignment;
}) {
  return (
    <td
      className={classNames(props.className, 'truncate max-w-56 text-gray-900 pl-2 md:pl-6 pr-2 md:pr-3 text-sm', {
        'text-left': props.alignment === TableCellAlignment.Left,
        'text-right': props.alignment === TableCellAlignment.Right,
        'text-center': props.alignment === TableCellAlignment.Center,
      })}
    >
      {props.children}
    </td>
  );
}
