import { InvoiceItemFragment } from '@client/graphql/types/graphql.ts';
import { useNavigate } from 'react-router-dom';
import { useFocusedCompany } from '@packages/core/company/focused-company-context.ts';
import Table from '@packages/ui/table/table.tsx';
import TableRow from '@packages/ui/table/table-row.tsx';
import TableRowColumn from '@packages/ui/table/table-row-cell.tsx';
import moment from 'moment-timezone';
import CurrencyView from '@packages/core/utils/currency-view.tsx';
import TableHeaderColumn from '@packages/ui/table/table-header-column.tsx';

const InvoiceTable = (props: { invoices: readonly InvoiceItemFragment[]; loading: boolean; branchID: string }) => {
  const navigate = useNavigate();
  const { settings } = useFocusedCompany();

  return (
    <Table
      loading={props.loading}
      data={props.invoices}
      build={(invoice) => {
        return (
          <TableRow
            key={invoice.id}
            onClick={() => {
              navigate(invoice.id);
            }}
          >
            <TableRowColumn>{invoice.shortID}</TableRowColumn>
            <TableRowColumn>{invoice.place}</TableRowColumn>
            <TableRowColumn>{moment(invoice.createdAt).tz(settings.timezone).format('HH:mm')}</TableRowColumn>
            <TableRowColumn>
              <CurrencyView price={invoice.total} />
            </TableRowColumn>
          </TableRow>
        );
      }}
    >
      <TableHeaderColumn>ID</TableHeaderColumn>
      <TableHeaderColumn>Place</TableHeaderColumn>
      <TableHeaderColumn>Time</TableHeaderColumn>
      <TableHeaderColumn>Total</TableHeaderColumn>
    </Table>
  );
};

export default InvoiceTable;
