import { useState } from 'react';
import PreviewBrowser from '@client/page/management/banner/banner-preview/component/preview-browser.tsx';
import PreviewBrowserPlaceholderContent from './component/preview-browser-placeholder-content.tsx';
import Overlay from './component/overlay';

interface OverlayBannerPreviewProps {
  uploadImage: string | null;
}

export function OverlayBannerPreview({
  uploadImage,
}: OverlayBannerPreviewProps) {
  const host: string = import.meta.env.VITE_CDN_HOST as string;
  const [isHidden, setIsHidden] = useState(true);

  const toggleVisibility = () => {
    setIsHidden(!isHidden);
  };

  return (
    <div className="flex flex-col ml-4 space-y-4 h-full relative">
      <PreviewBrowser>
        <div className="relative h-full">
          <PreviewBrowserPlaceholderContent />

          <Overlay isHidden={false} toggleVisibility={toggleVisibility} >
            {uploadImage && (
              <img
                className="p-16"
                alt="preview"
                src={host + '/' + uploadImage}
              />
            )}
          </Overlay>
        </div>
      </PreviewBrowser>
    </div>
  );
}
