import { useMutation } from '@apollo/client';
import { graphql } from '@client/graphql/types';

export const UPDATE_MENU_SECTION = graphql(`
  mutation updateMenuSection($id: ID!, $input: UpdateMenuSection!, $timeRuleInput: UpdateMenuSectionTimeRule!) {
    updateMenuSection(id: $id, input: $input, timeRuleInput: $timeRuleInput) {
      name
      description
    }
  }
`);

export function useUpdateMenuSection() {
  return useMutation(UPDATE_MENU_SECTION, {});
}
