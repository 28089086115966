import { useTranslate } from "@tolgee/react";

const MailInput = (props: {
  value: string;
  onChange: (value: string) => void;
}) => {
  const { value, onChange } = props;
  const { t } = useTranslate();

  return (
    <textarea
      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 
       focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
      value={value}
      name={'mails'}
      placeholder={t('reservation.setting.form-input.notification-emails.description')}
      onChange={(e) => {
        onChange(e.target.value);
      }}
    />
  );
};

export default MailInput;
