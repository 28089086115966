import Spinner from 'ui/src/spinner';
import Center from 'ui/src/center';
import { getFragmentData } from '@client/graphql/types';
import { OnlineOrderBranchSettingFragment } from '@client/graphql/online-order-branch-setting-fragment';
import ApplicationErrorView from '@client/module/error/application-error-view';
import { useFocusedBranchContext } from '../../table-group/table-group-list/component/branch-selection/focused-branch-context.ts';
import useOnlineOrderBranchSetting from './logic/use-online-order-branch-setting';
import OnlineOrderBranchSettingSetup from './component/online-order-branch-setting-setup';
import OnlineOrderBranchSettingEdit from './component/online-order-branch-setting-edit';

export default function OnlineOrderBranchSettingPage() {
  // Params
  const branchProvider = useFocusedBranchContext();
  const branchID = branchProvider.branch?.id ?? '';

  // Hooks
  const { data, loading, error } = useOnlineOrderBranchSetting(branchID);

  // Data
  const onlineOrderBranchSettingData = getFragmentData(
    OnlineOrderBranchSettingFragment,
    data?.onlineOrderBranchConfiguration
  );

  // Safety check
  if (loading) {
    return (
      <Center className="p-8">
        <Spinner />
      </Center>
    );
  }

  if (error) {
    return <ApplicationErrorView error={error} />;
  }

  return (
    <>
      {onlineOrderBranchSettingData ? (
        <>
          <OnlineOrderBranchSettingEdit onlineOrderBranchSettingData={onlineOrderBranchSettingData} />
        </>
      ) : (
        <OnlineOrderBranchSettingSetup branchID={branchID} />
      )}
    </>
  );
}
