import { Link, useNavigate } from 'react-router-dom';
import CardHeader from '@packages/ui/card/card-header.tsx';
import SecondaryButton from '@packages/ui/button/secondary-button.tsx';
import PrimaryButton from '@packages/ui/button/primary-button.tsx';
import Card from '@packages/ui/card/card.tsx';
import { useMenuList } from '@client/page/management/menu/menu-list/logic/use-menu-list.ts';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import Table from '@packages/ui/table/table.tsx';
import TableHeaderColumn from '@packages/ui/table/table-header-column.tsx';
import TableRowColumn from '@packages/ui/table/table-row-cell.tsx';
import TableRow from '@packages/ui/table/table-row.tsx';
import { ContentLayout, PrimaryContent } from 'ui/src/content-layout';
import CardTableContent from '@packages/ui/card/card-content-table.tsx';
import { useTranslate } from '@tolgee/react';
import { Helmet } from 'react-helmet-async';

export default function MenuList() {
  const navigate = useNavigate();
  const { t } = useTranslate();

  const { loading, error, data, refetch } = useMenuList();

  return (
    <ContentLayout>
      <Helmet>
        <title>{t('menu.menu-list.helmet.title')}</title>
        <meta name="description" content={t('menu.menu-list.helmet.description')} />
      </Helmet>
      <PrimaryContent>
        <Card>
          <CardHeader title={t('menu.menu-list.header')}>
            {/* Create button */}
            <PrimaryButton
              onClick={() => {
                navigate('create');
              }}
              data-testid="list-create-button"
            >
              {t('menu.menu-list.button.create', 'Create')}
            </PrimaryButton>

            {/* Refresh button */}
            <SecondaryButton
              onClick={() => {
                refetch().catch((e) => void e);
              }}
            >
              <ArrowPathIcon className="w-4" />
            </SecondaryButton>
          </CardHeader>

          <CardTableContent>
            <Table
              loading={loading}
              error={error?.message}
              data={data?.menus ?? []}
              build={(menu) => {
                return (
                  <TableRow key={menu.id}>
                    <TableRowColumn>
                      <Link to={menu.id}>{menu.title}</Link>
                    </TableRowColumn>
                  </TableRow>
                );
              }}
            >
              <TableHeaderColumn>{t('common.table-header-column.name')}</TableHeaderColumn>
            </Table>
          </CardTableContent>
        </Card>

        {/* Hide INH-145 */}
        {/* <MenuPreviewSetup /> */}
      </PrimaryContent>
    </ContentLayout>
  );
}
