import { useState } from 'react';
import Card from 'ui/src/card/card';
import CardContent from 'ui/src/card/card-content';
import FormItem from 'ui/src/form/form-item';
import FormLayout from 'ui/src/form/form-layout';
import TextInput from 'ui/src/form/input/text-input';
import ToggleInput from 'ui/src/form/input/toggle-input';
import CardFooter from 'ui/src/card/card-footer';
import SecondaryButton from 'ui/src/button/secondary-button';
import PrimaryButton from 'ui/src/button/primary-button';
import CardHeader from 'ui/src/card/card-header';
import { useNavigate } from 'react-router-dom';
import { TimeRuleType, ContentType } from '@client/graphql/types/graphql';
import useFormData from '@client/module/utils/use-form-data';
import MenuSelectionInput from 'ui/src/form/input/menu-selection-input';
import {
  formatGraphQlError,
  validationErrors,
} from '@client/module/error/error';
import { useFocusedCompanyContext } from '@packages/core/company/focused-company-context.ts';
import { OverlayBannerPreview } from '@client/page/management/banner/banner-preview/overlay-banner-preview.tsx';
import {
  ContentLayout,
  PrimaryContent,
  SecondaryContent,
} from 'ui/src/content-layout';
import ImageInput from '@packages/core/uploading/image-input.tsx';
import { client } from '@client/graphql/client.ts';
import moment from 'moment-timezone';
import { Helmet } from 'react-helmet-async';
import useBannerCreate from './logic/use-banner-create';

interface OverlayBannerForm {
  title: string;
  enabled: boolean;
  contentType: ContentType;
  image: string | null;
  timeRuleType: TimeRuleType;
  start: string;
  end: string;
}

export default function OverlayBannerCreate() {
  const { id: companyID, settings } = useFocusedCompanyContext();
  const navigate = useNavigate();
  const [create, { loading, error }] = useBannerCreate();
  const [timeRuleInp, setTimeRuleInp] = useState<TimeRuleType>(
    TimeRuleType.Always
  );

  const applicationErrors = formatGraphQlError(error?.graphQLErrors);
  const validationError = validationErrors(applicationErrors);

  const {
    data: form,
    handle,
    manualHandle,
  } = useFormData<OverlayBannerForm>({
    title: '',
    enabled: false,
    contentType: ContentType.Overlay,
    image: null,
    timeRuleType: TimeRuleType.Always,
    start: '',
    end: '',
  });

  const onSubmit = async () => {
    if (form.timeRuleType == TimeRuleType.RangeTime) {
      if (form.start > form.end) {
        return;
      }
      await create({
        variables: {
          companyID: companyID,
          input: {
            title: form.title,
            enabled: form.enabled,
          },
          timeRuleInput: {
            type: form.timeRuleType,
            start: moment(form.start)
              .tz(settings.timezone, false)
              .toISOString(),
            end: moment(form.end).tz(settings.timezone, false).toISOString(),
          },
          contentInput: {
            type: form.contentType,
            image: form.image,
          },
        },
      })
        .then(() => {
          navigate('../..');
        })
        .catch((e) => {
          /* eslint-disable no-console */
          console.log(e);
        });
    }
    if (form.timeRuleType == TimeRuleType.Always) {
      await create({
        variables: {
          companyID: companyID,
          input: {
            title: form.title,
            enabled: form.enabled,
          },
          timeRuleInput: {
            type: form.timeRuleType,
          },
          contentInput: {
            type: form.contentType,
            image: form.image,
          },
        },
      })
        .then(() => {
          navigate('../..');
        })
        .catch((e) => {
          /* eslint-disable no-console */
          console.log(e);
        });
    }
  };

  return (
    <ContentLayout>
      <Helmet>
        <title>Create overlay banner</title>
        <meta
          name="description"
          content="This page allows you to create an overlay banner"
        />
      </Helmet>
      <PrimaryContent>
        <Card className={'flex-grow'}>
          <CardHeader title={'Create overlay banner'} />

          <CardContent>
            <FormLayout>
              <FormItem title={'Title'} className="max-w-md">
                <TextInput
                  data-testid="title-input"
                  type="text"
                  label="title"
                  name="title"
                  placeholder="Title"
                  value={form.title}
                  error={validationError.title}
                  autoComplete={'off'}
                  onChange={handle}
                />
              </FormItem>

              <FormItem title={''} className="max-w-md">
                <ImageInput
                  client={client}
                  images={form.image ? [form.image] : []}
                  onChange={(newValue) => {
                    if (newValue.length > 0) {
                      manualHandle('image', newValue[0]);
                    } else {
                      manualHandle('image', null);
                    }
                  }}
                />
              </FormItem>

              <FormItem title={'Time rule type'} className="max-w-md">
                <div className="flex flex-col gap-4">
                  <MenuSelectionInput
                    title=""
                    data={Object.values(TimeRuleType)}
                    value={form.timeRuleType}
                    onChange={(newValue) => {
                      manualHandle('timeRuleType', newValue);
                      setTimeRuleInp(newValue);
                    }}
                    build={(item) => {
                      return {
                        id: item,
                        name: item,
                      };
                    }}
                    className="-my-2 w-full"
                  />
                  {timeRuleInp == TimeRuleType.RangeTime && (
                    <div>
                      <FormItem title="Timezone" className="pt-4">
                        <TextInput
                          type={'text'}
                          label={'timezone'}
                          value={settings.timezone}
                          name={'timezone'}
                          placeholder={'Timezone'}
                          // eslint-disable-next-line @typescript-eslint/no-empty-function
                          onChange={() => {}}
                        />
                      </FormItem>

                      <FormItem title={'Range'} className="pt-4">
                        <div className="max-w-full flex gap-2">
                          <input
                            className="rounded-md max-w-full border-gray-300"
                            aria-label="Start Date"
                            id="start"
                            name="start"
                            type="datetime-local"
                            onChange={handle}
                          />

                          <input
                            className="rounded-md max-w-full border-gray-300"
                            aria-label="End Date"
                            id="end"
                            name="end"
                            type="datetime-local"
                            onChange={handle}
                          />
                        </div>

                        <div>
                          {form.start >= form.end && (
                            <p
                              className="mt-2 text-xs italic text-red-600"
                              id="email-error"
                            >
                              {
                                'Choose startDate and endDate, startDate must be smaller than endDate'
                              }
                            </p>
                          )}
                        </div>
                      </FormItem>
                    </div>
                  )}

                  {timeRuleInp == TimeRuleType.Always && <div></div>}
                </div>
              </FormItem>

              <FormItem title="Active" className="w-32">
                <ToggleInput
                  value={form.enabled}
                  onChange={(newValue) => {
                    manualHandle('enabled', newValue);
                  }}
                />
              </FormItem>
            </FormLayout>
          </CardContent>

          <CardFooter>
            <div className="flex justify-center space-x-4 w-96">
              <SecondaryButton
                className="flex-1"
                onClick={() => {
                  navigate('../..');
                }}
              >
                Back
              </SecondaryButton>
              <PrimaryButton
                id="create-button"
                data-testid="create-button"
                className="w-32 flex-1"
                onClick={() => {
                  onSubmit().catch((e) => void e);
                }}
                loading={loading}
              >
                Create
              </PrimaryButton>
            </div>
          </CardFooter>
        </Card>
      </PrimaryContent>

      <SecondaryContent>
        <OverlayBannerPreview uploadImage={form.image} />
      </SecondaryContent>
    </ContentLayout>
  );
}
