import { Navigate, RouteObject } from 'react-router-dom';
import OpeningTimeDayOfWeek from '@client/page/management/opening-time/opening-time-day-of-week/opening-time-day-of-week.tsx';
export const OpeningTimeRouter: RouteObject = {
  path: 'time/:service',
  children: [
    {
      index: true,
      element: <Navigate to=".." />,
    },
    {
      path: ':dayOfWeek',
      handle: {
        crumb: () => 'Day of week',
      },
      children: [
        {
          path: '',
          element: <OpeningTimeDayOfWeek />,
        },
      ],
    },
  ],
};
