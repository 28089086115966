import { Dayjs } from 'dayjs';
import { ReactNode } from 'react';
import {
  useFocusedBranchContext
} from '@client/page/management/table-group/table-group-list/component/branch-selection/focused-branch-context.ts';
import useGetInvoicesByBranch from '@client/page/management/invoice/invoice-list/logic/use-get-invoices-by-branch.ts';
import Loading from '@packages/ui/loading.tsx';
import ApplicationErrorView from '@client/module/error/application-error-view.tsx';
import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import CardTableContent from '@packages/ui/card/card-content-table.tsx';
import CardFooter from '@packages/ui/card/card-footer.tsx';
import InvoiceTable from '@client/page/management/invoice/invoice-list/component/Invoice-table.tsx';
import { InvoiceItemFragmentDoc } from '@client/graphql/types/graphql.ts';
import { getFragmentData } from '@client/graphql/types';

export default function InvoiceList(props: {
  startTime: Dayjs,
  endTime: Dayjs,
  header: ReactNode,
  footer: ReactNode,
}){
  const branchProvider = useFocusedBranchContext();
  const branchID = branchProvider.branch?.id ?? '';

  const {data, loading, error} = useGetInvoicesByBranch(branchID, {
    startTime: props.startTime.format('YYYY-MM-DDTHH:mm'),
    endTime: props.endTime.format('YYYY-MM-DDTHH:mm')
  })

  const invoices = getFragmentData(InvoiceItemFragmentDoc, data?.invoicesByBranch);

  if (loading) return <Loading />


  return (
    <>
      <ApplicationErrorView className="my-4" error={error} />
      <Card>
        <CardHeader title="Invoices">{props.header}</CardHeader>
        <CardTableContent>
          <InvoiceTable
            loading={loading}
            invoices={invoices ?? []}
            branchID={branchID}
          />
        </CardTableContent>

        {props.footer && <CardFooter>{props.footer}</CardFooter>}
      </Card>
    </>
  )
}