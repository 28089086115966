import { usePushNotificationService } from '@client/module/push-notification/push-notification-context.ts';
import { usePushTokens } from '@client/module/push-notification/use-push-tokens.ts';
import { useEffect } from 'react';
import { captureException } from '@sentry/react';
import SecondaryButton from '@packages/ui/button/secondary-button.tsx';
import DotStatus from '@packages/ui/status/dot-status.tsx';

export const PushNotificationToggle = () => {
  const pushNotificationService = usePushNotificationService();
  const { data, refetch } = usePushTokens();
  const enabled = data?.pushNotificationTokens.includes(pushNotificationService.currentToken ?? '') ?? false;

  const enablePushHandler = () => {
    pushNotificationService
      .enable()
      ?.then(() => refetch())
      .catch(captureException);
  };

  useEffect(() => {
    refetch().catch(() => void 0);
  }, [pushNotificationService.currentToken, refetch]);

  return (
    <SecondaryButton onClick={enablePushHandler}>
      <div className="flex gap-2 items-center">
        <DotStatus enabled={enabled} />
        <p>Enable push notification</p>
      </div>
    </SecondaryButton>
  );
};
