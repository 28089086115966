import { GeneralDeleteButton } from '@client/page/component/general-delete-button.tsx';
import { useDeleteTableGroup } from '@client/page/management/table-group/table-group-edit/logic/use-delete-table-group';
import { useTranslate } from "@tolgee/react";

export default function TableGroupDeleteButton(props: {
  tableGroupId: string;
}) {
  const [deleteTableGroup] = useDeleteTableGroup();
  const { t } = useTranslate();

  return (
    <GeneralDeleteButton
      alert={{
        title: t('table-group-delete.alert-title'),
        message: t('table-group-delete.alert-message'),
      }}
      card={{
        title: t('table-group-delete.card-title'),
        descriptions: t('table-group-delete.card-description'),
        buttonTitle: t('table-group-delete.card-button-title'),
      }}
      delete={async () => {
        await deleteTableGroup({ variables: { id: props.tableGroupId } });
      }}
    />
  );
}
