export default function migrationV1() {
  const authData = localStorage.getItem('auth');
  const authUserData = localStorage.getItem('auth-user');

  const auth: unknown = authData ? JSON.parse(authData) : null;
  const authUser: unknown = authUserData ? JSON.parse(authUserData) : null;

  if (auth && authUser) {
    localStorage.removeItem('auth');
    localStorage.removeItem('authUser');

    localStorage.setItem('auth', JSON.stringify({ ...auth, ...authUser }));
  }
}
