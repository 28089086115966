import { useCallback } from 'react';
import { useNotificationCenter } from '@packages/ui/notification/notification-center.ts';
import PrimaryButton from '@packages/ui/button/primary-button.tsx';
import useDoneOnlineOrder from '@client/page/management/online-order/detail/logic/use-done-online-order.ts';
import { useDialog } from 'ui/src/modal/use-dialog';
import { useTranslate } from "@tolgee/react";

const OnlineOrderActionDone = (props: { orderID: string; onClose?: () => void }) => {
  const { orderID, onClose } = props;

  const { showAlert } = useNotificationCenter();

  const [action, { loading }] = useDoneOnlineOrder();

  const dialog = useDialog();
  const { t } = useTranslate();
  const handleDialog = () => {
    dialog.confirmAlert({
      title: t('online-order.detail.action.done.dialog.title'),
      message: t('online-order.detail.action.done.dialog.message'),
      textButton: t('online-order.detail.action.done.dialog.text-button'),
      onConfirm: confirmHandler,
    });
  };

  const confirmHandler = useCallback(() => {
    return action({
      variables: {
        id: orderID,
      },
    })
      .then(() => {
        showAlert({
          status: 'success',
          title: t('alert.title.success'),
          message: t('online-order.detail.action.done.alert.message'),
        });
        onClose?.();
      })
      .catch((e: Error) => {
        showAlert({
          status: 'error',
          title: t('alert.title.error'),
          message: e.message,
        });
      });
  }, [action, orderID, showAlert, onClose, t]);

  return (
    <PrimaryButton onClick={handleDialog} disabled={loading}>
      {t('online-order.detail.action.done.button')}
    </PrimaryButton>
  );
};

export default OnlineOrderActionDone;
