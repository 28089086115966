import { graphql } from '@client/graphql/types';
import { useMutation } from '@apollo/client';

const QUERY = graphql(`
  mutation DeleteAllTableReservations($branchID: ID!) {
    deleteAllTableReservations(branchId: $branchID)
  }
`);

export default function useDeleteAllReservations() {
  return useMutation(QUERY, {});
}
