import { Fragment, ReactNode } from 'react';
import { Transition } from '@headlessui/react';
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline';
import { XMarkIcon } from '@heroicons/react/20/solid';
import {
  Notification,
  NotificationCenterContext,
  NotificationStatus,
  useNotificationCenter,
  useNotificationCenterProvider,
} from '@packages/ui/notification/notification-center.ts';
import { useTranslate } from '@tolgee/react';

export default function NotificationCenterProvider(props: { children?: ReactNode }) {
  const notificationCenter = useNotificationCenterProvider();

  return (
    <NotificationCenterContext.Provider value={notificationCenter}>
      {props.children}
      <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
      >
        <div className="flex w-full h-full flex-col justify-end items-center space-y-4 sm:items-end">
          {notificationCenter.notifications.map((notification) => (
            <NotificationView key={notification.id} notification={notification} />
          ))}
        </div>
      </div>
    </NotificationCenterContext.Provider>
  );
}

const NotificationIconView = (props: { icon: NotificationStatus }) => {
  switch (props.icon) {
    case 'success':
      return <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />;
    case 'error':
      return <ExclamationCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />;
    case 'warning':
      return <ExclamationTriangleIcon className="h-6 w-6 text-yellow-400" aria-hidden="true" />;
    case 'info':
      return <InformationCircleIcon className="h-6 w-6 text-blue-400" aria-hidden="true" />;
  }
};

const NotificationView = (props: { notification: Notification }) => {
  const notificationCenter = useNotificationCenter();
  const notification = props.notification;
  const { t } = useTranslate();

  return (
    <Transition
      show={true}
      as={Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div
        className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
        <div className="p-4">
          <div className="flex items-start">
            <div className="flex-shrink-0">
              <NotificationIconView icon={notification.status} />
            </div>
            <div className="ml-3 w-0 flex-1 pt-0.5">
              <p className="text-sm font-medium text-gray-900">{notification.title}</p>
              <p className="mt-1 text-sm text-gray-500">{notification.message}</p>
            </div>
            <div className="ml-4 flex flex-shrink-0">
              <button
                type="button"
                className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500
                 focus:ring-offset-2"
                onClick={() => {
                  notificationCenter.closeAlert(notification.id);
                }}
              >
                <span className="sr-only">{t('common.close')}</span>
                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
};
