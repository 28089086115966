import { graphql } from '@client/graphql/types';
import { useQuery } from '@apollo/client';

const QUERY = graphql(`
  query OnlineOrderDetail($id: ID!, $branchID: ID!) {
    onlineOrder(id: $id) {
      ...OnlineOrderItem
    }

    onlineOrderBranchConfiguration(branchID: $branchID) {
      ...OnlineOrderBranchSettingItem
    }
  }
`);

export default function useGetOnlineOrder(id: string, branchID: string) {
  return useQuery(QUERY, {
    variables: {
      id: id,
      branchID: branchID,
    }
  });
}
