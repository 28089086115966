import { createContext, useContext } from 'react';
import { PushNotificationService } from '@client/module/push-notification/push-notification.tsx';

export const PushNotificationServiceContext = createContext<
  PushNotificationService | undefined
>(undefined);

export function usePushNotificationService() {
  const context = useContext(PushNotificationServiceContext);

  if (context === undefined) {
    throw new Error(
      'usePushNotificationService must be used within a PushServiceContextProvider'
    );
  }

  return context;
}
