import PreviewBrowserNavigationBar from '@client/page/management/banner/banner-preview/component/preview-browser-navigation-bar.tsx';
import Card from '@packages/ui/card/card.tsx';
import { ReactNode } from 'react';

const PreviewBrowser = (props: { children: ReactNode }) => {
  return (
    <Card>
      <PreviewBrowserNavigationBar />
      {props.children}
    </Card>
  );
};

export default PreviewBrowser;
