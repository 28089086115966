import { useCompanyCurrency } from '@packages/core/company/focused-company-context.ts';
import { formatCurrency } from './currency-formatter';

export default function CurrencyView({ price, className }: { className?: string; price: number | null | undefined }) {
  const currency = useCompanyCurrency();

  if (price === null || price === undefined) {
    return <div className={className}>N/A</div>;
  }

  return <span className={className}>{formatCurrency(price, currency)}</span>;
}
