import { useQuery } from '@apollo/client';
import { graphql } from '@client/graphql/types';

const QUERY = graphql(`
  query Banners(
    $companyID: String!
    $offset: Int!
    $limit: Int!
    $filter: BannerStatusFilter
  ) {
    banners(
      companyId: $companyID
      offset: $offset
      limit: $limit
      filter: $filter
    ) {
      bannerId
      title
      viewCount
      clickCount
      enabled
      createAt
      updateAt
      content {
        __typename
        ... on TopContent {
          type
          text
        }
        ... on OverlayContent {
          type
          image
        }
      }
      timeRule {
        __typename
        ... on RangeTimeRule {
          type
          start
          end
        }
        ... on AlwaysTimeRule {
          type
        }
      }
    }
  }
`);

export default function useBanners(
  companyID: string,
  offset: number,
  limit: number,
  enabled: boolean | undefined
) {
  return useQuery(QUERY, {
    variables: {
      companyID: companyID,
      offset: offset,
      limit: limit,
      filter: {
        enabled: enabled,
      },
    },
  });
}
