import TableGroupEdit from '@client/page/management/table-group/table-group-edit/table-group-edit.tsx';
import TableListEdit from '@client/page/management/table/table-list/table-list-edit.tsx';
import TableGroupList from '../table-group/table-group-list/table-group-list';

export const TableRouter = {
  path: 'table-groups',
  handle: {
    crumb: () => 'All Table Group(s)',
  },
  children: [
    {
      index: true,
      element: <TableGroupList />
    },
    {
      path: ':tableGroupId',
      handle: {
        crumb: () => 'Edit table group',
      },
      element: <TableGroupEdit />
    },
    {
      path: ':tableGroupId/tables',
      handle: {
        crumb: () => 'Edit list tables',
      },
      element: <TableListEdit />
    },
  ],
};
