import { useEffect, useState } from 'react';
import {
  SubscribeEvent,
  TableReservationItemFragment,
  TableReservationsFilter,
} from '@client/graphql/types/graphql.ts';
import useTableReservationUpdateSubscribe from '@client/page/management/table-reservation/operative/logic/use-get-table-booking-subscribe.ts';
import { getFragmentData } from '@client/graphql/types';
import { TableReservationFragment } from '@client/graphql/table-reservation-fragment.ts';
import useTableReservationsQuery from '@client/page/management/table-reservation/operative/logic/use-table-reservations-query.ts';
import { useNotificationCenter } from '@packages/ui/notification/notification-center.ts';

const notificationAudio = new Audio('https://inhouse-storage.fra1.cdn.digitaloceanspaces.com/static/notification.wav');

export const useTableReservations = (branchID: string, filter: TableReservationsFilter) => {
  const { showAlert } = useNotificationCenter();

  const [data, setData] = useState<TableReservationItemFragment[]>([]);

  const { data: _initialData, refetch, loading } = useTableReservationsQuery(branchID, filter);
  const { data: _updateData } = useTableReservationUpdateSubscribe(branchID);

  useEffect(() => {
    if (!_initialData) {
      return;
    }

    setData(
      _initialData.tableReservations.map((item) => {
        return getFragmentData(TableReservationFragment, item);
      })
    );
  }, [_initialData]);

  useEffect(() => {
    if (!_updateData) {
      return;
    }

    const tableReservation = getFragmentData(
      TableReservationFragment,
      _updateData.tableReservationUpdateSubscribe.tableReservation
    );

    if (_updateData.tableReservationUpdateSubscribe.event === SubscribeEvent.Create) {
      showAlert({
        status: 'success',
        title: 'New table reservation',
        message: `New table reservation has been created for ${tableReservation.guestName}`,
      });

      void notificationAudio.play();
    }

    refetch().catch((err: Error) => {
      showAlert({
        status: 'error',
        title: 'Error',
        message: err.message,
      });
    });
  }, [_updateData, refetch, showAlert]);

  return { data, loading, showAlert };
};
