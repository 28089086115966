import { useMutation } from '@apollo/client';
import { graphql } from '@client/graphql/types';

const RESET_CURRENT_USER_PASSWORD = graphql(`
  mutation ResetCurrentUserPassword(
    $userID: ID!
    $input: ResetCurrentUserPasswordInput!
  ) {
    resetCurrentUserPassword(userID: $userID, input: $input)
  }
`);

export default function useResetCurrentUserPassword() {
  return useMutation(RESET_CURRENT_USER_PASSWORD, {});
}
