import React from 'react';
import classNames from 'classnames';

export default function CardFooter(props: { children?: React.ReactNode }) {
  return (
    <div
      className={classNames(
        'bg-gray-50 px-2 py-3 flex justify-center rounded-b-lg space-x-2',
        'md:px-4 md:space-x-4 md:py-5 sm:px-6'
      )}
    >
      {props.children}
    </div>
  );
}
