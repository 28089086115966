import { graphql } from '@client/graphql/types';
import { useQuery } from '@apollo/client';

const QUERY = graphql(`
  query BranchTableBookingConfiguration($branchID: ID!) {
    tableReservationConfiguration(branchID: $branchID) {
      id
      hourBlocking
      seatLimit
      flexDuration
      defaultBookingTimeDuration
      status
      pause
      autoConfirm
      ignoreStatus
      notificationMails
      phone
      reservationLeadMinutes
      reservationTraitMinutes
      reminderTime
      flexEnabled  
    }
  }
`);

export default function useTableReservationSetting(branchId: string) {
  return useQuery(QUERY, {
    variables: {
      branchID: branchId,
    },
  });
}
