import { Invoice, InvoiceLineItemFragment } from '@client/graphql/types/graphql';
import Table from 'ui/src/table/table';
import TableHeaderColumn from 'ui/src/table/table-header-column';
import TableRow from 'ui/src/table/table-row';
import TableRowColumn from 'ui/src/table/table-row-cell';
import CurrencyView from 'core/src/utils/currency-view';
import { TableCellAlignment } from '@packages/ui/table/table-type.ts';
import { capitalize } from 'lodash';

export default function InvoiceProductTable(props: { invoice: Invoice }) {
  const { invoice } = props;

  const products = invoice.invoiceLines;

  return (
    <>
      <Table
        data={products ?? []}
        build={(product) => <InvoiceProductRow key={product.id} product={product} />}
        footer={<InvoiceProductTotalRow invoice={invoice} />}
      >
        <TableHeaderColumn>Product</TableHeaderColumn>
        <TableHeaderColumn alignment={TableCellAlignment.Right}>Price</TableHeaderColumn>
      </Table>
    </>
  );
}

function InvoiceProductRow(props: { product: InvoiceLineItemFragment }) {
  const { product } = props;
  return (
    <>
      <TableRow key={product.id}>
        <TableRowColumn>
          <div className="py-1.5">
            <p className="font-normal	text-sm mb-0.5">
              {product.quantity} x {product.title}
            </p>
            <ul className="text-gray-600 italic text-xs gap-y-0.5">{product.subtitle}</ul>
          </div>
        </TableRowColumn>

        <TableRowColumn alignment={TableCellAlignment.Right}>
          <div className="space-x-1">
            <span className="flex-none text-sm text-gray-500">
              <CurrencyView price={product.price} /> x {product.quantity}
            </span>

            <span className="hidden md:inline-block">=</span>

            <span className="ml-1 block md:inline-block">
              <CurrencyView className="text-base" price={product.total} />
            </span>
          </div>
        </TableRowColumn>
      </TableRow>
    </>
  );
}

function InvoiceProductTotalRow(props: { invoice: Invoice }) {
  return (
    <>
      {props.invoice.invoiceSecondaryLines?.map((item) => (
        <TableRow key={item.id} className="bg-gray-50 h-8">
          <TableRowColumn className="text-gray-500">
            {capitalize(item.title)} {item.description && <>({item.description})</>}
          </TableRowColumn>
          <TableRowColumn alignment={TableCellAlignment.Right}>
            <CurrencyView className="text-md" price={item.price} />
          </TableRowColumn>
        </TableRow>
      ))}

      <TableRow className="">
        <TableRowColumn className="text-lg font-semibold">Total</TableRowColumn>
        <TableRowColumn alignment={TableCellAlignment.Right}>
          <CurrencyView className="text-3xl" price={props.invoice.total} />
        </TableRowColumn>
      </TableRow>
    </>
  );
}
