import { ReactNode } from 'react';
import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react';
import classNames from 'classnames';

const DialogAlert = (props: { children?: ReactNode }) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    <Dialog open={true} className="relative z-10 focus:outline-none" onClose={() => {}}>
      <DialogBackdrop transition={true} className="fixed inset-0 bg-black/30" />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4">
          <DialogPanel
            transition
            className={classNames(
              'data-[closed]:transform-[scale(95%)] w-full',
              'max-w-md rounded-xl border bg-white p-6 backdrop-blur-2xl duration-300 ease-out data-[closed]:opacity-0'
            )}
          >
            {props.children}
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};

export default DialogAlert;
