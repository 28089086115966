import { useDialog } from '@packages/ui/modal/use-dialog';
import { useNavigate } from 'react-router-dom';
import { useDeleteMenuProduct } from '@client/page/management/menu/menu-product-edit/logic/use-delete-menu-product';
import { useCallback } from 'react';
import CardSection from '@packages/ui/card/card-section';
import DestroyButton from '@packages/ui/button/destroy-button';
import { useTranslate } from '@tolgee/react';

export default function ProductMenuDelete(props: { productId: string }) {
  const navigate = useNavigate();
  const { t } = useTranslate();
  const dialog = useDialog();
  const [deleteMenuProduct] = useDeleteMenuProduct();

  const onDestroy = useCallback(() => {
    dialog.destructAlert({
      title: t('menu.menu-detail.product.edit.extra-action.delete.alert.title'),
      message: t('menu.menu-detail.product.edit.extra-action.delete.alert.message'),
      textButton: t('menu.menu-detail.product.edit.extra-action.delete.alert.text-button'),
      onConfirm: async () => {
        await deleteMenuProduct({ variables: { id: props.productId } });
        navigate('..');
      },
    });
  }, [deleteMenuProduct, dialog, navigate, props.productId, t]);

  return (
    <div className="flex space-x-4">
      <CardSection
        title={t('menu.menu-detail.product.edit.extra-action.delete.title')}
        descriptions={t('menu.menu-detail.product.edit.extra-action.delete.description')}
      >
        <DestroyButton data-testid="delete-button" onClick={onDestroy}>
          {t('menu.menu-detail.product.edit.extra-action.delete.button')}
        </DestroyButton>
      </CardSection>
    </div>
  );
}
