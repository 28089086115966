import { DaysOfWeek } from '@client/graphql/types/graphql.ts';

export function daysOfWeekToString(input: DaysOfWeek): string {
  switch (input) {
    case DaysOfWeek.Monday:
      return 'Monday';
    case DaysOfWeek.Tuesday:
      return 'Tuesday';
    case DaysOfWeek.Wednesday:
      return 'Wednesday';
    case DaysOfWeek.Thursday:
      return 'Thursday';
    case DaysOfWeek.Friday:
      return 'Friday';
    case DaysOfWeek.Saturday:
      return 'Saturday';
    case DaysOfWeek.Sunday:
      return 'Sunday';
  }
}

export function stringToDaysOfWeek(input: string): DaysOfWeek | undefined {
  switch (input) {
    case 'MONDAY':
      return DaysOfWeek.Monday;
    case 'TUESDAY':
      return DaysOfWeek.Tuesday;
    case 'WEDNESDAY':
      return DaysOfWeek.Wednesday;
    case 'THURSDAY':
      return DaysOfWeek.Thursday;
    case 'FRIDAY':
      return DaysOfWeek.Friday;
    case 'SATURDAY':
      return DaysOfWeek.Saturday;
    case 'SUNDAY':
      return DaysOfWeek.Sunday;
  }
}
