import Table from '@packages/ui/table/table.tsx';
import {
  OnlineOrderDeliveryMode,
  OnlineOrderListItemFragment,
  OnlineOrderState,
} from '@client/graphql/types/graphql.ts';
import TableHeaderColumn from '@packages/ui/table/table-header-column.tsx';
import TableRow from '@packages/ui/table/table-row.tsx';
import TableRowColumn from '@packages/ui/table/table-row-cell.tsx';
import CurrencyView from '@packages/core/utils/currency-view.tsx';
import moment from 'moment-timezone';
import { useFocusedCompany } from '@packages/core/company/focused-company-context.ts';
import { ShoppingBagIcon, TruckIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import OnlineOrderSetButtons
  from '@client/page/management/online-order/dashboard/component/order-online-state-button.tsx';
import { TableCellAlignment } from '@packages/ui/table/table-type.ts';
import classNames from 'classnames';
import { useTranslate } from "@tolgee/react";

const OnlineOrderTable = (props: {
  orders: readonly OnlineOrderListItemFragment[];
  loading: boolean;
  branchID: string;
  navigationPrefix?: string;
}) => {
  const navigate = useNavigate();
  const { settings } = useFocusedCompany();
  const { t } = useTranslate();
  if (props.orders.length === 0) {
    return <div className="p-4 border-t">{t('online-order.dashboard.no-orders-found')}</div>;
  }

  return (
    <Table
      loading={props.loading}
      data={props.orders}
      build={(order) => {
        return (
          <TableRow
            key={order.id}
            onClick={() => {
              if (props.navigationPrefix) {
                navigate(`${props.navigationPrefix}/${order.id}`);
              } else {
                navigate(order.id);
              }
            }}
            className={classNames({ 'bg-amber-50': isWaitingForConfirm(order.state) })}
          >
            <TableRowColumn>
              <DeliveryType deliveryMode={order.deliveryMode} distance={order.distanceToRestaurant} />
            </TableRowColumn>
            <TableRowColumn>{order.guestName}</TableRowColumn>
            <TableRowColumn>{moment(order.userCreatedAt).tz(settings.timezone).format('HH:mm')}</TableRowColumn>
            <TableRowColumn className="hidden md:table-cell">
              {order._count.onlineOrderProducts} product(s)
            </TableRowColumn>
            <TableRowColumn className="hidden md:table-cell">
              <CurrencyView price={order.totalPrice} />
            </TableRowColumn>

            <TableRowColumn alignment={TableCellAlignment.Right}>
              <OnlineOrderSetButtons
                navigationPrefix={props.navigationPrefix}
                onlineOrderId={order.id}
                onlineOrderState={order.state}
              />
            </TableRowColumn>
          </TableRow>
        );
      }}
    >
      <TableHeaderColumn className={'text-center'}>{t('common.table-header-column.type')}</TableHeaderColumn>
      <TableHeaderColumn>{t('common.table-header-column.guest')}</TableHeaderColumn>
      <TableHeaderColumn>{t('common.table-header-column.time')}</TableHeaderColumn>
      <TableHeaderColumn className="hidden md:table-cell">{t('common.table-header-column.products')}</TableHeaderColumn>
      <TableHeaderColumn className="hidden md:table-cell">{t('common.table-header-column.price')}</TableHeaderColumn>
      <TableHeaderColumn alignment={TableCellAlignment.Right}></TableHeaderColumn>
    </Table>
  );
};

const DeliveryType = (props: { deliveryMode: OnlineOrderDeliveryMode; distance: number | null | undefined }) => {
  let additionalInfo = '';
  if (props.deliveryMode === OnlineOrderDeliveryMode.Delivery && props.distance) {
    additionalInfo = ` ${(props.distance / 1000).toFixed(2)} km`;
  }

  return (
    <div className="flex flex-col items-center">
      {props.deliveryMode == OnlineOrderDeliveryMode.Delivery ? (
        <>
          <TruckIcon className="w-6 h-6" />{' '}
          <p className="inline-block text-center text-xs md:text-sm line-clamp-1">{additionalInfo}</p>
        </>
      ) : (
        <ShoppingBagIcon className="w-6 h-6" />
      )}
    </div>
  );
};

const isWaitingForConfirm = (onlineOrderState: OnlineOrderState) => {
  return onlineOrderState === OnlineOrderState.WaitingForConfirmation;
};

export default OnlineOrderTable;
