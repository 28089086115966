import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

export type NotificationStatus = 'success' | 'error' | 'warning' | 'info';

export interface AlertConfig {
  status: NotificationStatus;
  title: string;
  message: string;

  // Optional timeout in seconds
  timeout?: number;
}

export interface NotificationCenter {
  notifications: Notification[];

  showAlert: (config: AlertConfig) => void;
  closeAlert: (id: string) => void;
}

export const NotificationCenterContext = createContext<NotificationCenter | undefined>(undefined);

export function useNotificationCenter(): NotificationCenter {
  const context = useContext(NotificationCenterContext);

  if (context === undefined) {
    throw new Error('useNotificationManager must be used within a NotificationManagerProvider');
  }

  return context;
}

export interface Notification {
  id: string;

  status: NotificationStatus;
  title: string;
  message: string;

  createdAt: Date;
}

export function useNotificationCenterProvider(): NotificationCenter {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const showAlert = useCallback(
    (config: AlertConfig) => {
      const id = uuidv4();

      // Add a new notification to the list
      setNotifications((notifications) => [
        ...notifications,
        {
          ...config,
          id: id,
          createdAt: new Date(),
        },
      ]);

      // Remove the notification after 5 seconds by default
      setTimeout(
        () => {
          setNotifications((notifications) => notifications.filter((notification) => notification.id !== id));
        },
        (config.timeout ?? 5) * 1000
      );
    },
    [setNotifications]
  );

  const closeAlert = useCallback(
    (id: string) => {
      setNotifications((notifications) => notifications.filter((notification) => notification.id !== id));
    },
    [setNotifications]
  );

  return useMemo(
    () => ({
      notifications: notifications,
      showAlert: showAlert,
      closeAlert: closeAlert,
    }),
    [closeAlert, notifications, showAlert]
  );
}
