import { Fragment, ReactNode } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import { useTranslate } from "@tolgee/react";

export default function MenuSelectionInput<T>(props: {
  title: string;
  data: readonly T[];
  value: T | null;
  onChange: (newValue: T) => void;
  build: (item: T) => { id: string; name: string };
  trailing?: ReactNode;
  className?: string;
}) {
  const { t } = useTranslate();
  return (
    <Listbox value={props.value} onChange={props.onChange}>
      {({ open }) => (
        <div className={props.className}>
          <Listbox.Label className="block mb-2 text-sm font-medium leading-6 text-gray-900">
            {props.title}
          </Listbox.Label>
          <div className="relative">
            <div className="flex items-center gap-x-2">
              <Listbox.Button
                className="px-4 flex items-center relative w-full cursor-default rounded-md
             bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2
             focus:ring-indigo-600 sm:text-sm sm:leading-6"
              >
                <span
                  className="block truncate">
                  {props.value ? props.build(props.value).name :
                    t('menu.menu-detail.product.create.form-input.menu-category.not-selected')}
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              </Listbox.Button>

              {props.trailing}
            </div>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg
               ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
              >
                {props.data.map((item) => (
                  <Listbox.Option
                    key={props.build(item).id}
                    className={({ active }) =>
                      classNames(
                        active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                        'px-4 flex relative cursor-default select-none py-2 pl-3 pr-9',
                      )
                    }
                    value={item}
                  >
                    {({ selected, active }) => (
                      <>
                        <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                          {props.build(item).name}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-indigo-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4',
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </div>
      )}
    </Listbox>
  );
}
