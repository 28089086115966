import dayjs, { Dayjs } from 'dayjs';
import { useState } from 'react';
import Card from 'ui/src/card/card';
import CardHeader from 'ui/src/card/card-header';
import CardTableContent from 'ui/src/card/card-content-table';
import { getFragmentData } from '@client/graphql/types';
import { OnlineOrderListFragment } from '@client/graphql/online-order-fragment';
import { ContentLayout, PrimaryContent } from 'ui/src/content-layout';
import ApplicationErrorView from '@client/module/error/application-error-view';
import OnlineOrderDailySummarize
  from '@client/page/management/online-order/dashboard/component/online-order-daily-summarize.tsx';
import SecondaryButton from '@packages/ui/button/secondary-button.tsx';
import { useTranslate } from "@tolgee/react";
import OnlineOrderTable from '../dashboard/component/online-order-table';
import {
  useFocusedBranchContext,
} from '../../table-group/table-group-list/component/branch-selection/focused-branch-context.ts';
import { TableReservationRangeFilter } from '../../table-reservation/list/component/table-reservation-range-filter';
import useOnlineOrdersHistory from './logic/use-online-order-history';

const OnlineOrderHistory = () => {
  const branchProvider = useFocusedBranchContext();
  const branchID = branchProvider.branch?.id ?? '';

  const initialStartTime = dayjs().subtract(7, 'day').startOf('day');
  const initialEndTime = dayjs().add(0, 'day').endOf('day');

  const [startTime, setStartTime] = useState<Dayjs>(initialStartTime);
  const [endTime, setEndTime] = useState<Dayjs>(initialEndTime);

  const { data, loading, error } = useOnlineOrdersHistory(branchID, {
    startTime: startTime.format('YYYY-MM-DDTHH:mm'),
    endTime: endTime.format('YYYY-MM-DDTHH:mm'),
  });
  const orders = getFragmentData(OnlineOrderListFragment, data?.onlineOrdersHistory) ?? [];

  const handleQuickPick = (type: 'currentMonth' | 'lastMonth' | 'last7Days') => {
    let newStartTime, newEndTime;
    const today = dayjs();

    switch (type) {
      case 'currentMonth':
        newStartTime = today.startOf('month');
        newEndTime = today.endOf('month');
        break;
      case 'lastMonth':
        newStartTime = today.subtract(1, 'month').startOf('month');
        newEndTime = today.subtract(1, 'month').endOf('month');
        break;
      case 'last7Days':
        newStartTime = today.subtract(7, 'days').startOf('day');
        newEndTime = today.endOf('day');
        break;
    }

    setStartTime(newStartTime);
    setEndTime(newEndTime);
  };
  const { t } = useTranslate();

  return (
    <ContentLayout>
      {' '}
      <PrimaryContent>
        <ApplicationErrorView className="my-4" error={error} />
        <Card>
          <CardHeader title={t('online-order.history.title')}>
            <SecondaryButton
              onClick={() => {
                handleQuickPick('currentMonth');
              }}
            >
              {t('common.button.current-month')}
            </SecondaryButton>
            <SecondaryButton
              onClick={() => {
                handleQuickPick('lastMonth');
              }}
            >
              {t('common.button.last-month')}
            </SecondaryButton>
            <SecondaryButton
              onClick={() => {
                handleQuickPick('last7Days');
              }}
            >
              {t('common.button.last-7-days')}
            </SecondaryButton>
            <TableReservationRangeFilter
              startTime={startTime}
              endTime={endTime}
              onChange={(dates) => {
                setStartTime(dates[0]);
                setEndTime(dates[1]);
              }}
            />
          </CardHeader>

          <CardTableContent>
            <OnlineOrderDailySummarize order={orders} />
            <OnlineOrderTable loading={loading} orders={orders} branchID={branchID} />
          </CardTableContent>
        </Card>
      </PrimaryContent>
    </ContentLayout>
  );
};

export default OnlineOrderHistory;
