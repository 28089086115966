import { MenuProductConfigurationValue } from '@client/graphql/types/graphql';
import useFormData from '@client/module/utils/use-form-data';
import { useCompanyCurrency } from 'core/src/company/focused-company-context';
import { normalizeCurrency } from 'core/src/utils/currency-formatter';
import PrimaryButton from 'ui/src/button/primary-button';
import SecondaryButton from 'ui/src/button/secondary-button';
import Card from 'ui/src/card/card';
import CardContent from 'ui/src/card/card-content';
import CardFooter from 'ui/src/card/card-footer';
import CardHeader from 'ui/src/card/card-header';
import FormItem from 'ui/src/form/form-item';
import FormLayout from 'ui/src/form/form-layout';
import TextInput from 'ui/src/form/input/text-input';
import { useNotificationCenter } from 'ui/src/notification/notification-center';
import { v4 as uuidv4 } from 'uuid';

interface Form {
  name: string;
  price: string;
}
export default function MenuProductConfigurationValueCreate(props: {
  configurationID: string;
  onClose: () => void;
  onCreate: (configurationID: string, newConfigValue: MenuProductConfigurationValue) => void;
}) {
  const { showAlert } = useNotificationCenter();
  const currency = useCompanyCurrency();
  const { data: form, handle } = useFormData<Form>({
    name: '',
    price: '',
  });

  const onSubmit = () => {
    const id = uuidv4();
    props.onCreate(props.configurationID, { id: id, name: form.name, price: normalizeCurrency(form.price, currency) });
    showAlert({
      status: 'success',
      title: 'Success',
      message: 'Configuration value has been created',
    });
  };

  return (
    <Card>
      <CardHeader title="Create price" />

      <CardContent>
        <FormLayout>
          <FormItem className="w-64" title={'Name'}>
            <TextInput
              data-testid="name-input"
              type="text"
              label="name"
              value={form.name}
              name="name"
              placeholder="Name"
              onChange={handle}
            />
          </FormItem>

          <FormItem className="w-32" title={'Price'}>
            <TextInput
              data-testid="price-input"
              type="number"
              label="price"
              value={form.price}
              name="price"
              placeholder="Price"
              onChange={handle}
            />
          </FormItem>
        </FormLayout>
      </CardContent>

      <CardFooter>
        <SecondaryButton
          className="w-32"
          onClick={() => {
            props.onClose();
          }}
        >
          Back
        </SecondaryButton>
        <PrimaryButton
          className="w-32"
          data-testid="create-button"
          onClick={() => {
            onSubmit();
            props.onClose();
          }}
        >
          Create
        </PrimaryButton>
      </CardFooter>
    </Card>
  );
}
