import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import CardContent from '@packages/ui/card/card-content.tsx';
import { Bar } from 'react-chartjs-2';
import { useTranslate } from '@tolgee/react';

const TableReservationAnalyticHourlyDiagram = (props: { hourlyCount: { hour: number; count: number }[] }) => {
  const { hourlyCount } = props;

  const fullHourData = new Array(24).fill(0);

  hourlyCount.forEach(({ hour, count }) => {
    fullHourData[hour] = count;
  });

  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  const labels = fullHourData.map((_, index) => `${index}:00`);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  const data = fullHourData.map((item) => item);
  const { t } = useTranslate();

  return (
    <Card>
      <CardHeader title={t('reservation.report.hourly-diagram.title')} />
      <CardContent>
        {
          <Bar
            className="h-96"
            options={{
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                y: {
                  ticks: {
                    format: {
                      maximumFractionDigits: 0,
                      minimumFractionDigits: 0,
                    },
                  },
                },
                x: {},
              },
            }}
            data={{
              labels: labels,
              datasets: [
                {
                  data: data,
                  backgroundColor: 'rgba(53, 162, 235, 0.5)',
                  label: t('reservation.report.hourly-diagram.label'),
                },
              ],
            }}
          />
        }
      </CardContent>
    </Card>
  );
};

export default TableReservationAnalyticHourlyDiagram;
