import { ContentLayout, PrimaryContent } from '@packages/ui/content-layout.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import Card from 'ui/src/card/card';
import CardContent from '@packages/ui/card/card-content.tsx';
import FormLayout from '@packages/ui/form/form-layout.tsx';
import useFormData from '@client/module/utils/use-form-data.ts';
import TextInput from '@packages/ui/form/input/text-input.tsx';
import FormItem from '@packages/ui/form/form-item.tsx';
import useCreateCompany from '@client/page/company/logic/use-create-company.ts';
import CardFooter from '@packages/ui/card/card-footer.tsx';
import PrimaryButton from '@packages/ui/button/primary-button.tsx';
import { useNavigate } from 'react-router-dom';
import { captureException } from '@sentry/react';

interface Form {
  id: string;
  name: string;
  currency: string;
}

const CompanyCreate = () => {
  const navigate = useNavigate();
  const [create] = useCreateCompany();

  const { data: form, handle } = useFormData<Form>({
    id: '',
    name: '',
    currency: 'EUR',
  });

  const callback = () => {
    create({ variables: { input: form } })
      .then(() => {
        navigate('..');
      })
      .catch(captureException);
  };

  return (
    <ContentLayout className="p-4">
      <PrimaryContent>
        <Card>
          <CardHeader title="Company Create" />
          <CardContent>
            <FormLayout>
              <FormItem
                title={'Your unique company identifier'}
                className="max-w-md"
              >
                <TextInput
                  type="text"
                  label="id"
                  value={form.id}
                  name="id"
                  placeholder="This value can not be changed later"
                  onChange={handle}
                />
              </FormItem>

              <FormItem title={'Company name'} className="max-w-md">
                <TextInput
                  type="text"
                  label="name"
                  value={form.name}
                  name="name"
                  placeholder="A name for your company"
                  onChange={handle}
                />
              </FormItem>

              <FormItem title={'Currency'} className="max-w-md">
                <TextInput
                  type="text"
                  label="currency"
                  value={form.currency}
                  name="currency"
                  placeholder="Currency"
                  onChange={handle}
                />
              </FormItem>
            </FormLayout>
          </CardContent>

          <CardFooter>
            <PrimaryButton onClick={callback}>Create</PrimaryButton>
          </CardFooter>
        </Card>
      </PrimaryContent>
    </ContentLayout>
  );
};

export default CompanyCreate;
