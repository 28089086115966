import lodash from 'lodash';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {
  UserNotificationData,
} from '@client/page/management/user-notificartion/user-notification-list/component/user-notification-data.ts';

export default function UserNotificationList(props: { items: UserNotificationData[] | undefined }) {
  const { items } = props;
  return (
    <>
      <div className="flex flex-col">
        {items?.map(item => (
          <Link key={item.id} className="flex flex-col gap-y-3 px-4 py-6 border-b-2 hover:bg-gray-100"
                to={item.link ?? ''}>
            <p className="font-semibold text-lg">{item.title}</p>
            <p className="italic">{item.message}</p>
            <p className="text-gray-500">{lodash.capitalize(moment(parseInt(item.createdAt)).fromNow())}</p>
          </Link>
        ))}
      </div>
    </>
  );
}
