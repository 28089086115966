import { ReactNode } from 'react';
import classNames from 'classnames';

/**
 * Card component that contains different UI elements
 */
export default function Card(props: { className?: string; children?: ReactNode }) {
  return (
    <div
      className={classNames(
        'bg-white shadow rounded-lg divide-y divide-gray-200 bg-clip-content bg-clip-padding overflow-visible',
        props.className
      )}
    >
      {props.children}
    </div>
  );
}
