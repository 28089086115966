import { PickupDiscountType } from '@client/graphql/types/graphql.ts';
import { XCircleIcon } from '@heroicons/react/24/outline';
import { currencySymbol } from '@packages/core/utils/currency-formatter.ts';

export function pickupDiscountTypeUnit(amount: number | undefined, type: PickupDiscountType, currency: string) {
  switch (type) {
    case PickupDiscountType.None:
      return <XCircleIcon className="w-5 h-5 text-gray-500" />;
    case PickupDiscountType.Percentage:
      return <>{amount}%</>;
    case PickupDiscountType.Amount:
      return <span className="text-gray-500">{currencySymbol(currency)}</span>;
  }
}