import MenuSelectionInput from '@packages/ui/form/input/menu-selection-input.tsx';
import {
  useFocusedBranchContext,
} from '@client/page/management/table-group/table-group-list/component/branch-selection/focused-branch-context.ts';
import { Cog6ToothIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import { useTranslate } from '@tolgee/react';

export default function FocusedBranchSelection() {
  const navigate = useNavigate();
  const { branch, branches, setBranch } = useFocusedBranchContext();
  const { t } = useTranslate();
  return (
    <div className="">
      <p className={'text-base font-medium'}>{t('navigation-content.Branch.title', 'Branch')}</p>
      <div className="flex items-center gap-x-1">
        <MenuSelectionInput
          title=""
          data={branches}
          value={branch}
          onChange={setBranch}
          build={(item) => {
            return {
              id: item.id,
              name: item.name,
            };
          }}
          trailing={
            <Cog6ToothIcon
              className={'w-6 h-6 stroke-gray-500'}
              onClick={() => {
                navigate(`/current-branch/${branch?.id ?? ''}`);
              }}
            />
          }
        />
      </div>
    </div>
  );
}
