import CardHeader from '@packages/ui/card/card-header.tsx';
import Card from '@packages/ui/card/card.tsx';
import CardContent from '@packages/ui/card/card-content.tsx';
import FormLayout from '@packages/ui/form/form-layout.tsx';
import FormItem from '@packages/ui/form/form-item.tsx';
import SecondaryButton from '@packages/ui/button/secondary-button.tsx';
import { useNotificationCenter } from '@packages/ui/notification/notification-center.ts';
import useUpdateOnlineOrderConfiguration
  from '@client/page/management/online-order/setting/logic/use-update-online-order-branch-setting.ts';
import { CategoryStyle } from '@client/graphql/types/graphql.ts';
import MenuSelectionInput from '@packages/ui/form/input/menu-selection-input.tsx';
import { capitalize } from 'lodash';
import useCheckAdminRole from '@client/module/auth/check-role-hook';
import { useTranslate } from "@tolgee/react";

export default function OnlineOrderStorefrontSetting(props: { branchID: string; currentCategoryStyle: CategoryStyle }) {
  const { showAlert } = useNotificationCenter();
  const { isAdmin } = useCheckAdminRole();
  const [update, { error: updateError }] = useUpdateOnlineOrderConfiguration();
  const { t } = useTranslate();
  const copyLinkHandle = () => {
    void navigator.clipboard
      .writeText(`${import.meta.env.VITE_STOREFRONT_HOST as string}/online-order/start?&branchID=${props.branchID}`)
      .then(() => {
        showAlert({
          status: 'success',
          title: t('alert.title.success'),
          message: t('online-order.setting-page.edit.storefront-setting.online-order-link.alert.success.message'),
        });
      })
      .catch(() => {
        showAlert({
          status: 'error',
          title: t('alert.title.error'),
          message: t('online-order.setting-page.edit.storefront-setting.online-order-link.alert.error.message'),
        });
      });
  };

  const handleCategoryStyle = (newCate: CategoryStyle) => {
    update({
      variables: {
        branchID: props.branchID,
        input: {
          categoryStyle: newCate,
        },
      },
    })
      .then(() => {
        showAlert({
          status: 'success',
          title: t('alert.title.success'),
          message: t('online-order.setting-page.edit.storefront-setting.category-style.alert.message'),
        });
      })
      .catch(() => {
        showAlert({
          status: 'error',
          title: t('alert.title.error'),
          message: updateError?.message ?? '',
        });
      });
  };

  return (
    <>
      <Card>
        <CardHeader title={t('online-order.setting-page.edit.storefront-setting.title')} />

        <CardContent>
          <FormLayout>
            {isAdmin && (
              <FormItem title={t('online-order.setting-page.edit.storefront-setting.category-style.title')}
                        className="max-w-md">
                <MenuSelectionInput
                  title={''}
                  data={Object.keys(CategoryStyle)}
                  value={capitalize(props.currentCategoryStyle)}
                  onChange={(newVal) => {
                    handleCategoryStyle(CategoryStyle[newVal as keyof typeof CategoryStyle]);
                  }}
                  build={(item) => {
                    return {
                      id: item,
                      name: t('online-order.setting-page.edit.storefront-setting.category-style.menu-selection.' + item.split('_').join(' ')),
                    };
                  }}
                />
              </FormItem>
            )}
            <FormItem title={t('online-order.setting-page.edit.storefront-setting.online-order-link.title')}>
              <p
                className={'text-gray-400 text-sm mb-4 mt-3'}>{t('online-order.setting-page.edit.storefront-setting.online-order-link.title')}</p>
              <SecondaryButton
                onClick={() => {
                  copyLinkHandle();
                }}
              >
                {t('online-order.setting-page.edit.storefront-setting.online-order-link.button')}
              </SecondaryButton>
            </FormItem>
          </FormLayout>
        </CardContent>
      </Card>
    </>
  );
}
