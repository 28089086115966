import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import CardContent from '@packages/ui/card/card-content.tsx';
import { Bar } from 'react-chartjs-2';
import { useTranslate } from "@tolgee/react";
import moment from 'moment';

const TableReservationAnalyticDailyDiagram = (props: {
  dailyCount: { date: string; count: number }[];
}) => {
  const { dailyCount } = props;

  // Fill missing days in month
  const ref = moment().add(7, 'days').startOf('day');
  const last30Days = Array.from(Array(30).keys())
    .map((i) => {
      return ref.clone().subtract(i, 'days').format('YYYY-MM-DD');
    })
    .map((date) => {
      return {
        date: date,
        count: dailyCount.find((item) => item.date === date)?.count ?? 0,
      };
    })
    .sort((a, b) => moment(a.date).diff(moment(b.date)));

  const labels = last30Days.map((item) => moment(item.date).format('DD/MM'));
  const data = last30Days.map((item) => item.count);

  const { t } = useTranslate();
  return (
    <Card>
      <CardHeader title={t('reservation.report.daily-diagram.title')} />
      <CardContent>
        {
          <Bar
            className="h-96"
            options={{
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                y: {
                  ticks: {
                    format: {
                      maximumFractionDigits: 0,
                      minimumFractionDigits: 0,
                    },
                  },
                },
                x: {},
              },
            }}
            data={{
              labels: labels,
              datasets: [
                {
                  data: data,
                  backgroundColor: 'rgba(53, 162, 235, 0.5)',
                  label: t('reservation.report.daily-diagram.label'),
                },
              ],
            }}
          />
        }
      </CardContent>
    </Card>
  );
};

export default TableReservationAnalyticDailyDiagram;
