import Center from '@packages/ui/center.tsx';
import Spinner from '@packages/ui/spinner.tsx';

export default function Loading() {
  return (
    <Center className="py-4">
      <Spinner />
    </Center>
  );
}
