import CardSection from '@packages/ui/card/card-section.tsx';
import DestroyButton from '@packages/ui/button/destroy-button.tsx';
import { useCallback } from 'react';
import { useDialog } from '@packages/ui/modal/use-dialog.tsx';
import { useNavigate } from 'react-router-dom';
import useDeleteMenuProductConfiguration
  from '@client/page/management/menu/menu-product-configuration-edit/logic/use-delete-product-configuration.ts';
import { useTranslate } from "@tolgee/react";

export default function MenuProductConfigurationDelete(props: {
  productConfigurationId: string;
}) {
  const navigate = useNavigate();
  const dialog = useDialog();
  const [deleteMenu] = useDeleteMenuProductConfiguration();
  const { t } = useTranslate();

  const onDestroy = useCallback(() => {
    dialog.destructAlert({
      title: t('menu.menu-detail.product.edit.configurations.edit.extra-action.delete.alert.title'),
      message: t('menu.menu-detail.product.edit.configurations.edit.extra-action.delete.alert.message'),
      textButton: t('menu.menu-detail.product.edit.configurations.edit.extra-action.delete.alert.text-button'),
      onConfirm: async () => {
        await deleteMenu({ variables: { id: props.productConfigurationId } });
        navigate('..');
      },
    });
  }, [deleteMenu, dialog, navigate, props.productConfigurationId, t]);
  return (
    <div className="flex space-x-4">
      <CardSection
        title={t('menu.menu-detail.product.edit.configurations.edit.extra-action.delete.title')}
        descriptions={t('menu.menu-detail.product.edit.configurations.edit.extra-action.delete.description')}
      >
        <DestroyButton data-testid="delete-button" onClick={onDestroy}>
          {t('menu.menu-detail.product.edit.configurations.edit.extra-action.delete.button')}
        </DestroyButton>
      </CardSection>
    </div>
  );
}
