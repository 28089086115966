import { MenuProductLabel } from '@client/graphql/types/graphql.ts';
import CheckBoxInput from '@packages/ui/form/input/check-box-input.tsx';
import { capitalize } from 'lodash';
import { useTranslate } from "@tolgee/react";

export default function MenuProductLabelSelect(props: {
  label: MenuProductLabel[];
  handleLabel: (item: MenuProductLabel, selected: boolean) => void;
}) {
  const menuProductLable = Object.values(MenuProductLabel);
  const { t } = useTranslate();
  return (
    <div className={'grid grid-cols-2'}>
      {menuProductLable.map((item) => (
        <div key={item}>
          <CheckBoxInput
            value={props.label.find((label) => label === item) !== undefined}
            name={t('menu.menu-detail.product.edit.form-input.label.selection.' + capitalize(item.replace('_', ' ')))}
            onChange={(_, value) => {
              props.handleLabel(item, value);
            }}
          />
        </div>
      ))}
    </div>
  );
}
