import Card from '@packages/ui/card/card.tsx';
import FormLayout from '@packages/ui/form/form-layout.tsx';
import FormItem from '@packages/ui/form/form-item.tsx';
import TextInput from '@packages/ui/form/input/text-input.tsx';
import { useCallback, useState } from 'react';
import PrimaryButton from '@packages/ui/button/primary-button.tsx';
import AlertError from '@packages/ui/alert/alert-error.tsx';
import {
  formatGraphQlError,
  validationErrors,
} from '@client/module/error/error.ts';
import { useNavigate } from 'react-router-dom';
import CardContent from '@packages/ui/card/card-content.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import CardFooter from '@packages/ui/card/card-footer.tsx';
import SecondaryButton from 'ui/src/button/secondary-button';
import Center from 'ui/src/center';
import Spinner from 'ui/src/spinner';
import useFormData from '@client/module/utils/use-form-data';
import { UserCompanyPermission } from '@client/graphql/types/graphql';
import { useCompanyId } from '@packages/core/company/focused-company-context.ts';
import { ContentLayout, PrimaryContent } from 'ui/src/content-layout';
import { Helmet } from 'react-helmet-async';
import UserRoleSelected from './component/user-role-selected';
import useUserCompanyRoleCreate from './logic/user-user-company-role-create';

interface FormData {
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  permission: UserCompanyPermission[];
}

export default function UserCreate() {
  const companyID = useCompanyId();
  const navigate = useNavigate();

  const [userCreate, { loading, error }] = useUserCompanyRoleCreate();
  const [permissions, setPermissions] = useState<Set<UserCompanyPermission>>(
    new Set()
  );
  const {
    data: form,
    handle,
    manualHandle,
  } = useFormData<FormData>({
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    permission: Array.from(permissions),
  });

  const applicationErrors = formatGraphQlError(error?.graphQLErrors);
  const validationError = validationErrors(applicationErrors);

  const onSubmit = useCallback(() => {
    userCreate({
      variables: { companyID: companyID, input: form },
    })
      .then((value) => {
        if (!value.data) {
          alert('Error creating user');
        } else {
          alert(
            'Your password is ' + value.data.userCompanyRoleCreate.password
          );
          navigate('..');
        }
      })
      .catch((e: unknown) => void e);
  }, [form, navigate, userCreate, companyID]);

  return (
     
      <ContentLayout>
        <Helmet>
          <title>Create User</title>
          <meta
            name="description"
            content="This page allows you to create a user"
          />
        </Helmet>
        <PrimaryContent>
          <div className="space-y-4 ">
            <Card>
              <CardHeader title="Create User" />

              <CardContent>
                {loading ? (
                  <Center>
                    <Spinner />
                  </Center>
                ) : (
                  <FormLayout>
                    <FormItem title={'Username'} className="max-w-md">
                      <TextInput
                        data-testid="Username-input"
                        type="text"
                        label="title"
                        value={form.username}
                        name="username"
                        placeholder="Username"
                        error={validationError.title}
                        onChange={handle}
                      />
                    </FormItem>

                    <FormItem title={'Firstname'} className="max-w-md">
                      <TextInput
                        data-testid="firstName-input"
                        type="text"
                        label="Firstname"
                        value={form.firstName}
                        name="firstName"
                        placeholder="firstname"
                        error={validationError.title}
                        onChange={handle}
                      />
                    </FormItem>

                    <FormItem title={'Lastname'} className="max-w-md">
                      <TextInput
                        data-testid="lastName-input"
                        type="text"
                        label="Lastname"
                        value={form.lastName}
                        name="lastName"
                        placeholder="lastname"
                        error={validationError.title}
                        onChange={handle}
                      />
                    </FormItem>

                    <FormItem title={'Email'} className="max-w-md">
                      <TextInput
                        data-testid="description-input"
                        type="text"
                        label="description"
                        value={form.email}
                        name="email"
                        placeholder="description"
                        error={validationError.title}
                        onChange={handle}
                      />
                    </FormItem>

                    <FormItem title={'User Role'} className="max-w-md">
                      <UserRoleSelected
                        permissions={permissions}
                        setPermissions={setPermissions}
                        manualHandle={manualHandle}
                      />
                    </FormItem>

                    {error && (
                      <AlertError
                        className="mt-4"
                        title={error.name}
                        message={error.message}
                      />
                    )}
                  </FormLayout>
                )}
              </CardContent>

              <CardFooter>
                <SecondaryButton
                  className="w-32"
                  onClick={() => {
                    navigate('..');
                  }}
                >
                  Cancel
                </SecondaryButton>
                <PrimaryButton
                  className="w-32"
                  onClick={() => {
                    onSubmit();
                  }}
                >
                  Create
                </PrimaryButton>
              </CardFooter>
            </Card>
          </div>
        </PrimaryContent>
      </ContentLayout>
     
  );
}
