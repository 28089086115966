import { MenuProductConfiguration, ProductConfigurationType } from '@client/graphql/types/graphql';
import useFormData from '@client/module/utils/use-form-data';
import Card from 'ui/src/card/card';
import CardContent from 'ui/src/card/card-content';
import CardHeader from 'ui/src/card/card-header';
import FormItem from 'ui/src/form/form-item';
import FormLayout from 'ui/src/form/form-layout';
import MenuSelectionInput from 'ui/src/form/input/menu-selection-input';
import TextInput from 'ui/src/form/input/text-input';
import { v4 as uuidv4 } from 'uuid';
import CardFooter from 'ui/src/card/card-footer';
import SecondaryButton from 'ui/src/button/secondary-button';
import PrimaryButton from 'ui/src/button/primary-button';
import { useNotificationCenter } from 'ui/src/notification/notification-center';
import { formatProductConfigurationType } from '../../menu-product-configuration-edit/logic/format-product-configuration-type';

interface FormData {
  title: string;
  type: ProductConfigurationType;
}

export default function MenuProductConfigurationCreate(props: {
  onClose: () => void;
  onCreate: (newConfig: MenuProductConfiguration) => void;
}) {
  const { showAlert } = useNotificationCenter();

  const { data, handle, manualHandle } = useFormData<FormData>({
    title: '',
    type: ProductConfigurationType.Option,
  });

  const onSubmit = () => {
    const id = uuidv4();
    props.onCreate({ id: id, title: data.title, type: data.type, values: [] });
    showAlert({
      status: 'success',
      title: 'Success',
      message: 'Configuration has been created',
    });
  };

  return (
    <Card>
      <CardHeader title="Add configuration" />

      <CardContent>
        <FormLayout>
          <FormItem title="Title">
            <TextInput
              type="text"
              label="title"
              value={data.title}
              name="title"
              placeholder="Title"
              onChange={handle}
            />
          </FormItem>
          <MenuSelectionInput
            title="Type"
            data={[ProductConfigurationType.Selection, ProductConfigurationType.Option]}
            value={data.type}
            onChange={(newValue) => {
              manualHandle('type', newValue);
            }}
            build={(type) => ({
              id: type,
              name: formatProductConfigurationType(type),
            })}
          />
        </FormLayout>
      </CardContent>

      <CardFooter>
        <CardFooter>
          <SecondaryButton
            className="w-32"
            onClick={() => {
              props.onClose();
            }}
          >
            Cancel
          </SecondaryButton>
          <PrimaryButton
            className="w-32"
            onClick={() => {
              onSubmit();
              props.onClose();
            }}
          >
            Create
          </PrimaryButton>
        </CardFooter>
      </CardFooter>
    </Card>
  );
}
