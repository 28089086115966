import { useState } from 'react';
import PrimaryButton from 'ui/src/button/primary-button';
import Card from 'ui/src/card/card';
import CardContent from 'ui/src/card/card-content';
import CardFooter from 'ui/src/card/card-footer';
import CardHeader from 'ui/src/card/card-header';
import FormItem from 'ui/src/form/form-item';

export default function TableBookingMessageIcon({
  message,
}: {
  message: string;
}) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <>
      <p
        className="max-w-24 truncate"
        title={message}
        onClick={() => {
          setIsOpen(true);
        }}
      >
        {message}
      </p>

      {isOpen && (
        <div className="">
          <div className="fixed -inset-4 z-50 flex justify-center items-center">
            <Card className="bg-white rounded-lg">
              <CardHeader title="Message" />
              <CardContent>
                <FormItem title="" className="min-w-[300px] ">
                  <textarea
                    className="w-full rounded-md"
                    id="message"
                    name="message"
                  >
                    {message}
                  </textarea>
                </FormItem>
              </CardContent>
              <CardFooter>
                <PrimaryButton
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  Done
                </PrimaryButton>
              </CardFooter>
            </Card>
          </div>
          <div className="fixed -inset-4 bg-gray-500 opacity-75 transition-opacity z-40"></div>
        </div>
      )}
    </>
  );
}
