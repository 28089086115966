import moment from 'moment';

const FullDateView = (props: { date: unknown }) => {
  const date = props.date;

  const formattedDate = 'DD MMMM YYYY HH:mm';

  if (!date) {
    return <p>Invalid date</p>;
  }

  switch (typeof date) {
    case 'string':
      return <p>{moment(date).format(formattedDate)}</p>;
    case 'number':
      return <p>{moment(date).format(formattedDate)}</p>;
    default:
      return <p>Invalid date</p>;
  }
};

export default FullDateView;
