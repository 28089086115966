import Card from '@packages/ui/card/card.tsx';
import CardContent from '@packages/ui/card/card-content.tsx';
import useFormData from '@client/module/utils/use-form-data';
import { useCallback, useState } from 'react';
import { formatGraphQlError, validationErrors } from '@client/module/error/error';
import FormLayout from 'ui/src/form/form-layout';
import FormItem from 'ui/src/form/form-item';
import TextInput from '@packages/ui/form/input/text-input.tsx';
import CardHeader from '@packages/ui/card/card-header';
import ToggleInput from '@packages/ui/form/input/toggle-input';
import SecondaryButton from '@packages/ui/button/secondary-button';
import PrimaryButton from '@packages/ui/button/primary-button';
import AlertError from '@packages/ui/alert/alert-error.tsx';
import { DaysOfWeek, OpeningTimeServiceType } from '@client/graphql/types/graphql.ts';
import {
  useFocusedBranchContext,
} from '@client/page/management/table-group/table-group-list/component/branch-selection/focused-branch-context.ts';
import {
  useCreateOpeningTime,
} from '@client/page/management/opening-time/opening-time-create/logic/use-create-opening-time.ts';
import MenuSelectionInput from 'ui/src/form/input/menu-selection-input';
import { useTranslate } from "@tolgee/react";

interface OpeningTimeCreateForm {
  openTime: string;
  closeTime: string;
  note: string;
  isActive: boolean;
}

export default function OpeningTimeCreate(props: {
  onClose: () => void
  service: OpeningTimeServiceType
}) {
  const { onClose, service } = props;

  const daysOfWeek = [
    DaysOfWeek.Monday,
    DaysOfWeek.Tuesday,
    DaysOfWeek.Wednesday,
    DaysOfWeek.Thursday,
    DaysOfWeek.Friday,
    DaysOfWeek.Saturday,
    DaysOfWeek.Sunday,
  ];

  const [selected, setSelected] = useState(daysOfWeek[0]);

  const { branch } = useFocusedBranchContext();

  const {
    data: form,
    handle,
    manualHandle,
  } = useFormData<OpeningTimeCreateForm>({
    openTime: '',
    closeTime: '',
    note: '',
    isActive: true,
  });

  const [create, { error, loading }] = useCreateOpeningTime();
  const applicationErrors = formatGraphQlError(error?.graphQLErrors);
  const validationError = validationErrors(applicationErrors);

  const submit = useCallback(() => {
    create({
      variables: {
        input: {
          branchId: branch?.id ?? '',
          service: service,
          dayOfWeek: selected,
          ...form,
        },
      },
    })
      .then(() => {
        onClose();
      })
      .catch((e) => void e);
  }, [branch?.id, create, selected, form, onClose, service]);
  const { t } = useTranslate();
  return (
    <>
      <Card>
        <CardHeader title={t('reservation.setting.create-opening-time.title')} withBackButton={true} />
        <CardContent>
          <FormLayout>
            <FormItem className="max-w-md" title={t('reservation.setting.create-opening-time.open-time')}>
              <TextInput
                type="time"
                name="openTime"
                label="openTime"
                autoComplete="off"
                placeholder="Open Time"
                error={validationError.openTime}
                value={form.openTime}
                onChange={handle}
              />
            </FormItem>

            <FormItem className="max-w-md" title={t('reservation.setting.create-opening-time.close-time')}>
              <TextInput
                type="time"
                name="closeTime"
                label="closeTime"
                autoComplete="off"
                placeholder="Close Time"
                error={validationError.closeTime}
                value={form.closeTime}
                onChange={handle}
              />
            </FormItem>

            <FormItem className="max-w-md capitalize" title={t('common.day-of-week.title')}>
              <MenuSelectionInput
                title=""
                data={daysOfWeek}
                value={selected}
                onChange={(e) => {
                  setSelected(e);
                }}
                build={(item) => {
                  return {
                    id: item,
                    name: item,
                  };
                }}
              />
            </FormItem>

            {/*
              <FormItem className="max-w-md" title="Time zone">
                <TimeZoneSelect
                  value={form.timezone}
                  onChange={(newValue) => {
                    handleSelectChange('timezone', newValue);
                  }}
                />
              </FormItem>
                 */}

            <FormItem className="max-w-md" title={t('common.toggle.active')}>
              <ToggleInput
                value={form.isActive}
                onChange={(newValue) => {
                  manualHandle('isActive', newValue);
                }}
              />
            </FormItem>

            <FormItem className="max-w-md" title={t('common.form-input.note')}>
              <TextInput
                type="text"
                name="note"
                label="note"
                autoComplete="off"
                placeholder={t('reservation.setting.create-opening-time.note.placeholder')}
                error={validationError.note}
                value={form.note}
                onChange={handle}
              />
            </FormItem>
          </FormLayout>

          {error && <AlertError className="mt-4" title={'Error'} message={error.message} />}
        </CardContent>

        <div className="bg-gray-50 px-2 py-3 flex justify-end rounded-b-lg space-x-2
         md:px-4 md:space-x-4 md:py-5 sm:px-6">
          <SecondaryButton
            className="w-32"
            onClick={() => {
              onClose();
            }}
          >
            {t('common.button.cancel')}
          </SecondaryButton>
          <PrimaryButton
            id="create-button"
            data-testid="create-button"
            className="w-32"
            onClick={submit}
            loading={loading}
          >
            {t('common.button.create')}
          </PrimaryButton>
        </div>
      </Card>
    </>
  );
}
