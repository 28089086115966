import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { BranchItemFragment } from '@client/graphql/types/graphql.ts';
import { useBranchList } from '@client/page/management/branch/branch-list/logic/use-branch-list.tsx';
import { getFragmentData } from '@client/graphql/types';
import { BranchFragment } from '@client/graphql/branch-fragment.ts';
import { ApolloError } from '@apollo/client';

interface FocusedBranchProvider {
  branch: BranchItemFragment | null;
  setBranch: (branch: BranchItemFragment) => void;
  branches: BranchItemFragment[];
  loading: boolean;
  refetch: () => Promise<void>;
  error: ApolloError | undefined;
}

export function useFocusedBranchContext() {
  const context = useContext(FocusedBranchContext);
  if (!context) {
    throw new Error('useBranchContext must be used within a BranchContext.Provider');
  }
  return context;
}

export function useFocusedBranchProvider(): FocusedBranchProvider {
  const [branch, setBranch] = useState<BranchItemFragment | null>(null);

  // Fetch data
  const [loading, setLoading] = useState(true);
  const { data, loading: branchLoading, error, refetch } = useBranchList();

  useEffect(() => {
    const branches = data?.branches.map((branch) => getFragmentData(BranchFragment, branch));

    if (branchLoading) {
      return;
    }

    if (!branches || branches.length === 0) {
      setLoading(false);
      return;
    }

    setLoading(false);

    const prevSelectedBranchId = localStorage.getItem('selectedBranch');
    if (prevSelectedBranchId) {
      // Search branch by id
      const branch = branches.find((branch) => branch.id === prevSelectedBranchId);
      if (branch) {
        setBranch(branch);
      } else {
        setBranch(branches[0]);
      }
    } else {
      setBranch(branches[0]);
    }
    return;
  }, [branchLoading, data]);

  return useMemo(() => {
    const branches = data?.branches.map((branch) => getFragmentData(BranchFragment, branch)) ?? [];

    return {
      loading,
      error,
      branch,
      branches,
      refetch: async () => {
        await refetch();
      },
      setBranch: (branch) => {
        localStorage.setItem('selectedBranch', branch.id);
        setBranch(branch);
      },
    };
  }, [branch, data?.branches, error, loading, refetch]);
}

export const FocusedBranchContext = createContext<FocusedBranchProvider | null>(null);
