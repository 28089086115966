import Card from 'ui/src/card/card';
import CardHeader from 'ui/src/card/card-header';
import { DaysOfWeek, OpeningTimeServiceType } from '@client/graphql/types/graphql';
import ApplicationErrorView from '@client/module/error/application-error-view';
import { OpeningTimeFragment } from '@client/graphql/opening-time-fragment.ts';
import { getFragmentData } from '@client/graphql/types';
import { daysOfWeekToString } from '@client/graphql/days-of-week.ts';
import CardTableContent from '@packages/ui/card/card-content-table.tsx';
import Table from '@packages/ui/table/table.tsx';
import TableHeaderColumn from '@packages/ui/table/table-header-column.tsx';
import { TableCellAlignment } from '@packages/ui/table/table-type.ts';
import TableRow from '@packages/ui/table/table-row.tsx';
import TableRowColumn from '@packages/ui/table/table-row-cell.tsx';
import { useTranslate } from "@tolgee/react";
import Loading from '@packages/ui/loading.tsx';
import SecondaryButton from 'ui/src/button/secondary-button.tsx';
import {
  useFocusedBranchContext,
} from '../../table-group/table-group-list/component/branch-selection/focused-branch-context.ts';
import { useOpeningTimesQuery } from './logic/use-opening-times-query.ts';


export default function OpeningTimeSetup(props: {
  title: string; service: OpeningTimeServiceType,
  showUpdateModalOpeningTime: (openingTimeId: string) => void,
  showCreateModalOpeningTime: (service: OpeningTimeServiceType) => void,
}) {
  const { branch } = useFocusedBranchContext();

  const { t } = useTranslate();

  const { data, loading, error } = useOpeningTimesQuery(branch?.id, props.service, undefined);

  const openingTimeFragments = getFragmentData(OpeningTimeFragment, data?.openingTimes);

  const daysOfWeek = [
    DaysOfWeek.Monday,
    DaysOfWeek.Tuesday,
    DaysOfWeek.Wednesday,
    DaysOfWeek.Thursday,
    DaysOfWeek.Friday,
    DaysOfWeek.Saturday,
    DaysOfWeek.Sunday,
  ];

  return (
    <Card>
      <CardHeader title={props.title}>
        <SecondaryButton onClick={() => {
          props.showCreateModalOpeningTime(props.service);
        }}>{t('common.button.create')}</SecondaryButton>
      </CardHeader>
      <CardTableContent>
        {loading && <Loading />}

        {openingTimeFragments && (
          <Table
            data={daysOfWeek}
            build={(dayOfWeek) => {
              const dayTimes = openingTimeFragments.filter((time) => time.dayOfWeek === dayOfWeek);

              return (
                <TableRow
                  key={dayOfWeek}
                >
                  <TableRowColumn>{t('common.day-of-week.'+daysOfWeekToString(dayOfWeek))}</TableRowColumn>
                  <TableRowColumn alignment={TableCellAlignment.Right}>
                    {dayTimes.length === 0 && <p>{t('common.text.close')}</p>}
                    {dayTimes.map((time) => {
                      return (
                        <div key={time.id}>
                          <p className="hover:underline" onClick={() => {
                            props.showUpdateModalOpeningTime(time.id);
                          }}>
                            {time.openTime} - {time.closeTime}
                          </p>
                        </div>
                      );
                    })}
                  </TableRowColumn>
                </TableRow>
              );
            }}
          >
            <TableHeaderColumn>{t('common.day-of-week.title')}</TableHeaderColumn>
            <TableHeaderColumn alignment={TableCellAlignment.Right}>{t('common.text.time')}</TableHeaderColumn>
          </Table>
        )}

        <ApplicationErrorView error={error} />
      </CardTableContent>
    </Card>
  );
}
